import type { City } from '../../cities';

export const incheon: City = {
    name: {
        "en": "Incheon",
        "ru": "Инчхон"
    },
    country: {
        "en": "South Korea",
        "ru": "Южная Корея"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.pixabay.com%2Fphoto%2F2016%2F10%2F20%2F07%2F47%2Fsongdo-incheon-korea-1754841_640.jpg&f=1&nofb=1&ipt=c3a1355690e0f4025a284dc6b050a284d7a8f39ba1ac19d2f9c3685799101f09&ipo=images",
    populationMillion: 3.0,
    population: {
        "en": "3.0 million",
        "ru": "3,0 млн"
    },
    description: {
        "en": "Incheon is a major coastal city and transportation hub, home to South Korea's largest international airport and one of the world's busiest seaports.",
        "ru": "Инчхон - крупный прибрежный город и транспортный узел, где находится крупнейший международный аэропорт Южной Кореи и один из самых загруженных морских портов мира."
    },
    founded: {
        "en": "1883",
        "ru": "1883"
    },
    landmarks: {
        "en": ["Incheon Bridge", "Songdo Central Park", "Chinatown", "Wolmido Island"],
        "ru": ["Мост Инчхон", "Центральный парк Сонгдо", "Чайнатаун", "Остров Вольмидо"]
    },
    universities: {
        "en": ["Incheon National University", "University of Incheon", "Inha University"],
        "ru": ["Инчхонский национальный университет", "Университет Инчхона", "Университет Инха"]
    },
    industries: {
        "en": ["Logistics", "Manufacturing", "Tourism", "Information Technology"],
        "ru": ["Логистика", "Производство", "Туризм", "Информационные технологии"]
    },
    climate: {
        "en": "Humid continental climate with hot summers and cold winters",
        "ru": "Влажный континентальный климат с жарким летом и холодной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Ferry", "Airport Railroad"],
        "ru": ["Метро", "Автобус", "Паром", "Аэроэкспресс"]
    },
    timeZone: "UTC+09:00",
    languages: {
        "en": ["Korean", "English"],
        "ru": ["Корейский", "Английский"]
    },
    airports: {
        "en": ["Incheon International Airport"],
        "ru": ["Международный аэропорт Инчхон"]
    },
    isCapital: false,
    coordinates: {
        latitude: 37.4563,
        longitude: 126.7052
    }
};
