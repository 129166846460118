import type { City } from '../../cities';

export const newyork: City = {
    name: {
        "en": "New York",
        "ru": "Нью-Йорк"
    },
    country: {
        "en": "United States",
        "ru": "США"
    },
    image: "https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9",
    populationMillion: 8.8,
    population: {
        "en": "8.8 million",
        "ru": "8,8 млн"
    },
    description: {
        "en": "New York City is the most populous city in the United States and a global hub for finance, culture, art, and entertainment.",
        "ru": "Нью-Йорк - самый населенный город США и мировой центр финансов, культуры, искусства и развлечений."
    },
    founded: {
        "en": "1624",
        "ru": "1624"
    },
    landmarks: {
        "en": ["Statue of Liberty", "Empire State Building", "Central Park", "Times Square"],
        "ru": ["Статуя Свободы", "Эмпайр-стейт-билдинг", "Центральный парк", "Таймс-сквер"]
    },
    universities: {
        "en": ["Columbia University", "New York University", "City University of New York"],
        "ru": ["Колумбийский университет", "Нью-Йоркский университет", "Городской университет Нью-Йорка"]
    },
    industries: {
        "en": ["Finance", "Media", "Technology", "Fashion", "Tourism"],
        "ru": ["Финансы", "Медиа", "Технологии", "Мода", "Туризм"]
    },
    climate: {
        "en": "Humid subtropical climate with hot summers and cold winters",
        "ru": "Влажный субтропический климат с жарким летом и холодной зимой"
    },
    transportation: {
        "en": ["Subway", "Bus", "Ferry", "Taxi"],
        "ru": ["Метро", "Автобус", "Паром", "Такси"]
    },
    timeZone: "UTC-05:00",
    languages: {
        "en": ["English", "Spanish"],
        "ru": ["Английский", "Испанский"]
    },
    airports: {
        "en": ["John F. Kennedy International Airport", "LaGuardia Airport", "Newark Liberty International Airport"],
        "ru": ["Международный аэропорт имени Джона Кеннеди", "Аэропорт Ла-Гуардия", "Международный аэропорт Ньюарк Либерти"]
    },
    isCapital: false,
    coordinates: {
        latitude: 40.7128,
        longitude: -74.0060
    }
};
