import type { City } from '../../cities';

export const berlin: City = {
    name: {
        "en": "Berlin",
        "ru": "Берлин"
    },
    country: {
        "en": "Germany",
        "ru": "Германия"
    },
    image: "https://images.unsplash.com/photo-1560969184-10fe8719e047",
    populationMillion: 3.7,
    population: {
        "en": "3.7 million",
        "ru": "3,7 млн"
    },
    description: {
        "en": "Berlin is Germany's capital and largest city, known for its rich history, vibrant arts scene, and modern culture.",
        "ru": "Берлин - столица и крупнейший город Германии, известный своей богатой историей, яркой художественной сценой и современной культурой."
    },
    founded: {
        "en": "1237",
        "ru": "1237"
    },
    landmarks: {
        "en": ["Brandenburg Gate", "East Side Gallery", "Reichstag Building", "Museum Island"],
        "ru": ["Бранденбургские ворота", "Ист-Сайд-Галери", "Здание Рейхстага", "Музейный остров"]
    },
    universities: {
        "en": ["Humboldt University", "Free University of Berlin", "Technical University of Berlin"],
        "ru": ["Университет Гумбольдта", "Свободный университет Берлина", "Технический университет Берлина"]
    },
    industries: {
        "en": ["Technology", "Media", "Research", "Tourism"],
        "ru": ["Технологии", "Медиа", "Исследования", "Туризм"]
    },
    climate: {
        "en": "Temperate seasonal climate with warm summers and cool winters",
        "ru": "Умеренный сезонный климат с теплым летом и прохладной зимой"
    },
    transportation: {
        "en": ["U-Bahn", "S-Bahn", "Bus", "Tram"],
        "ru": ["U-Bahn", "S-Bahn", "Автобус", "Трамвай"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["German", "English"],
        "ru": ["Немецкий", "Английский"]
    },
    airports: {
        "en": ["Berlin Brandenburg Airport"],
        "ru": ["Аэропорт Берлин-Бранденбург"]
    },
    isCapital: true,
    coordinates: {
        latitude: 52.5200,
        longitude: 13.4050
    }
};
