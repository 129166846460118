<script lang="ts">
  import { t, activeLanguage } from "../../../translations/translations";
  import { countries as countriesData } from "./data/countries";
  import type { Country } from "./data/countries";
  import { Link } from "svelte-routing";
  import { onMount } from "svelte";

  // Get the country name from URL params instead of parsing location directly
  let countryName: string;
  let country: Country | undefined;

  onMount(() => {
    console.log("Mounted");
    countryName = location.pathname.split("/").pop() || "";
    country = countriesData.find(
      (c) =>
        c.name.en.toLowerCase() === countryName?.toLowerCase() ||
        c.countryCode.alpha2.toLowerCase() === countryName?.toLowerCase() ||
        c.countryCode.alpha3.toLowerCase() === countryName?.toLowerCase(),
    );
  });

  // Helper function for flag emoji
  function getCountryFlag(countryCode: string): string {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
  }

  // Helper function to format numbers with commas
  function formatNumber(num: number): string {
    return num.toLocaleString();
  }
</script>

{#if country}
  <div class="container mx-auto px-4 py-8">
    <!-- Breadcrumb -->
    <nav class="flex mb-6 text-txt-muted-light dark:text-txt-muted-dark">
      <Link
        to="/countries"
        class="hover:text-txt-body-light dark:hover:text-txt-body-dark"
      >
        {$t.countries.countries}
      </Link>
      <span class="mx-2">›</span>
      <span class="text-txt-body-light dark:text-txt-body-dark">
        {country.name[$activeLanguage] || country.name.en}
      </span>
    </nav>

    <!-- Hero section -->
    <div class="relative h-96 rounded-xl overflow-hidden mb-8">
      <img
        src={country.image}
        alt={country.name[$activeLanguage] || country.name.en}
        class="w-full h-full object-cover"
      />
      <div
        class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"
      >
        <div class="absolute bottom-0 left-0 p-8">
          <div class="flex items-center gap-4">
            <span class="text-5xl"
              >{getCountryFlag(country.countryCode.alpha2)}</span
            >
            <div>
              <h1 class="text-4xl font-bold text-white mb-2">
                {country.name[$activeLanguage] || country.name.en}
              </h1>
              <div class="text-white/80">
                {country.countryCode.alpha3} • {country.internetTLD}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Main content -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <!-- Key information -->
      <div class="lg:col-span-2 space-y-8">
        <!-- Basic info -->
        <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
          <h2 class="text-2xl font-semibold mb-4">
            {$t.countries.information}
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.capital}
              </p>
              <p class="text-lg">
                {country.capital[$activeLanguage] || country.capital.en}
              </p>
            </div>
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.continent}
              </p>
              <p class="text-lg">
                {country.continent[$activeLanguage] || country.continent.en}
              </p>
            </div>
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.population}
              </p>
              <p class="text-lg">
                {formatNumber(country.populationMillion)}
                {$t.countries.countryDetails.unit.million}
              </p>
            </div>
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.area}
              </p>
              <p class="text-lg">
                {formatNumber(country.areaTotal)}
                {country.areaUnit[$activeLanguage] || country.areaUnit.en}
              </p>
            </div>
          </div>
        </section>

        <!-- Economy -->
        <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
          <h2 class="text-2xl font-semibold mb-4">{$t.countries.economy}</h2>
          <div class="space-y-4">
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.gdp}
              </p>
              <p class="text-lg">
                {formatNumber(country.gdpBillion)}
                {$t.countries.countryDetails.unit.billion}
              </p>
            </div>
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.currency}
              </p>
              <p class="text-lg">
                {country.currency[$activeLanguage] || country.currency.en}
              </p>
            </div>
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.majorIndustries}
              </p>
              <div class="flex flex-wrap gap-2">
                {#each country.majorIndustries[$activeLanguage] || country.majorIndustries.en as industry}
                  <span
                    class="px-3 py-1 bg-form-input-light dark:bg-form-input-dark rounded-full"
                  >
                    {industry}
                  </span>
                {/each}
              </div>
            </div>
          </div>
        </section>

        <!-- Country Information -->
        <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
          <h2 class="text-2xl font-semibold mb-4">{$t.countries.about}</h2>
          <div class="space-y-6">
            <!-- Geography -->
            <div>
              <h3 class="text-xl font-semibold mb-2">
                {$t.countries.geography}
              </h3>
              <p
                class="text-txt-body-light dark:text-txt-body-dark leading-relaxed"
              >
                {country.geography[$activeLanguage] || country.geography.en}
              </p>
            </div>

            <!-- History -->
            <div>
              <h3 class="text-xl font-semibold mb-2">{$t.countries.history}</h3>
              <p
                class="text-txt-body-light dark:text-txt-body-dark leading-relaxed"
              >
                {country.history[$activeLanguage] || country.history.en}
              </p>
            </div>

            <!-- Culture -->
            <div>
              <h3 class="text-xl font-semibold mb-2">{$t.countries.culture}</h3>
              <p
                class="text-txt-body-light dark:text-txt-body-dark leading-relaxed"
              >
                {country.culture[$activeLanguage] || country.culture.en}
              </p>
            </div>

            <!-- Travel -->
            <div>
              <h3 class="text-xl font-semibold mb-2">{$t.countries.travel}</h3>
              <p
                class="text-txt-body-light dark:text-txt-body-dark leading-relaxed"
              >
                {country.travel[$activeLanguage] || country.travel.en}
              </p>
            </div>
          </div>
        </section>
      </div>

      <!-- Sidebar -->
      <div class="space-y-8">
        <!-- Languages and Communication -->
        <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
          <h2 class="text-2xl font-semibold mb-4">
            {$t.countries.communication}
          </h2>
          <div class="space-y-4">
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.languages}
              </p>
              <div class="flex flex-wrap gap-2">
                {#each country.languages[$activeLanguage] || country.languages.en as language}
                  <span
                    class="px-3 py-1 bg-form-input-light dark:bg-form-input-dark rounded-full"
                  >
                    {language}
                  </span>
                {/each}
              </div>
            </div>
            <div>
              <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                {$t.countries.countryDetails.phone}
              </p>
              <p class="text-lg">{country.phoneCode}</p>
            </div>
          </div>
        </section>

        <!-- Major Cities -->
        <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
          <h2 class="text-2xl font-semibold mb-4">
            {$t.countries.countryDetails.majorCities}
          </h2>
          <div class="space-y-2">
            {#each country.topCities[$activeLanguage] || country.topCities.en as city}
              <div
                class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg"
              >
                <Link
                  to={`/cities/${city.toLowerCase()}`}
                  class="block w-full hover:text-primary-light dark:hover:text-primary-dark"
                >
                  {city}
                </Link>
              </div>
            {/each}
          </div>
        </section>

        <!-- Time Zones -->
        <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
          <h2 class="text-2xl font-semibold mb-4">
            {$t.countries.countryDetails.timeZones}
          </h2>
          <div class="space-y-2">
            {#each country.timeZones as timezone}
              <div
                class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg"
              >
                {timezone}
              </div>
            {/each}
          </div>
        </section>
      </div>
    </div>
  </div>
{:else}
  <div class="container mx-auto px-4 py-8">
    <p>{$t.countries.notFound}</p>
  </div>
{/if}
