import type { City } from '../../cities';

export const milan: City = {
    name: {
        "en": "Milan",
        "ru": "Милан"
    },
    country: {
        "en": "Italy",
        "ru": "Италия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.pixabay.com%2Fphoto%2F2017%2F04%2F19%2F15%2F32%2Fmilano-2242609_960_720.jpg&f=1&nofb=1&ipt=4c5510160a505d80c489d9bbdac45b10653a9abcf6ff9e4d2333be3e8975082b&ipo=images",
    populationMillion: 3.1,
    population: {
        "en": "3.1 million",
        "ru": "3,1 млн"
    },
    description: {
        "en": "Milan is Italy's financial hub and fashion capital, known for its rich history, art, design, and luxury shopping.",
        "ru": "Милан - финансовый центр и столица моды Италии, известный своей богатой историей, искусством, дизайном и роскошными магазинами."
    },
    founded: {
        "en": "600 BC",
        "ru": "600 до н.э."
    },
    landmarks: {
        "en": ["Milan Cathedral", "Galleria Vittorio Emanuele II", "Sforza Castle", "Santa Maria delle Grazie"],
        "ru": ["Миланский собор", "Галерея Виктора Эммануила II", "Замок Сфорца", "Санта-Мария-делле-Грацие"]
    },
    universities: {
        "en": ["University of Milan", "Politecnico di Milano", "Bocconi University"],
        "ru": ["Миланский университет", "Миланский технический университет", "Университет Боккони"]
    },
    industries: {
        "en": ["Fashion", "Finance", "Design", "Manufacturing", "Tourism"],
        "ru": ["Мода", "Финансы", "Дизайн", "Производство", "Туризм"]
    },
    climate: {
        "en": "Humid subtropical climate with hot summers and cool winters",
        "ru": "Влажный субтропический климат с жарким летом и прохладной зимой"
    },
    transportation: {
        "en": ["Metro", "Tram", "Bus", "Suburban Railway"],
        "ru": ["Метро", "Трамвай", "Автобус", "Пригородная железная дорога"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["Italian", "English"],
        "ru": ["Итальянский", "Английский"]
    },
    airports: {
        "en": ["Milan Malpensa Airport", "Milan Linate Airport"],
        "ru": ["Аэропорт Милан-Мальпенса", "Аэропорт Милан-Линате"]
    },
    isCapital: false,
    coordinates: {
        latitude: 45.4642,
        longitude: 9.1900
    }
};
