<script lang="ts">
  import { fade, fly } from "svelte/transition";
  import { Link } from "svelte-routing";
  import { t } from "../../../translations/translations";
  import { auth } from "../../../stores/authStore";
  interface MenuItem {
    to: string;
    label: string;
  }

  export let isOpen: boolean;
  export let toggleMenu: () => void;
  export let menuItems: MenuItem[];

  const ANIMATION_DURATION = 200;
  const ANIMATION_DELAY_MULTIPLIER = 75;

  $: isLoggedIn = $auth.isLoggedIn;

  function handleMenuClick(isLogout: boolean = false): void {
    toggleMenu();
    if (isLogout) {
      auth.logout();
    }
  }

  function handleClickOutside(event: MouseEvent) {
    const menu = document.querySelector(".menu-container");
    if (menu && !menu.contains(event.target as Node) && isOpen) {
      toggleMenu();
    }
  }
</script>

<!-- Menu Button -->
<button
  class="relative w-8 h-8 flex items-center justify-center focus:outline-none"
  on:click={toggleMenu}
  aria-label="Toggle menu"
  aria-expanded={isOpen}
  aria-controls="mobile-menu"
>
  <div class="relative w-6 h-4">
    <div
      class="absolute w-full h-[2px] bg-txt-body-dark transform transition-all duration-300 ease-in-out"
      class:rotate-0={!isOpen}
      class:rotate-45={isOpen}
      class:translate-y-[7px]={isOpen}
    />
    <div
      class="absolute w-full h-[2px] bg-txt-body-dark top-[7px] transform transition-all duration-300 ease-in-out"
      class:opacity-100={!isOpen}
      class:opacity-0={isOpen}
    />
    <div
      class="absolute w-full h-[2px] bg-txt-body-dark top-[14px] transform transition-all duration-300 ease-in-out"
      class:rotate-0={!isOpen}
      class:-rotate-45={isOpen}
      class:-translate-y-[7px]={isOpen}
    />
  </div>
</button>

<!-- svelte-ignore a11y-no-static-element-interactions -->
{#if isOpen}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="fixed inset-0 bg-black bg-opacity-50 z-50"
    on:click={handleClickOutside}
  >
    <div
      class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
    >
      <div
        class="menu-container bg-form-light dark:bg-form-dark rounded-lg shadow-lg p-6 w-11/12 max-w-md"
      >
        <!-- <h2 class="text-2xl font-bold mb-4 text-center text-slate-800 dark:text-white">Menu</h2> -->
        <ul class="space-y-4">
          {#if isLoggedIn}
            {#each menuItems as item}
              <li>
                <Link
                  to={item.to}
                  on:click={() =>
                    handleMenuClick(item.label === $t.menu.logout)}
                  class="block text-center text-lg font-medium text-txt-body-light dark:text-txt-body-dark hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover transition-colors duration-200"
                >
                  {item.label}
                </Link>
              </li>
            {/each}
            <li>
              <a
                href="/"
                on:click|preventDefault={() => handleMenuClick(true)}
                class="block text-center text-lg font-medium text-txt-body-light dark:text-txt-body-dark hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover transition-colors duration-200"
              >
                {$t.menu.logout}
              </a>
            </li>
          {:else}
            <li>
              <Link
                to="/login"
                on:click={() => handleMenuClick()}
                class="block text-center text-lg font-medium text-txt-body-light dark:text-txt-body-dark hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover transition-colors duration-200"
              >
                {$t.menu.login}
              </Link>
            </li>
          {/if}
        </ul>
      </div>
    </div>
  </div>
{/if}
