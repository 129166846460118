import type { City } from '../../cities';

export const birmingham: City = {
    name: {
        "en": "Birmingham",
        "ru": "Бирмингем"
    },
    country: {
        "en": "United Kingdom",
        "ru": "Великобритания"
    },
    image: "https://images.unsplash.com/photo-1486299267070-83823f5448dd",
    populationMillion: 2.6,
    population: {
        "en": "2.6 million",
        "ru": "2,6 млн"
    },
    description: {
        "en": "Birmingham is Britain's second-largest city and a major industrial and commercial center, known for its rich industrial heritage and cultural diversity.",
        "ru": "Бирмингем - второй по величине город Великобритании и крупный промышленный и коммерческий центр, известный своим богатым индустриальным наследием и культурным разнообразием."
    },
    founded: {
        "en": "600s AD",
        "ru": "600-е н.э."
    },
    landmarks: {
        "en": ["Bullring Shopping Centre", "Birmingham Museum & Art Gallery", "Library of Birmingham", "Cadbury World"],
        "ru": ["Торговый центр Буллринг", "Музей и художественная галерея Бирмингема", "Библиотека Бирмингема", "Мир Кэдбери"]
    },
    universities: {
        "en": ["University of Birmingham", "Aston University", "Birmingham City University"],
        "ru": ["Университет Бирмингема", "Университет Астон", "Городской университет Бирмингема"]
    },
    industries: {
        "en": ["Manufacturing", "Services", "Technology", "Education"],
        "ru": ["Производство", "Услуги", "Технологии", "Образование"]
    },
    climate: {
        "en": "Temperate maritime climate with mild winters and cool summers",
        "ru": "Умеренный морской климат с мягкими зимами и прохладным летом"
    },
    transportation: {
        "en": ["Train", "Bus", "Tram", "Bicycle"],
        "ru": ["Поезд", "Автобус", "Трамвай", "Велосипед"]
    },
    timeZone: "UTC+00:00",
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    airports: {
        "en": ["Birmingham Airport"],
        "ru": ["Аэропорт Бирмингема"]
    },
    isCapital: false,
    coordinates: {
        latitude: 52.4862,
        longitude: -1.8904
    }
};
