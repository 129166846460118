import type { City } from '../../cities';

export const paris: City = {
    name: {
        "en": "Paris",
        "ru": "Париж"
    },
    country: {
        "en": "France",
        "ru": "Франция"
    },
    image: "https://images.unsplash.com/photo-1502602898657-3e91760cbb34",
    populationMillion: 11.1,
    population: {
        "en": "11.1 million",
        "ru": "11,1 млн"
    },
    description: {
        "en": "Paris is the capital and largest city of France, known for its art, culture, fashion, and iconic landmarks.",
        "ru": "Париж - столица и крупнейший город Франции, известный своим искусством, культурой, модой и знаковыми достопримечательностями."
    },
    founded: {
        "en": "3rd century BC",
        "ru": "3 век до н.э."
    },
    landmarks: {
        "en": ["Eiffel Tower", "Louvre Museum", "Notre-Dame Cathedral", "Arc de Triomphe"],
        "ru": ["Эйфелева башня", "Лувр", "Собор Парижской Богоматери", "Триумфальная арка"]
    },
    universities: {
        "en": ["Sorbonne University", "École Polytechnique", "Sciences Po"],
        "ru": ["Университет Сорбонна", "Политехническая школа", "Sciences Po"]
    },
    industries: {
        "en": ["Tourism", "Fashion", "Technology", "Finance", "Luxury Goods"],
        "ru": ["Туризм", "Мода", "Технологии", "Финансы", "Предметы роскоши"]
    },
    climate: {
        "en": "Oceanic climate with warm summers and mild winters",
        "ru": "Океанический климат с теплым летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "RER", "Bus", "Tram"],
        "ru": ["Метро", "RER", "Автобус", "Трамвай"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["French", "English"],
        "ru": ["Французский", "Английский"]
    },
    airports: {
        "en": ["Charles de Gaulle Airport", "Orly Airport"],
        "ru": ["Аэропорт Шарль-де-Голль", "Аэропорт Орли"]
    },
    isCapital: true,
    coordinates: {
        latitude: 48.8566,
        longitude: 2.3522
    }
};