import type { Country } from '../countries';

export const china: Country = {
    name: {
        "en": "China",
        "ru": "Китай"
    },
    image: "https://images.unsplash.com/photo-1547981609-4b6bfe67ca0b",
    gdpBillion: 17730,
    gdp: {
        "en": "17,730 billion USD",
        "ru": "17,730 млрд долл. США"
    },
    currency: {
        "en": "Chinese Yuan (¥)",
        "ru": "Китайский юань (¥)"
    },
    topCities: {
        "en": ["Shanghai", "Beijing", "Shenzhen"],
        "ru": ["Шанхай", "Пекин", "Шэньчжэнь"]
    },
    populationMillion: 1410,
    population: {
        "en": "1.41 billion",
        "ru": "1,41 млрд"
    },
    capital: {
        "en": "Beijing",
        "ru": "Пекин"
    },
    languages: {
        "en": ["Chinese"],
        "ru": ["Китайский"]
    },
    continent: {
        "en": "Asia",
        "ru": "Азия"
    },
    timeZones: ["UTC+08:00"],
    drivingSide: "right",
    countryCode: {
        alpha2: "CN",
        alpha3: "CHN"
    },
    government: {
        "en": "Unitary socialist state",
        "ru": "Унитарное социалистическое государство"
    },
    areaTotal: 9600000,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Temperate", "Subtropical", "Tropical"],
        "ru": ["Умеренный", "Субтропический", "Тропический"]
    },
    landmarks: {
        "en": ["Great Wall of China", "Tiananmen Square", "Forbidden City"],
        "ru": ["Великая Китайская стена", "Тайаньмень", "Запретный город"]
    },
    internetTLD: ".cn",
    phoneCode: "+86",
    majorCompanies: {
        "en": ["Alibaba", "Tencent", "PetroChina", "China Mobile", "Industrial and Commercial Bank of China"],
        "ru": ["Alibaba", "Tencent", "PetroChina", "China Mobile", "Industrial and Commercial Bank of China"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "China is the world's most populous country, with a vast territory spanning diverse landscapes from the Gobi Desert to the tropical rainforests of the south.",
        "ru": "Китай - самый населенный государство в мире, с обширной территорией, простирающейся от пустыни Гоби до тропических rainforests южного Китая."
    },
    history: {
        "en": "China's history spans over 5,000 years, with notable dynasties such as the Xia, Shang, and Zhou, followed by the Qin and Han Empires.",
        "ru": "История Китая насчитывает более 5000 лет, с заметными династии, такими как Xia, Shang и Zhou, за которыми следовали империи Цин и Хан."
    },
    culture: {
        "en": "Chinese culture is rich and diverse, with influences from Taoism, Buddhism, and Confucianism, known for its unique art forms and cuisine.",
        "ru": "Китайская культура богата и разнообразна, с влиянием таоизма, буддизма и конфуцианства, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "China offers a wide range of travel experiences from ancient historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Китай предлагает широкий спектр путешествий от древних исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};