import type { City } from '../../cities';

export const montreal: City = {
    name: {
        "en": "Montreal",
        "ru": "Монреаль"
    },
    country: {
        "en": "Canada",
        "ru": "Канада"
    },
    image: "https://images.unsplash.com/photo-1519178614-68673b201f36",
    populationMillion: 4.2,
    population: {
        "en": "4.2 million",
        "ru": "4,2 млн"
    },
    description: {
        "en": "Montreal is Canada's second-largest city and the largest in Quebec province. It's known for its rich cultural heritage, combining European charm with North American modernity.",
        "ru": "Монреаль - второй по величине город Канады и крупнейший город провинции Квебек. Известен богатым культурным наследием, сочетающим европейское очарование с североамериканской современностью."
    },
    founded: {
        "en": "1642",
        "ru": "1642"
    },
    landmarks: {
        "en": ["Notre-Dame Basilica", "Mount Royal", "Old Montreal", "Olympic Stadium"],
        "ru": ["Базилика Нотр-Дам", "Гора Мон-Руаяль", "Старый Монреаль", "Олимпийский стадион"]
    },
    universities: {
        "en": ["McGill University", "Université de Montréal", "Concordia University"],
        "ru": ["Университет Макгилла", "Монреальский университет", "Университет Конкордия"]
    },
    industries: {
        "en": ["Technology", "Aerospace", "Film Industry", "Tourism"],
        "ru": ["Технологии", "Аэрокосмическая промышленность", "Киноиндустрия", "Туризм"]
    },
    climate: {
        "en": "Humid continental climate with warm summers and cold snowy winters",
        "ru": "Влажный континентальный климат с теплым летом и холодной снежной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Commuter Rail", "Bixi Bikes"],
        "ru": ["Метро", "Автобус", "Пригородные поезда", "Велопрокат Bixi"]
    },
    timeZone: "UTC-05:00",
    languages: {
        "en": ["French", "English"],
        "ru": ["Французский", "Английский"]
    },
    airports: {
        "en": ["Montréal-Pierre Elliott Trudeau International Airport"],
        "ru": ["Международный аэропорт имени Пьера Эллиота Трюдо"]
    },
    isCapital: false,
    coordinates: {
        latitude: 45.5017,
        longitude: -73.5673
    }
};
