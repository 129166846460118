<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { auth } from "../../stores/authStore";
  let isLoggedIn = false;

  // Subscribe to changes in the user's login status
  auth.subscribe((value) => {
    isLoggedIn = value.isLoggedIn;
  });

  // onMount(async () => {
  //   console.log("Hub mounted");
  //   await user.getAuthStatus();
  //   if (!isLoggedIn) {
  //     console.log("User is not logged in, redirecting to /login");
  //     navigate("/login");
  //   } else {
  //     console.log("User is logged in, redirecting to /status");
  //     navigate("/status");
  //   }
  // });

</script>

<div>
  Hub here
</div>
