import type { City } from '../../cities';

export const busan: City = {
    name: {
        "en": "Busan",
        "ru": "Пусан"
    },
    country: {
        "en": "South Korea",
        "ru": "Южная Корея"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd.newsweek.com%2Fen%2Ffull%2F2073632%2Fbusan-south-korea-city-view-night.jpg&f=1&nofb=1&ipt=0261f6a125f4c97be12eb427e382c793228b4614615efbfe0499d888137adac7&ipo=images",
    populationMillion: 3.4,
    population: {
        "en": "3.4 million",
        "ru": "3,4 млн"
    },
    description: {
        "en": "Busan is South Korea's second-largest city and busiest port, known for its beaches, mountains, temples, and vibrant seafood markets.",
        "ru": "Пусан - второй по величине город Южной Кореи и самый загруженный порт, известный своими пляжами, горами, храмами и оживленными рыбными рынками."
    },
    founded: {
        "en": "1876",
        "ru": "1876"
    },
    landmarks: {
        "en": ["Gamcheon Culture Village", "Haedong Yonggungsa Temple", "Haeundae Beach", "Busan Tower"],
        "ru": ["Культурная деревня Камчхон", "Храм Хэдон Ёнгунса", "Пляж Хэундэ", "Пусанская башня"]
    },
    universities: {
        "en": ["Pusan National University", "Dong-A University", "Busan University of Foreign Studies"],
        "ru": ["Пусанский национальный университет", "Университет Донг-А", "Пусанский университет иностранных языков"]
    },
    industries: {
        "en": ["Shipping", "Tourism", "Manufacturing", "Film Industry"],
        "ru": ["Судоходство", "Туризм", "Производство", "Киноиндустрия"]
    },
    climate: {
        "en": "Humid subtropical climate with warm summers and mild winters",
        "ru": "Влажный субтропический климат с теплым летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Train", "Ferry"],
        "ru": ["Метро", "Автобус", "Поезд", "Паром"]
    },
    timeZone: "UTC+09:00",
    languages: {
        "en": ["Korean", "English"],
        "ru": ["Корейский", "Английский"]
    },
    airports: {
        "en": ["Gimhae International Airport"],
        "ru": ["Международный аэропорт Кимхэ"]
    },
    isCapital: false,
    coordinates: {
        latitude: 35.1796,
        longitude: 129.0756
    }
};
