<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { onMount } from "svelte";
    import { t, activeLanguage } from "../../../translations/translations";

    export let uniqueCurrencies: string[] = [];
    export let uniqueContinents: string[] = [];
    export let uniqueLanguages: string[] = [];
    export let initialVisibilitySettings: Record<string, boolean>;
    export let isLargeBox: boolean;

    const dispatch = createEventDispatcher();

    // Filter state
    let gdpBMin = "";
    let gdpBMax = "";
    let popMMin = "";
    let popMMax = "";
    let selectedCurrency = "all";
    let selectedContinent = "all";
    let selectedLanguage = "all";
    let sortBy = "name";
    let sortOrder = "asc";

    // Change initial filter visibility to false to match CityFilters
    let showFilters = false;

    // Add new visibility state
    let visibilitySettings = { ...initialVisibilitySettings };

    function updateFilters() {
        dispatch("filterChange", {
            gdpBMin,
            gdpBMax,
            popMMin,
            popMMax,
            selectedCurrency,
            selectedContinent,
            selectedLanguage,
            sortBy,
            sortOrder,
            visibilitySettings,
        });
    }

    function clearFilters() {
        gdpBMin = "";
        gdpBMax = "";
        popMMin = "";
        popMMax = "";
        selectedCurrency = "all";
        selectedContinent = "all";
        selectedLanguage = "all";
        sortBy = "name";
        sortOrder = "asc";
        visibilitySettings = { ...initialVisibilitySettings };
        updateFilters();
    }

    // Update filters whenever any value changes
    $: {
        gdpBMin,
            gdpBMax,
            popMMin,
            popMMax,
            selectedCurrency,
            selectedContinent,
            selectedLanguage,
            sortBy,
            sortOrder,
            visibilitySettings;
        updateFilters();
    }

    // Trigger initial filter on mount
    onMount(() => {
        updateFilters();
    });

    function toggleSize() {
        dispatch("sizeChange", !isLargeBox);
    }
</script>

<div class="bg-box-light dark:bg-box-dark p-6 rounded-xl mb-4">
    <!-- Top controls - simplify to match CityFilters -->
    <div class="flex flex-wrap items-center gap-4">
        <div class="flex items-center gap-2 text-sm flex-wrap">
            <button
                on:click={() => (showFilters = !showFilters)}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent flex items-center gap-1"
            >
                <span class="font-medium">🏷️</span>
            </button>
            <select
                bind:value={sortBy}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent appearance-none max-w-[160px]"
            >
                <option value="name"
                    >{$t.countries.filters.sortOptions.countryName}</option
                >
                <option value="gdp"
                    >{$t.countries.filters.sortOptions.gdp}</option
                >
                <option value="population"
                    >{$t.countries.filters.sortOptions.population}</option
                >
                <option value="area"
                    >{$t.countries.filters.sortOptions.area}</option
                >
            </select>

            <button
                on:click={() =>
                    (sortOrder = sortOrder === "asc" ? "desc" : "asc")}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent w-8 text-center"
                title={sortOrder === "asc"
                    ? $t.countries.filters.ascending
                    : $t.countries.filters.descending}
            >
                {sortOrder === "asc" ? "↑" : "↓"}
            </button>

            <button
                on:click={toggleSize}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent w-8 text-center"
                title={isLargeBox
                    ? $t.countries.filters.compact
                    : $t.countries.filters.expanded}
            >
                {isLargeBox ? "A" : "a"}
            </button>
        </div>
        <button
            on:click={clearFilters}
            class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent flex items-center gap-1"
        >
            <span class="font-medium"> 🧹</span>
        </button>
    </div>

    <!-- Wrapped filters -->
    {#if showFilters}
        <div class="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <!-- GDP Range -->
            <div class="space-y-2">
                <label
                    for="gdpMin"
                    class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                >
                    {$t.countries.filters.gdp}
                </label>
                <div class="flex gap-2">
                    <div>
                        <input
                            id="gdpMin"
                            type="number"
                            bind:value={gdpBMin}
                            placeholder={$t.countries.filters.from}
                            class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent placeholder-form-placeholder-light dark:placeholder-form-placeholder-dark"
                        />
                    </div>
                    <div>
                        <input
                            id="gdpMax"
                            type="number"
                            bind:value={gdpBMax}
                            placeholder={$t.countries.filters.to}
                            aria-label="{$t.countries.filters.gdp} {$t.countries
                                .filters.to}"
                            class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent placeholder-form-placeholder-light dark:placeholder-form-placeholder-dark"
                        />
                    </div>
                </div>
            </div>

            <!-- Population Range -->
            <div class="space-y-2">
                <label
                    for="populationMin"
                    class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                >
                    {$t.countries.filters.population}
                </label>
                <div class="flex gap-2">
                    <div>
                        <input
                            id="populationMin"
                            type="number"
                            bind:value={popMMin}
                            placeholder={$t.countries.filters.from}
                            class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent placeholder-form-placeholder-light dark:placeholder-form-placeholder-dark"
                        />
                    </div>
                    <div>
                        <input
                            id="populationMax"
                            type="number"
                            bind:value={popMMax}
                            placeholder={$t.countries.filters.to}
                            class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent placeholder-form-placeholder-light dark:placeholder-form-placeholder-dark"
                        />
                    </div>
                </div>
            </div>

            <!-- Continent Filter -->
            <div class="space-y-2">
                <label
                    for="continent"
                    class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                >
                    {$t.countries.filters.continent}
                </label>
                <select
                    id="continent"
                    bind:value={selectedContinent}
                    class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent appearance-none"
                >
                    <option value="all"
                        >{$t.countries.filters.allContinents}</option
                    >
                    {#each uniqueContinents as continent}
                        <option value={continent}>{continent}</option>
                    {/each}
                </select>
            </div>

            <!-- Currency Filter -->
            <div class="space-y-2">
                <div class="flex gap-2">
                    <div class="w-full">
                        <label
                            for="currency"
                            class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                        >
                            {$t.countries.filters.currency}
                        </label>
                        <select
                            id="currency"
                            bind:value={selectedCurrency}
                            class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent appearance-none"
                        >
                            <option value="all"
                                >{$t.countries.filters.allCurrencies}</option
                            >
                            {#each uniqueCurrencies as currency}
                                <option value={currency}>{currency}</option>
                            {/each}
                        </select>
                    </div>
                </div>
            </div>

            <!-- Language Filter -->
            <div class="space-y-2">
                <div class="flex gap-2">
                    <div class="w-full">
                        <label
                            for="language"
                            class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                        >
                            {$t.countries.filters.language}
                        </label>
                        <select
                            id="language"
                            bind:value={selectedLanguage}
                            class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent appearance-none"
                        >
                            <option value="all"
                                >{$t.countries.filters.allLanguages}</option
                            >
                            {#each uniqueLanguages as language}
                                <option value={language}>{language}</option>
                            {/each}
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add this after the existing filter controls -->
        <div class="space-y-2 col-span-full mt-6">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label
                class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                id="visibility-group"
            >
                {$t.countries.filters.visibilityTitle}
            </label>
            <div
                class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"
                role="group"
                aria-labelledby="visibility-group"
            >
                {#each Object.entries(visibilitySettings) as [key, value]}
                    <label class="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            bind:checked={visibilitySettings[key]}
                            class="rounded border-form-border-light dark:border-form-border-dark text-accent-light focus:ring-accent-light"
                        />
                        <span
                            class="text-sm text-txt-body-light dark:text-txt-body-dark"
                        >
                            {$t.countries.countryDetails[key]}
                        </span>
                    </label>
                {/each}
            </div>
        </div>
    {/if}
</div>
