import type { City } from '../../cities';

export const novosibirsk: City = {
    name: {
        "en": "Novosibirsk",
        "ru": "Новосибирск"
    },
    country: {
        "en": "Russia",
        "ru": "Россия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fcommons%2Fthumb%2Fd%2Fda%2FNovosibirsk_skyline_in_winter.jpg%2F399px-Novosibirsk_skyline_in_winter.jpg&f=1&nofb=1&ipt=4177482882af4a9368fbecc600c35baf1fffa12b765dc03e71ad51afc5b37a45&ipo=images",
    populationMillion: 1.6,
    population: {
        "en": "1.6 million",
        "ru": "1,6 млн"
    },
    description: {
        "en": "Novosibirsk is Russia's third-largest city and the largest in Siberia. It's an important scientific, cultural, industrial and transportation hub.",
        "ru": "Новосибирск - третий по величине город России и крупнейший в Сибири. Это важный научный, культурный, промышленный и транспортный центр."
    },
    founded: {
        "en": "1893",
        "ru": "1893"
    },
    landmarks: {
        "en": ["Novosibirsk Opera and Ballet Theatre", "Alexander Nevsky Cathedral", "Akademgorodok", "Novosibirsk Zoo"],
        "ru": ["Новосибирский театр оперы и балета", "Собор Александра Невского", "Академгородок", "Новосибирский зоопарк"]
    },
    universities: {
        "en": ["Novosibirsk State University", "Novosibirsk State Technical University", "Siberian State University of Transport"],
        "ru": ["Новосибирский государственный университет", "Новосибирский государственный технический университет", "Сибирский государственный университет путей сообщения"]
    },
    industries: {
        "en": ["Science", "Technology", "Manufacturing", "Nuclear Research"],
        "ru": ["Наука", "Технологии", "Производство", "Ядерные исследования"]
    },
    climate: {
        "en": "Humid continental climate with warm summers and very cold winters",
        "ru": "Влажный континентальный климат с теплым летом и очень холодной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Tram", "Trolleybus"],
        "ru": ["Метро", "Автобус", "Трамвай", "Троллейбус"]
    },
    timeZone: "UTC+07:00",
    languages: {
        "en": ["Russian", "English"],
        "ru": ["Русский", "Английский"]
    },
    airports: {
        "en": ["Novosibirsk Tolmachevo Airport"],
        "ru": ["Аэропорт Новосибирск Толмачёво"]
    },
    isCapital: false,
    coordinates: {
        latitude: 55.0084,
        longitude: 82.9357
    }
};
