import type { City } from '../../cities';

export const kyoto: City = {
    name: {
        "en": "Kyoto",
        "ru": "Киото"
    },
    country: {
        "en": "Japan",
        "ru": "Япония"
    },
    image: "https://images.unsplash.com/photo-1493976040374-85c8e12f0c0e",
    populationMillion: 1.5,
    population: {
        "en": "1.5 million",
        "ru": "1,5 млн"
    },
    description: {
        "en": "Kyoto, the former capital of Japan, is a city of incredible cultural heritage with over 1,600 Buddhist temples, Shinto shrines, and 17 UNESCO World Heritage sites.",
        "ru": "Киото, бывшая столица Японии, - город с невероятным культурным наследием, включающим более 1600 буддийских храмов, синтоистских святилищ и 17 объектов Всемирного наследия ЮНЕСКО."
    },
    founded: {
        "en": "794",
        "ru": "794"
    },
    landmarks: {
        "en": ["Kinkaku-ji", "Fushimi Inari Shrine", "Kiyomizu-dera", "Nijo Castle"],
        "ru": ["Кинкаку-дзи", "Святилище Фусими Инари", "Киёмидзу-дэра", "Замок Нидзё"]
    },
    universities: {
        "en": ["Kyoto University", "Doshisha University", "Ritsumeikan University"],
        "ru": ["Киотский университет", "Университет Досися", "Университет Рицумэйкан"]
    },
    industries: {
        "en": ["Tourism", "Traditional Crafts", "Technology", "Education"],
        "ru": ["Туризм", "Традиционные ремесла", "Технологии", "Образование"]
    },
    climate: {
        "en": "Humid subtropical climate with hot summers and cool winters",
        "ru": "Влажный субтропический климат с жарким летом и прохладной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Train", "Bicycle"],
        "ru": ["Метро", "Автобус", "Поезд", "Велосипед"]
    },
    timeZone: "UTC+09:00",
    languages: {
        "en": ["Japanese", "English"],
        "ru": ["Японский", "Английский"]
    },
    airports: {
        "en": ["Kansai International Airport", "Itami Airport"],
        "ru": ["Международный аэропорт Кансай", "Аэропорт Итами"]
    },
    isCapital: false,
    coordinates: {
        latitude: 35.0116,
        longitude: 135.7681
    }
};
