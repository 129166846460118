import type { City } from '../../cities';

export const melbourne: City = {
    name: {
        "en": "Melbourne",
        "ru": "Мельбурн"
    },
    country: {
        "en": "Australia",
        "ru": "Австралия"
    },
    image: "https://images.unsplash.com/photo-1514395462725-fb4566210144",
    populationMillion: 5.1,
    population: {
        "en": "5.1 million",
        "ru": "5,1 млн"
    },
    description: {
        "en": "Melbourne is Australia's cultural capital and second-largest city. Known for its coffee culture, arts scene, and sporting events.",
        "ru": "Мельбурн - культурная столица Австралии и второй по величине город. Известен своей кофейной культурой, художественной сценой и спортивными мероприятиями."
    },
    founded: {
        "en": "1835",
        "ru": "1835"
    },
    landmarks: {
        "en": ["Federation Square", "Royal Botanic Gardens", "Melbourne Cricket Ground", "Flinders Street Station"],
        "ru": ["Площадь Федерации", "Королевские ботанические сады", "Мельбурнский крикетный стадион", "Вокзал Флиндерс-стрит"]
    },
    universities: {
        "en": ["University of Melbourne", "Monash University", "RMIT University"],
        "ru": ["Мельбурнский университет", "Университет Монаша", "Университет RMIT"]
    },
    industries: {
        "en": ["Finance", "Education", "Tourism", "Technology", "Arts"],
        "ru": ["Финансы", "Образование", "Туризм", "Технологии", "Искусство"]
    },
    climate: {
        "en": "Temperate oceanic climate with warm summers and mild winters",
        "ru": "Умеренный океанический климат с теплым летом и мягкой зимой"
    },
    transportation: {
        "en": ["Tram", "Train", "Bus", "Bike Share"],
        "ru": ["Трамвай", "Поезд", "Автобус", "Велопрокат"]
    },
    timeZone: "UTC+10:00",
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    airports: {
        "en": ["Melbourne Airport", "Avalon Airport"],
        "ru": ["Аэропорт Мельбурна", "Аэропорт Авалон"]
    },
    isCapital: false,
    coordinates: {
        latitude: -37.8136,
        longitude: 144.9631
    }
};
