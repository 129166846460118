export type CountriesTranslation = {
    searchPlaceholder: string;
    countries: string;
    information: string;
    about: string;
    economy: string;
    communication: string;
    geography: string;
    history: string;
    culture: string;
    travel: string;
    notFound: string;
    filters: {
        from: string;
        to: string;
        title: string;
        sort: string;
        clear: string;
        gdp: string;
        population: string;
        continent: string;
        allContinents: string;
        currency: string;
        allCurrencies: string;
        language: string;
        allLanguages: string;
        sortOptions: {
            countryName: string;
            gdp: string;
            population: string;
            area: string;
        };
        ascending: string;
        descending: string;
        visibilityTitle: string;
    };
    countryDetails: {
        capital: string;
        gdp: string;
        population: string;
        currency: string;
        continent: string;
        area: string;
        phone: string;
        domain: string;
        languages: string;
        majorCities: string;
        majorCompanies: string;
        majorIndustries: string;
        timeZones: string;
        unit: {
            billion: string;
            million: string;
            km2: string;
        };
    };
    loading: string;
};

export const countriesTranslations: Record<string, CountriesTranslation> = {
    en: {
        searchPlaceholder: "Search countries...",
        countries: "Countries",
        information: "Information",
        about: "About",
        economy: "Economy",
        communication: "Communication",
        geography: "Geography",
        history: "History",
        culture: "Culture",
        travel: "Travel",
        notFound: "Country not found",
        filters: {
            from: "From",
            to: "To",
            title: "Filters",
            sort: "Sort",
            clear: "Clear",
            gdp: "GDP (Billion $)",
            population: "Population (Million)",
            continent: "Continent",
            allContinents: "All Continents",
            currency: "Currency",
            allCurrencies: "All Currencies",
            language: "Language",
            allLanguages: "All Languages",
            sortOptions: {
                countryName: "Name",
                gdp: "GDP",
                population: "Population",
                area: "Area"
            },
            ascending: "Ascending",
            descending: "Descending",
            visibilityTitle: "Show fields"
        },
        countryDetails: {
            capital: "Capital",
            gdp: "GDP",
            population: "Population",
            currency: "Currency",
            continent: "Continent",
            area: "Area",
            phone: "Phone Code",
            domain: "Domain",
            languages: "Languages",
            majorCities: "Major Cities",
            majorCompanies: "Major Companies",
            majorIndustries: "Major Industries",
            timeZones: "Time Zones",
            unit: {
                billion: "B$",
                million: "M",
                km2: "km²"
            }
        },
        loading: "Loading countries..."
    },
    ru: {
        searchPlaceholder: "Поиск стран...",
        countries: "Страны",
        information: "Информация",
        about: "О стране",
        economy: "Экономика",
        communication: "Коммуникации",
        geography: "География",
        history: "История",
        culture: "Культура",
        travel: "Путешествия",
        notFound: "Страна не найдена",
        filters: {
            from: "От",
            to: "До",
            title: "Фильтры",
            sort: "Сортировка",
            clear: "Очистить",
            gdp: "ВВП (Миллиард $)",
            population: "Население (Миллион)",
            continent: "Континент",
            allContinents: "Все континенты",
            currency: "Валюта",
            allCurrencies: "Все валюты",
            language: "Язык",
            allLanguages: "Все языки",
            sortOptions: {
                countryName: "Название",
                gdp: "ВВП",
                population: "Население",
                area: "Площадь"
            },
            ascending: "По возрастанию",
            descending: "По убыванию",
            visibilityTitle: "Показывать поля"
        },
        countryDetails: {
            capital: "Столица",
            gdp: "ВВП",
            population: "Население",
            currency: "Валюта",
            continent: "Континент",
            area: "Площадь",
            phone: "Телефонный код",
            domain: "Домен",
            languages: "Языки",
            majorCities: "Крупные города",
            majorCompanies: "Крупные компании",
            majorIndustries: "Крупные отрасли",
            timeZones: "Часовые пояса",
            unit: {
                billion: "Млрд $",
                million: "Млн Ч",
                km2: "км²"
            }
        },
        loading: "Загрузка стран..."
    }
};