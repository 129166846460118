<script lang="ts">
  import type { City } from "./data/cities";
  import { cities as CitiesData } from "./data/cities";
  import CityFilters from "./CityFilters.svelte";
  import CityBox from "./CityBox.svelte";
  import { t, activeLanguage } from "../../../translations/translations";
  import { onMount } from 'svelte';
  import SEO from '../../../lib/components/SEO.svelte';

  let cities: City[] = CitiesData;
  let filteredCities: City[] = cities;
  let searchQuery: string = "";

  // Create initial visibility settings
  const initialVisibilitySettings = {
    population: true,
    description: true,
    founded: false,
    landmarks: false,
    universities: false,
    industries: false,
    climate: false,
    transportation: false,
    airports: false,
    languages: false
  };

  let currentVisibilitySettings = { ...initialVisibilitySettings };

  // Add isLargeBox state
  let isLargeBox = true;

  // Reactive statement to update filtered cities when language changes
  $: {
    if (cities) {
      filteredCities = cities.filter(city => {
        const name = getName(city).toLowerCase();
        return name.includes(searchQuery.toLowerCase());
      });
    }
  }

  // Reactive statements to update unique values when language changes
  $: uniqueCountries =
    cities && $activeLanguage
      ? [...new Set(cities.map((city) => getCountry(city)))].sort()
      : [];

  $: uniqueLanguages =
    cities && $activeLanguage
      ? [...new Set(cities.flatMap((city) => getLanguages(city)))].sort()
      : [];

  // Functions to get translated values
  function getName(city: City): string {
    return city.name[$activeLanguage] || city.name.en;
  }

  function getCountry(city: City): string {
    return city.country[$activeLanguage] || city.country.en;
  }

  function getLanguages(city: City): string[] {
    return city.languages[$activeLanguage] || city.languages.en;
  }

  function handleFilters(event: CustomEvent) {
    if (!cities) return;

    const { visibilitySettings, ...filters } = event.detail;
    currentVisibilitySettings = visibilitySettings;

    filteredCities = cities.filter((city) => {
      const name = getName(city).toLowerCase();
      if (!name.includes(searchQuery.toLowerCase())) return false;

      const popM = city.populationMillion;

      if (filters.popMMin && popM < filters.popMMin) return false;
      if (filters.popMMax && popM > filters.popMMax) return false;

      const cityCountry = getCountry(city);
      const cityLanguages = getLanguages(city);

      if (filters.selectedCountry !== "all" && cityCountry !== filters.selectedCountry) return false;
      if (filters.selectedLanguage !== "all" && !cityLanguages.includes(filters.selectedLanguage)) return false;
      if (filters.isCapitalOnly && !city.isCapital) return false;

      return true;
    });

    filteredCities = sortCities(filteredCities, filters);
  }

  function sortCities(cities: City[], filters: any): City[] {
    return [...cities].sort((a, b) => {
      let valueA, valueB;

      switch (filters.sortBy) {
        case "population":
          valueA = a.populationMillion;
          valueB = b.populationMillion;
          break;
        default: // 'name'
          valueA = getName(a).toLowerCase();
          valueB = getName(b).toLowerCase();
          return filters.sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
      }

      // Numeric comparison
      if (filters.sortOrder === "asc") {
        return valueA - valueB;
      }
      return valueB - valueA;
    });
  }

  // Intersection Observer setup
  let observerOptions = {
    root: null,
    rootMargin: '50px',
    threshold: 0.1
  };

  let visibleCities = new Set();

  function observeElement(element: HTMLElement) {
    if (element && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          const cityIndex = parseInt(entry.target.dataset.index);
          if (entry.isIntersecting) {
            visibleCities.add(cityIndex);
            visibleCities = visibleCities;
          }
        });
      }, observerOptions);

      observer.observe(element);
      return {
        destroy() { observer.disconnect(); }
      };
    }
  }

  // Add new handler for size change
  function handleSizeChange(event: CustomEvent) {
    isLargeBox = event.detail;
  }
</script>

<!-- <SEO
  title="Cities Directory | Shindo.dev"
  description="Explore cities worldwide with comprehensive information about population, landmarks, universities, climate, and transportation systems."
  keywords="world cities, city information, city guides, urban areas, metropolitan regions"
  url="https://shindo.dev/cities"
/> -->

{#if cities && cities.length > 0}
  <div class="container mx-auto px-4 py-8">
    <div class="mb-4 relative">
      <input
        type="text"
        bind:value={searchQuery}
        placeholder={$t.cities.searchPlaceholder}
        class="w-full p-2 pr-10 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
      />
      {#if searchQuery}
        <button
          class="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          on:click={() => searchQuery = ""}
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </button>
      {/if}
    </div>

    <CityFilters
      {uniqueCountries}
      {uniqueLanguages}
      {isLargeBox}
      initialVisibilitySettings={initialVisibilitySettings}
      on:filterChange={handleFilters}
      on:sizeChange={handleSizeChange}
    />

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
      {#each filteredCities as city, index}
        <div
          data-index={index}
          use:observeElement
        >
          {#if visibleCities.has(index)}
            <CityBox
              {city}
              {isLargeBox}
              visibilitySettings={currentVisibilitySettings}
            />
          {:else}
            <div class="h-96 bg-box-light dark:bg-box-dark rounded-xl shadow-lg" />
          {/if}
        </div>
      {/each}
    </div>
  </div>
{:else}
  <div class="container mx-auto px-4 py-8">
    <p>{$t.cities.loading}</p>
  </div>
{/if}