import type { City } from '../../cities';

export const bangalore: City = {
    name: {
        "en": "Bangalore",
        "ru": "Бангалор"
    },
    country: {
        "en": "India",
        "ru": "Индия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fofficechai.com%2Fwp-content%2Fuploads%2F2018%2F01%2Fbangalore-worst-traffic-in-india-1024x538.jpg&f=1&nofb=1&ipt=cfd950e4ea056915a0812e4f2522018c6f21116ff0da1e031764bd26add45951&ipo=images",
    populationMillion: 12.3,
    population: {
        "en": "12.3 million",
        "ru": "12,3 млн"
    },
    description: {
        "en": "Bangalore is India's Silicon Valley and tech hub, known for its pleasant climate, parks, and vibrant startup ecosystem.",
        "ru": "Бангалор - индийская Кремниевая долина и технологический центр, известный своим приятным климатом, парками и динамичной экосистемой стартапов."
    },
    founded: {
        "en": "1537",
        "ru": "1537"
    },
    landmarks: {
        "en": ["Bangalore Palace", "Lalbagh Botanical Garden", "Cubbon Park", "Vidhana Soudha"],
        "ru": ["Дворец Бангалора", "Ботанический сад Лалбагх", "Парк Каббон", "Видхана Соудха"]
    },
    universities: {
        "en": ["Indian Institute of Science", "Bangalore University", "National Law School of India University"],
        "ru": ["Индийский институт науки", "Университет Бангалора", "Национальная юридическая школа Индийского университета"]
    },
    industries: {
        "en": ["Information Technology", "Biotechnology", "Aerospace", "Electronics"],
        "ru": ["Информационные технологии", "Биотехнологии", "Аэрокосмическая промышленность", "Электроника"]
    },
    climate: {
        "en": "Tropical savanna climate with mild temperatures year-round",
        "ru": "Тропический саванный климат с умеренными температурами круглый год"
    },
    transportation: {
        "en": ["Metro", "Bus", "Auto Rickshaw", "Taxi"],
        "ru": ["Метро", "Автобус", "Авторикша", "Такси"]
    },
    timeZone: "UTC+05:30",
    languages: {
        "en": ["Kannada", "English", "Hindi", "Tamil"],
        "ru": ["Каннада", "Английский", "Хинди", "Тамильский"]
    },
    airports: {
        "en": ["Kempegowda International Airport"],
        "ru": ["Международный аэропорт Кемпегауда"]
    },
    isCapital: false,
    coordinates: {
        latitude: 12.9716,
        longitude: 77.5946
    }
};
