import { writable } from 'svelte/store';

function createDarkModeStore() {
  // Initialize with value from localStorage, fallback to false
  const { subscribe, set, update } = writable(localStorage.getItem('darkMode') === 'true' || false);

  return {
    subscribe,
    toggle: () => update(n => {
      const newValue = !n;
      localStorage.setItem('darkMode', String(newValue));
      return newValue;
    }),
    set: (value: boolean) => {
      localStorage.setItem('darkMode', String(value));
      set(value);
    },
    init: () => {
      // Check if user has a stored preference
      const storedPreference = localStorage.getItem('darkMode');

      if (storedPreference === null) {
        // If no stored preference, use system preference
        const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        set(isDark);
        localStorage.setItem('darkMode', String(isDark));
      }

      // Still listen for system changes if user hasn't manually set preference
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handler = (e: MediaQueryListEvent) => {
        if (localStorage.getItem('darkMode') === null) {
          set(e.matches);
          localStorage.setItem('darkMode', String(e.matches));
        }
      };
      mediaQuery.addListener(handler);

      return () => mediaQuery.removeListener(handler);
    }
  };
}

export const darkMode = createDarkModeStore();
