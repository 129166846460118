<script lang="ts">
    import { onMount } from 'svelte';
    import type { City } from './data/cities';
    import { t, activeLanguage } from '../../../translations/translations';
    import { Link } from 'svelte-routing';

    export let city: City;
    export let visibilitySettings: {
        population: boolean;
        description: boolean;
        founded: boolean;
        landmarks: boolean;
        universities: boolean;
        industries: boolean;
        climate: boolean;
        transportation: boolean;
        airports: boolean;
        languages: boolean;
    };
    export let isLargeBox = true;

    // Create filtered entries based on visibility settings
    $: visibleDetails = Object.entries({
        ...(visibilitySettings.population && { [$t.cities.cityDetails.population]: city.population[$activeLanguage] || city.population.en }),
        ...(visibilitySettings.founded && { [$t.cities.cityDetails.founded]: city.founded[$activeLanguage] || city.founded.en }),
        ...(visibilitySettings.climate && { [$t.cities.cityDetails.climate]: city.climate[$activeLanguage] || city.climate.en })
    });

    $: visibleSections = [
        visibilitySettings.landmarks && { label: $t.cities.cityDetails.landmarks, items: city.landmarks[$activeLanguage] || city.landmarks.en },
        visibilitySettings.universities && { label: $t.cities.cityDetails.universities, items: city.universities[$activeLanguage] || city.universities.en },
        visibilitySettings.industries && { label: $t.cities.cityDetails.industries, items: city.industries[$activeLanguage] || city.industries.en },
        visibilitySettings.transportation && { label: $t.cities.cityDetails.transportation, items: city.transportation[$activeLanguage] || city.transportation.en },
        visibilitySettings.airports && { label: $t.cities.cityDetails.airports, items: city.airports[$activeLanguage] || city.airports.en },
        visibilitySettings.languages && { label: $t.cities.cityDetails.languages, items: city.languages[$activeLanguage] || city.languages.en }
    ].filter(Boolean);
</script>

{#if isLargeBox}
    <!-- Existing large box layout -->
    <div class="bg-box-light dark:bg-box-dark rounded-xl shadow-lg overflow-hidden">
        <!-- Image container with gradient overlay -->
        <div class="relative h-48">
            <img
                src={city.image}
                alt={city.name.en}
                class="w-full h-full object-cover"
            />
            <div class="absolute inset-0 bg-gradient-to-b from-transparent to-box-light dark:to-box-dark"></div>

            <!-- Add capital badge if applicable -->
            {#if city.isCapital}
                <div class="absolute top-3 right-3 bg-accent-light/80 dark:bg-accent-dark/80 px-2 py-1 rounded-md text-txt-button-light dark:text-txt-button-dark">
                    <span class="text-sm">{$t.cities.capital}</span>
                </div>
            {/if}
        </div>

        <!-- City information -->
        <div class="p-6">
            <div class="mb-4">
                <h2 class="text-2xl font-bold text-txt-heading-light dark:text-txt-heading-dark">
                    <Link to={`/cities/${city.name.en}`} class="hover:underline">
                        {city.name[$activeLanguage] || city.name.en}
                    </Link>
                </h2>
                <div class="text-sm text-txt-muted-light dark:text-txt-muted-dark mt-1">
                    {city.country[$activeLanguage] || city.country.en}
                </div>
            </div>

            {#if visibilitySettings.description}
                <p class="text-txt-body-light dark:text-txt-body-dark mb-4">
                    {city.description[$activeLanguage] || city.description.en}
                </p>
            {/if}

            <div class="space-y-3 text-txt-body-light dark:text-txt-body-dark">
                {#each visibleDetails as [label, value]}
                    <div class="flex justify-between">
                        <span class="text-txt-muted-light dark:text-txt-muted-dark">{label}:</span>
                        <span class="font-medium">{value}</span>
                    </div>
                {/each}

                {#each visibleSections as section}
                    <div class="pt-2">
                        <span class="text-txt-muted-light dark:text-txt-muted-dark">{section.label}:</span>
                        <div class="flex flex-wrap gap-2 mt-1">
                            {#each section.items as item}
                                <span class="px-2 py-1 bg-form-input-light dark:bg-form-input-dark rounded-md text-sm">
                                    {item}
                                </span>
                            {/each}
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>
{:else}
    <!-- New compact layout -->
    <div class="bg-box-light dark:bg-box-dark rounded-lg shadow p-3 flex items-center justify-between">
        <div class="flex items-center gap-3">
            <Link to={`/cities/${city.name.en}`} class="font-medium text-txt-heading-light dark:text-txt-heading-dark hover:underline">
                {city.name[$activeLanguage] || city.name.en}
            </Link>
            {#if city.isCapital}
            <span class="text-accent-light dark:text-accent-dark">★</span>
        {/if}
        </div>
        <span class="text-sm text-txt-muted-light dark:text-txt-muted-dark">
            {city.country[$activeLanguage] || city.country.en}
        </span>
    </div>
{/if}