export type CitiesTranslation = {
    searchPlaceholder: string;
    cities: string;
    information: string;
    coordinates: string;
    timeZone: string;
    notFound: string;
    filters: {
        from: string;
        to: string;
        title: string;
        sort: string;
        clear: string;
        population: string;
        country: string;
        allCountries: string;
        language: string;
        allLanguages: string;
        capitalOnly: string;
        sortOptions: {
            cityName: string;
            population: string;
        };
        ascending: string;
        descending: string;
        visibilityTitle: string;
    };
    cityDetails: {
        population: string;
        description: string;
        founded: string;
        landmarks: string;
        universities: string;
        industries: string;
        climate: string;
        transportation: string;
        airports: string;
        languages: string;
    };
    capital: string;
    loading: string;
};

export const citiesTranslations: Record<string, CitiesTranslation> = {
    en: {
        cities: "Cities",
        information: "Information",
        coordinates: "Coordinates",
        timeZone: "Time Zone",
        notFound: "City not found",
        searchPlaceholder: "Search cities...",
        filters: {
            from: "From",
            to: "To",
            title: "Filters",
            sort: "Sort",
            clear: "Clear",
            population: "Million",
            country: "Country",
            allCountries: "All Countries",
            language: "Language",
            allLanguages: "All Languages",
            capitalOnly: "Show only capitals",
            sortOptions: {
                cityName: "Name",
                population: "Population"
            },
            ascending: "Ascending",
            descending: "Descending",
            visibilityTitle: "Show fields"
        },
        cityDetails: {
            population: "Population",
            description: "Description",
            founded: "Founded",
            landmarks: "Landmarks",
            universities: "Universities",
            industries: "Industries",
            climate: "Climate",
            transportation: "Transportation",
            airports: "Airports",
            languages: "Languages"
        },
        capital: "Capital",
        loading: "Loading cities..."
    },
    ru: {
        cities: "Города",
        information: "Информация",
        coordinates: "Координаты",
        timeZone: "Часовой пояс",
        notFound: "Город не найден",
        searchPlaceholder: "Поиск городов...",
        filters: {
            from: "От",
            to: "До",
            title: "Фильтры",
            sort: "Сортировка",
            clear: "Очистить",
            population: "Млн",
            country: "Страна",
            allCountries: "Все страны",
            language: "Язык",
            allLanguages: "Все языки",
            capitalOnly: "Показать только столицы",
            sortOptions: {
                cityName: "Название",
                population: "Население"
            },
            ascending: "По возрастанию",
            descending: "По убыванию",
            visibilityTitle: "Показывать поля"
        },
        cityDetails: {
            population: "Население",
            description: "Описание",
            founded: "Основан",
            landmarks: "Достопримечательности",
            universities: "Университеты",
            industries: "Отрасли",
            climate: "Климат",
            transportation: "Транспорт",
            airports: "Аэропорты",
            languages: "Языки"
        },
        capital: "Столица",
        loading: "Загрузка городов..."
    }
};
