<script lang="ts">
    import { Link, navigate } from "svelte-routing";
    import GithubLogo from "../../../assets/images/github.svg";
    import { t } from "../../../translations/translations";
    import { auth } from "../../../stores/authStore";
    let email = "";
    let password = "";
    let errorMessage = "";

    async function handleSubmit() {
        try {
            await auth.login(email, password);
        } catch (error) {
            errorMessage = `${$t.login.loginFailed}: ${(error as Error).message}. ${$t.login.checkCredentials}`;
        }
    }

    function loginWithGithub() {
        // Redirect to the backend endpoint that initiates the GitHub OAuth flow
        window.location.href = import.meta.env.PROD ? 'https://auth.shindo.dev/github/login' : '/auth/github/login';
    }
</script>

<div
    id="loginContainer"
    class="
        flex-1
        container
        flex
        justify-center
        items-center
        mx-auto
        px-4
        py-12
    "
>
    <div
        id="loginWrapper"
        class="
            w-full
            max-w-[400px]
            mx-auto
        "
    >
        <h1
            id="loginTitle"
            class="
                text-4xl
                text-center
                text-txt-heading-light dark:text-txt-heading-dark
                tracking-tight
                font-bold
                mb-8
            "
        >
            {$t.login.welcome}
        </h1>

        <div
            id="loginCard"
            class="
                w-full
                p-8
                bg-box-light dark:bg-box-dark
                rounded-xl
                shadow-lg
            "
        >
            {#if errorMessage}
                <div
                    id="errorAlert"
                    class="
                        p-4
                        mb-6
                        bg-status-error/10
                        rounded-lg
                        text-sm
                        text-status-error
                        font-medium
                    "
                >
                    {errorMessage}
                </div>
            {/if}

            <form
                id="loginForm"
                on:submit|preventDefault={handleSubmit}
                class="
                    flex
                    flex-col
                    w-full
                    gap-5
                "
            >
                <div
                    id="emailGroup"
                    class="
                        flex
                        flex-col
                    "
                >
                    <label
                        for="email"
                        class="
                            mb-1.5
                            text-sm
                            text-txt-body-light dark:text-txt-body-dark
                            font-medium
                        "
                    >{$t.login.email}</label>
                    <input
                        type="email"
                        id="email"
                        bind:value={email}
                        required
                        class="
                            p-2.5
                            bg-form-input-light dark:bg-form-input-dark
                            border border-form-border-light dark:border-form-border-dark
                            rounded-lg
                            text-txt-body-light dark:text-txt-body-dark
                            transition-all
                            duration-200
                            focus:ring-2
                            focus:ring-form-border-focus
                            focus:border-form-border-focus
                        "
                    />
                </div>

                <div
                    id="passwordGroup"
                    class="
                        flex
                        flex-col
                    "
                >
                    <label
                        for="password"
                        class="
                            mb-1.5
                            text-sm
                            text-txt-body-light dark:text-txt-body-dark
                            font-medium
                        "
                    >{$t.login.password}</label>
                    <input
                        type="password"
                        id="password"
                        bind:value={password}
                        required
                        class="
                            p-2.5
                            bg-form-input-light dark:bg-form-input-dark
                            border border-form-border-light dark:border-form-border-dark
                            rounded-lg
                            text-txt-body-light dark:text-txt-body-dark
                            transition-all
                            duration-200
                            focus:ring-2
                            focus:ring-form-border-focus
                            focus:border-form-border-focus
                        "
                    />
                </div>

                <div
                    id="buttonGroup"
                    class="
                        flex
                        flex-col
                        gap-3
                        mt-2
                    "
                >
                    <button
                        type="submit"
                        class="
                            w-full
                            py-2.5
                            bg-accent-light
                            hover:bg-accent-light-hover
                            active:bg-accent-light-active
                            dark:bg-accent-dark
                            dark:hover:bg-accent-dark-hover
                            dark:active:bg-accent-dark-active
                            rounded-lg
                            text-txt-body-dark
                            font-medium
                            transition-colors
                            duration-200
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-accent-light
                        "
                    >
                        {$t.login.signIn}
                    </button>
                    <div
                        id="createAccountDisabled"
                        class="
                            w-full
                            py-2.5
                            bg-form-border-light dark:bg-form-border-dark
                            rounded-lg
                            text-center
                            text-txt-muted-light dark:text-txt-muted-dark
                            font-medium
                            cursor-not-allowed
                        "
                    >
                        {$t.login.createAccount}
                    </div>
                </div>
            </form>

            <div
                id="divider"
                class="
                    flex
                    items-center
                    my-6
                "
            >
                <div class="
                    flex-1
                    border-t
                    border-form-border-light dark:border-form-border-dark
                "></div>
                <span
                    class="
                        px-4
                        text-sm
                        text-txt-muted-light dark:text-txt-muted-dark
                        font-medium
                    "
                >{$t.login.continueWith}</span>
                <div class="
                    flex-1
                    border-t
                    border-form-border-light dark:border-form-border-dark
                "></div>
            </div>

            <div
                id="socialLogin"
                class="
                    flex
                    gap-3
                "
            >
                <div
                    class="
                        flex-1
                        flex
                        items-center
                        justify-center
                        gap-2
                        py-2.5
                        px-4
                        bg-form-border-light dark:bg-form-border-dark
                        rounded-lg
                        text-txt-muted-light dark:text-txt-muted-dark
                        font-medium
                        cursor-not-allowed
                    "
                >
                    <img src={GithubLogo} alt="GitHub Logo" class="
                        w-5
                        h-5
                        opacity-50
                    " />
                    <span>GitHub</span>
                </div>
            </div>
        </div>
    </div>
</div>
