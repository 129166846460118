import type { City } from '../../cities';

export const sydney: City = {
    name: {
        "en": "Sydney",
        "ru": "Сидней"
    },
    country: {
        "en": "Australia",
        "ru": "Австралия"
    },
    image: "https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9",
    populationMillion: 5.3,
    population: {
        "en": "5.3 million",
        "ru": "5,3 млн"
    },
    description: {
        "en": "Sydney is Australia's largest city and economic hub. Known for its iconic harbour, beaches, and vibrant cultural scene.",
        "ru": "Сидней - крупнейший город и экономический центр Австралии. Известен своей знаменитой гаванью, пляжами и яркой культурной жизнью."
    },
    founded: {
        "en": "1788",
        "ru": "1788"
    },
    landmarks: {
        "en": ["Sydney Opera House", "Sydney Harbour Bridge", "Bondi Beach", "Royal Botanic Garden"],
        "ru": ["Сиднейский оперный театр", "Мост Харбор-Бридж", "Пляж Бонди", "Королевский ботанический сад"]
    },
    universities: {
        "en": ["University of Sydney", "University of New South Wales", "Macquarie University"],
        "ru": ["Сиднейский университет", "Университет Нового Южного Уэльса", "Университет Маккуори"]
    },
    industries: {
        "en": ["Finance", "Tourism", "Technology", "Media", "Education"],
        "ru": ["Финансы", "Туризм", "Технологии", "Медиа", "Образование"]
    },
    climate: {
        "en": "Temperate climate with warm summers and mild winters",
        "ru": "Умеренный климат с теплым летом и мягкой зимой"
    },
    transportation: {
        "en": ["Train", "Bus", "Ferry", "Light Rail"],
        "ru": ["Поезд", "Автобус", "Паром", "Легкорельсовый транспорт"]
    },
    timeZone: "UTC+10:00",
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    airports: {
        "en": ["Sydney Airport", "Western Sydney Airport"],
        "ru": ["Аэропорт Сиднея", "Аэропорт Западного Сиднея"]
    },
    isCapital: false,
    coordinates: {
        latitude: -33.8688,
        longitude: 151.2093
    }
};
