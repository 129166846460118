import { type LanguageCode } from "./translations";

// Define the type for login translations
export type LoginTranslation = {
    welcome: string;
    email: string;
    password: string;
    signIn: string;
    createAccount: string;
    continueWith: string;
    loginFailed: string;
    checkCredentials: string;
};

// Export login translations for each language
export const loginTranslations: Record<LanguageCode, LoginTranslation> = {
    en: {
        welcome: "Welcome",
        email: "Email",
        password: "Password",
        signIn: "Sign in",
        createAccount: "Create account",
        continueWith: "or continue with",
        loginFailed: "Login failed",
        checkCredentials: "Please check your credentials and try again."
    },
    ru: {
        welcome: "Добро пожаловать",
        email: "Электронная почта",
        password: "Пароль",
        signIn: "Войти",
        createAccount: "Создать аккаунт",
        continueWith: "или продолжить с помощью",
        loginFailed: "Ошибка входа",
        checkCredentials: "Пожалуйста, проверьте ваши учетные данные и попробуйте снова."
    },
};