<script lang="ts">
    import { onMount } from 'svelte';
    import { Link, navigate } from 'svelte-routing';
    import GithubLogo from '../../../assets/images/github.svg';
    import { t } from "../../../translations/translations";

    let email = '';
    let password = '';
    let confirmPassword = '';
    let errorMessage = '';

    async function handleSubmit() {
        try {
            // TODO: Implement registration logic
            console.log('Registration submitted', { email, password, confirmPassword });
            navigate('/login');
        } catch (error) {
            console.error('Registration error:', error);
            errorMessage = `${$t.register.registrationFailed}. ${$t.register.tryAgain}`;
        }
    }

    function registerWithGithub() {
        // Implement GitHub registration logic
        console.log('Register with GitHub');
    }

    onMount(() => {
        // Any initialization logic can go here
    });
</script>

<div
    id="register-page-container"
    class="flex-1 container flex justify-center items-center mx-auto px-4 py-12"
>
    <div
        id="register-content-wrapper"
        class="w-full max-w-[400px] mx-auto"
    >
        <h1
            id="register-heading"
            class="text-4xl text-center tracking-tight font-bold mb-8 text-txt-heading-light dark:text-txt-heading-dark"
        >
            {$t.register.createAccount}
        </h1>

        <div
            id="register-form-container"
            class="w-full rounded-xl p-8 bg-box-light dark:bg-box-dark shadow-lg"
        >
            {#if errorMessage}
                <div
                    id="register-error-message"
                    class="p-4 mb-6 bg-status-error/10 rounded-lg text-sm text-status-error font-medium"
                >
                    {errorMessage}
                </div>
            {/if}

            <form
                id="register-form"
                on:submit|preventDefault={handleSubmit}
                class="w-full flex flex-col gap-5"
            >
                <div
                    id="register-email-group"
                    class="flex flex-col"
                >
                    <label
                        for="email"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {$t.register.email}
                    </label>
                    <input
                        type="email"
                        id="email"
                        bind:value={email}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div
                    id="register-password-group"
                    class="flex flex-col"
                >
                    <label
                        for="password"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {$t.register.password}
                    </label>
                    <input
                        type="password"
                        id="password"
                        bind:value={password}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div
                    id="register-confirm-password-group"
                    class="flex flex-col"
                >
                    <label
                        for="confirmPassword"
                        class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                    >
                        {$t.register.confirmPassword}
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        bind:value={confirmPassword}
                        required
                        class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    />
                </div>

                <div
                    id="register-buttons-group"
                    class="flex flex-col gap-3 mt-2"
                >
                    <button
                        type="submit"
                        class="w-full py-2.5 bg-accent-light hover:bg-accent-light-hover active:bg-accent-light-active dark:bg-accent-dark dark:hover:bg-accent-dark-hover dark:active:bg-accent-dark-active rounded-lg text-txt-body-dark font-medium transition-colors duration-200 focus:ring-2 focus:ring-offset-2 focus:ring-accent-light"
                    >
                        {$t.register.register}
                    </button>
                    <Link
                        to="/login"
                        class="w-full"
                    >
                        <div
                            class="w-full py-2.5 rounded-lg font-medium text-center bg-form-border-light dark:bg-form-border-dark text-txt-muted-light dark:text-txt-muted-dark transition-colors duration-200"
                        >
                            {$t.register.signInInstead}
                        </div>
                    </Link>
                </div>
            </form>

            <div
                id="register-divider"
                class="flex items-center my-6"
            >
                <div
                    class="flex-1 border-t border-form-border-light dark:border-form-border-dark"
                ></div>
                <span
                    class="px-4 text-sm text-txt-muted-light dark:text-txt-muted-dark font-medium"
                >
                    {$t.register.continueWith}
                </span>
                <div
                    class="flex-1 border-t border-form-border-light dark:border-form-border-dark"
                ></div>
            </div>

            <div
                id="register-social-buttons"
                class="flex gap-3"
            >
                <button
                    on:click={registerWithGithub}
                    class="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 bg-form-border-light dark:bg-form-border-dark rounded-lg text-txt-muted-light dark:text-txt-muted-dark"
                >
                    <img src={GithubLogo} alt="GitHub Logo" class="w-5 h-5" />
                    <span>GitHub</span>
                </button>
            </div>
        </div>
    </div>
</div>
