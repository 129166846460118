import { type LanguageCode } from "./translations";

// Define the type for menu translations
export type MenuTranslation = {
    dialog: string;
    status: string;
    login: string;
    logout: string;
    countries: string;
    cities: string;
};

// Export menu translations for each language
export const menuTranslations: Record<LanguageCode, MenuTranslation> = {
    en: {
        dialog: "💬",
        status: "🔧",
        login: "Login",
        logout: "Logout",
        countries: "Countries",
        cities: "Cities"
    },
    ru: {
        dialog: "💬",
        status: "🔧",
        login: "Войти",
        logout: "Выйти",
        countries: "Страны",
        cities: "Города"
    },
};