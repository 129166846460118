<script lang="ts">
  import { onMount } from 'svelte';
  import { darkMode } from '../../stores/darkMode';

  onMount(() => {
    const unsubscribe = darkMode.init();
    return unsubscribe;
  });
</script>

<main
  class:dark={$darkMode}
  class="flex-1 flex flex-col h-full justify-center items-center bg-bg-light text-txt-body-light dark:bg-bg-dark dark:text-txt-body-dark"
>
  <slot></slot>
</main>
