import type { City } from '../../cities';

export const london: City = {
    name: {
        "en": "London",
        "ru": "Лондон"
    },
    country: {
        "en": "United Kingdom",
        "ru": "Великобритания"
    },
    image: "https://images.unsplash.com/photo-1513635269975-59663e0ac1ad",
    populationMillion: 9.0,
    population: {
        "en": "9.0 million",
        "ru": "9,0 млн"
    },
    description: {
        "en": "London is the capital of England and the United Kingdom, a global financial center with rich history and culture.",
        "ru": "Лондон - столица Англии и Великобритании, мировой финансовый центр с богатой историей и культурой."
    },
    founded: {
        "en": "47 AD",
        "ru": "47 год н.э."
    },
    landmarks: {
        "en": ["Big Ben", "Tower Bridge", "Buckingham Palace", "London Eye"],
        "ru": ["Биг-Бен", "Тауэрский мост", "Букингемский дворец", "Лондонский глаз"]
    },
    universities: {
        "en": ["Imperial College London", "University College London", "London School of Economics"],
        "ru": ["Имперский колледж Лондона", "Университетский колледж Лондона", "Лондонская школа экономики"]
    },
    industries: {
        "en": ["Finance", "Technology", "Media", "Tourism", "Professional Services"],
        "ru": ["Финансы", "Технологии", "Медиа", "Туризм", "Профессиональные услуги"]
    },
    climate: {
        "en": "Temperate maritime climate with mild winters and cool summers",
        "ru": "Умеренный морской климат с мягкими зимами и прохладным летом"
    },
    transportation: {
        "en": ["Underground", "Bus", "DLR", "Overground"],
        "ru": ["Метро", "Автобус", "DLR", "Надземка"]
    },
    timeZone: "UTC+00:00",
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    airports: {
        "en": ["Heathrow Airport", "Gatwick Airport", "London City Airport"],
        "ru": ["Аэропорт Хитроу", "Аэропорт Гатвик", "Лондонский городской аэропорт"]
    },
    isCapital: true,
    coordinates: {
        latitude: 51.5074,
        longitude: -0.1278
    }
};