import type { Country } from '../countries';

export const unitedStates: Country = {
  name: {
    "en": "United States",
    "ru": "Соединённые Штаты"
  },
  image: "https://images.unsplash.com/photo-1501594907352-04cda38ebc29",
  gdpBillion: 23320,
  gdp: {
    "en": "23,320 billion USD",
    "ru": "23,320 млрд долл. США"
  },
  currency: {
    "en": "US Dollar ($)",
    "ru": "Доллар США ($)"
  },
  topCities: {
    "en": ["New York", "Los Angeles", "Chicago"],
    "ru": ["Нью-Йорк", "Лос-Анджелес", "Чикаго"]
  },
  populationMillion: 331.9,
  population: {
    "en": "331.9 million",
    "ru": "331,9 млн"
  },
  capital: {
    "en": "Washington, D.C.",
    "ru": "Вашингтон"
  },
  languages: {
    "en": ["English"],
    "ru": ["Английский"]
  },
  continent: {
    "en": "North America",
    "ru": "Северная Америка"
  },
  timeZones: ["UTC-04:00", "UTC-10:00"],
  drivingSide: "right",
  countryCode: {
    alpha2: "US",
    alpha3: "USA"
  },
  government: {
    "en": "Federal presidential constitutional republic",
    "ru": "Федеративная президентская республика"
  },
  areaTotal: 9833517,
  areaUnit: {
    "en": "km²",
    "ru": "км²"
  },
  climate: {
    "en": ["Continental", "Subtropical", "Arctic", "Tropical"],
    "ru": ["Континентальный", "Субтропический", "Арктический", "Тропический"]
  },
  landmarks: {
    "en": ["Statue of Liberty", "Grand Canyon", "Golden Gate Bridge"],
    "ru": ["Статуя Свободы", "Гранд-Каньон", "Мост Золотые Ворота"]
  },
  internetTLD: ".us",
  phoneCode: "+1",
  majorCompanies: {
    "en": ["Apple", "Microsoft", "Amazon", "Google", "Meta"],
    "ru": ["Apple", "Microsoft", "Amazon", "Google", "Meta"]
  },
  majorIndustries: {
    "en": ["Technology", "Finance", "Healthcare", "Entertainment", "Manufacturing"],
    "ru": ["Технологии", "Финансы", "Здравоохранение", "Развлечения", "Производство"]
  },
  geography: {
    "en": "The United States is a country in North America with the Atlantic Ocean, the Pacific Ocean, and the Rocky Mountains.",
    "ru": "Соединённые Штаты - страна в Северной Америке с Атлантическим океаном, Тихим океаном и Скалистыми горами."
  },
  history: {
    "en": "The United States' history spans over 200 years, with notable events such as the American Revolution and the Great Depression.",
    "ru": "История Соединённых Штатов насчитывает более 200 лет, с заметными событиями, такими как Американо-война и Великая Депрессия."
  },
  culture: {
    "en": "American culture is rich and diverse, with influences from Native American, African American, and European American cultures, known for its unique art forms and cuisine.",
    "ru": "Американская культура богата и разнообразна, с влиянием коренных американцев, афроамериканцев и европейцев, известна своей уникальной художественной сцене и кухней."
  },
  travel: {
    "en": "The United States offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
    "ru": "Соединённые Штаты предлагают широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
  }
};