import { type LanguageCode } from "./translations";

export type TermsTranslation = {
    title: string;
    introduction: string;
    usage: string;
    limitations: string;
    contact: string;
};

export const termsTranslations: Record<LanguageCode, TermsTranslation> = {
    en: {
        title: "Terms of Service",
        introduction: "Welcome to our service. By using our application, you agree to these terms.",
        usage: "You may use our service for personal and non-commercial purposes. You are responsible for maintaining the confidentiality of your account.",
        limitations: "We reserve the right to modify or terminate the service for any reason, without notice at any time.",
        contact: "If you have any questions about these Terms of Service, please contact us."
    },
    ru: {
        title: "Условия использования",
        introduction: "Добро пожаловать в наш сервис. Используя наше приложение, вы соглашаетесь с этими условиями.",
        usage: "Вы можете использовать наш сервис в личных и некоммерческих целях. Вы несете ответственность за сохранение конфиденциальности вашей учетной записи.",
        limitations: "Мы оставляем за собой право изменять или прекращать работу сервиса по любой причине в любое время без уведомления.",
        contact: "Если у вас есть вопросы об этих Условиях использования, пожалуйста, свяжитесь с нами."
    },
};
