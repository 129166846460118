<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { onMount } from "svelte";
    import { t, activeLanguage } from "../../../translations/translations";

    export let uniqueCountries: string[] = [];
    export let uniqueLanguages: string[] = [];
    export let initialVisibilitySettings: Record<string, boolean>;
    export let isLargeBox: boolean;

    const dispatch = createEventDispatcher();

    // Filter state
    let popMMin = "";
    let popMMax = "";
    let selectedCountry = "all";
    let selectedLanguage = "all";
    let isCapitalOnly = false;
    let sortBy = "name";
    let sortOrder = "asc";

    // Add new state for filter visibility
    let showFilters = false;

    // Add new visibility state
    let visibilitySettings = { ...initialVisibilitySettings };

    function updateFilters() {
        dispatch("filterChange", {
            popMMin,
            popMMax,
            selectedCountry,
            selectedLanguage,
            isCapitalOnly,
            sortBy,
            sortOrder,
            visibilitySettings,
        });
    }

    function clearFilters() {
        popMMin = "";
        popMMax = "";
        selectedCountry = "all";
        selectedLanguage = "all";
        isCapitalOnly = false;
        sortBy = "name";
        sortOrder = "asc";
        visibilitySettings = { ...initialVisibilitySettings };
        updateFilters();
    }

    // Update filters whenever any value changes
    $: {
        popMMin,
            popMMax,
            selectedCountry,
            selectedLanguage,
            isCapitalOnly,
            sortBy,
            sortOrder,
            visibilitySettings;
        updateFilters();
    }

    // Trigger initial filter on mount
    onMount(() => {
        updateFilters();
    });
</script>

<div class="bg-box-light dark:bg-box-dark p-6 rounded-xl mb-4">
    <!-- Top controls -->
    <div class="flex flex-wrap items-center gap-4">
        <div class="flex items-center gap-2 text-sm flex-wrap">
            <button
                on:click={() => (showFilters = !showFilters)}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent flex items-center gap-1"
            >
                <span class="font-medium">🏷️</span>
            </button>
            <select
                bind:value={sortBy}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent appearance-none max-w-[160px]"
            >
                <option value="name"
                    >{$t.cities.filters.sortOptions.cityName}</option
                >
                <option value="population"
                    >{$t.cities.filters.sortOptions.population}</option
                >
            </select>

            <button
                on:click={() =>
                    (sortOrder = sortOrder === "asc" ? "desc" : "asc")}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent w-8 text-center"
                title={sortOrder === "asc"
                    ? $t.cities.filters.ascending
                    : $t.cities.filters.descending}
            >
                {sortOrder === "asc" ? "↑" : "↓"}
            </button>

            <button
                on:click={() => dispatch("sizeChange", !isLargeBox)}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent w-8 text-center"
                title={isLargeBox
                    ? $t.cities.filters.compact
                    : $t.cities.filters.expanded}
            >
                {isLargeBox ? "A" : "a"}
            </button>
            <button
                on:click={clearFilters}
                class="h-[34px] py-1 px-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-input-light dark:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark focus:ring-1 focus:ring-accent-light focus:border-transparent flex items-center gap-1"
            >
                <span class="font-medium"> 🧹</span>
            </button>
        </div>
    </div>

    {#if showFilters}
        <div class="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <!-- Population Range -->
            <div class="space-y-2">
                <!-- svelte-ignore a11y-label-has-associated-control -->
                <label
                    class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                >
                    {$t.cities.filters.population}
                </label>
                <div class="flex gap-2">
                    <input
                        type="number"
                        bind:value={popMMin}
                        placeholder={$t.cities.filters.from}
                        class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent placeholder-form-placeholder-light dark:placeholder-form-placeholder-dark"
                    />
                    <input
                        type="number"
                        bind:value={popMMax}
                        placeholder={$t.cities.filters.to}
                        class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent placeholder-form-placeholder-light dark:placeholder-form-placeholder-dark"
                    />
                </div>
            </div>

            <!-- Country Filter -->
            <div class="space-y-2">
                <!-- svelte-ignore a11y-label-has-associated-control -->
                <label
                    class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                >
                    {$t.cities.filters.country}
                </label>
                <select
                    bind:value={selectedCountry}
                    class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent"
                >
                    <option value="all">{$t.cities.filters.allCountries}</option
                    >
                    {#each uniqueCountries as country}
                        <option value={country}>{country}</option>
                    {/each}
                </select>
            </div>

            <!-- Language Filter -->
            <div class="space-y-2">
                <!-- svelte-ignore a11y-label-has-associated-control -->
                <label
                    class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                >
                    {$t.cities.filters.language}
                </label>
                <select
                    bind:value={selectedLanguage}
                    class="w-full p-2 rounded-md border border-form-border-light dark:border-form-border-dark bg-form-light dark:bg-form-dark text-txt-body-light dark:text-txt-body-dark focus:ring-2 focus:ring-accent-light focus:border-transparent"
                >
                    <option value="all">{$t.cities.filters.allLanguages}</option
                    >
                    {#each uniqueLanguages as language}
                        <option value={language}>{language}</option>
                    {/each}
                </select>
            </div>

            <!-- Capital Cities Only -->
            <div class="space-y-2">
                <label class="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        bind:checked={isCapitalOnly}
                        class="rounded border-form-border-light dark:border-form-border-dark text-accent-light focus:ring-accent-light"
                    />
                    <span
                        class="text-sm text-txt-body-light dark:text-txt-body-dark"
                    >
                        {$t.cities.filters.capitalOnly}
                    </span>
                </label>
            </div>
        </div>

        <!-- Visibility Settings -->
        <div class="space-y-2 col-span-full mt-6">
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label
                class="block text-sm font-medium text-txt-body-light dark:text-txt-body-dark"
                id="visibility-group"
            >
                {$t.cities.filters.visibilityTitle}
            </label>
            <div
                class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"
                role="group"
                aria-labelledby="visibility-group"
            >
                {#each Object.entries(visibilitySettings) as [key, value]}
                    <label class="flex items-center space-x-2">
                        <input
                            type="checkbox"
                            bind:checked={visibilitySettings[key]}
                            class="rounded border-form-border-light dark:border-form-border-dark text-accent-light focus:ring-accent-light"
                        />
                        <span
                            class="text-sm text-txt-body-light dark:text-txt-body-dark"
                        >
                            {$t.cities.cityDetails[key]}
                        </span>
                    </label>
                {/each}
            </div>
        </div>
    {/if}
</div>
