import type { City } from '../../cities';

export const beijing: City = {
    name: {
        "en": "Beijing",
        "ru": "Пекин"
    },
    country: {
        "en": "China",
        "ru": "Китай"
    },
    image: "https://images.unsplash.com/photo-1508804185872-d7badad00f7d",
    populationMillion: 20.9,
    population: {
        "en": "20.9 million",
        "ru": "20,9 млн"
    },
    description: {
        "en": "Beijing is China's capital and cultural center, blending ancient imperial heritage with modern development and innovation.",
        "ru": "Пекин - столица и культурный центр Китая, сочетающий древнее имперское наследие с современным развитием и инновациями."
    },
    founded: {
        "en": "1045 BC",
        "ru": "1045 до н.э."
    },
    landmarks: {
        "en": ["Great Wall of China", "Forbidden City", "Temple of Heaven", "Summer Palace"],
        "ru": ["Великая Китайская стена", "Запретный город", "Храм Неба", "Летний дворец"]
    },
    universities: {
        "en": ["Peking University", "Tsinghua University", "Beijing Normal University"],
        "ru": ["Пекинский университет", "Университет Цинхуа", "Пекинский педагогический университет"]
    },
    industries: {
        "en": ["Technology", "Finance", "Manufacturing", "Tourism"],
        "ru": ["Технологии", "Финансы", "Производство", "Туризм"]
    },
    climate: {
        "en": "Humid continental climate with hot summers and cold winters",
        "ru": "Влажный континентальный климат с жарким летом и холодной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "High-speed Rail", "Bicycle"],
        "ru": ["Метро", "Автобус", "Скоростная железная дорога", "Велосипед"]
    },
    timeZone: "UTC+08:00",
    languages: {
        "en": ["Mandarin Chinese", "English"],
        "ru": ["Китайский (мандарин)", "Английский"]
    },
    airports: {
        "en": ["Beijing Capital International Airport", "Beijing Daxing International Airport"],
        "ru": ["Международный аэропорт Пекин Столичный", "Международный аэропорт Пекин Дасин"]
    },
    isCapital: true,
    coordinates: {
        latitude: 39.9042,
        longitude: 116.4074
    }
};
