<script lang="ts">
    import { onMount } from 'svelte';
    import { Link } from 'svelte-routing';
    import type { Country } from './data/countries';
    import { t, activeLanguage } from '../../../translations/translations'; // Import the translation store

    export let country: Country;
    export let visibilitySettings: {
        gdp: boolean;
        population: boolean;
        currency: boolean;
        continent: boolean;
        area: boolean;
        phone: boolean;
        domain: boolean;
        languages: boolean;
        majorCities: boolean;
        majorCompanies: boolean;
        majorIndustries: boolean;
        timeZones: boolean;
    };
    export let isLargeBox = true;

    // Create filtered entries based on visibility settings
    $: visibleDetails = Object.entries({
        ...(visibilitySettings.gdp && {
            [$t.countries.countryDetails.gdp]: `${country.gdpBillion} ${$t.countries.countryDetails.unit.billion}`
        }),
        ...(visibilitySettings.population && {
            [$t.countries.countryDetails.population]: `${country.populationMillion} ${$t.countries.countryDetails.unit.million}`
        }),
        ...(visibilitySettings.currency && { [$t.countries.countryDetails.currency]: country.currency[$activeLanguage] || country.currency.en }),
        ...(visibilitySettings.continent && { [$t.countries.countryDetails.continent]: country.continent[$activeLanguage] || country.continent.en }),
        ...(visibilitySettings.area && { [$t.countries.countryDetails.area]: `${country.areaTotal.toLocaleString()} ${country.areaUnit[$activeLanguage] || country.areaUnit.en}` }),
        ...(visibilitySettings.phone && { [$t.countries.countryDetails.phone]: country.phoneCode }),
        ...(visibilitySettings.domain && { [$t.countries.countryDetails.domain]: country.internetTLD })
    });

    $: visibleSections = [
        visibilitySettings.languages && { label: $t.countries.countryDetails.languages, items: country.languages[$activeLanguage] || country.languages.en },
        visibilitySettings.majorCities && { label: $t.countries.countryDetails.majorCities, items: country.topCities[$activeLanguage] || country.topCities.en },
        visibilitySettings.majorCompanies && { label: $t.countries.countryDetails.majorCompanies, items: country.majorCompanies[$activeLanguage] || country.majorCompanies.en },
        visibilitySettings.majorIndustries && { label: $t.countries.countryDetails.majorIndustries, items: country.majorIndustries[$activeLanguage] || country.majorIndustries.en },
        visibilitySettings.timeZones && { label: $t.countries.countryDetails.timeZones, items: country.timeZones }
    ].filter(Boolean);

    // Helper function to convert country code to flag emoji
    function getCountryFlag(countryCode: string): string {
        const codePoints = countryCode
            .toUpperCase()
            .split('')
            .map(char => 127397 + char.charCodeAt(0));
        return String.fromCodePoint(...codePoints);
    }
</script>

{#if isLargeBox}
    <!-- Existing large box layout -->
    <div class="bg-box-light dark:bg-box-dark rounded-xl shadow-lg overflow-hidden">
        <!-- Image container with gradient overlay -->
        <div class="relative h-48">
            <img
                src={country.image}
                alt={country.name.en}
                class="w-full h-full object-cover"
            />
            <div class="absolute inset-0 bg-gradient-to-b from-transparent to-box-light dark:to-box-dark"></div>

            <!-- Add country code badge -->
            <div class="absolute top-3 right-3 bg-box-light/80 dark:bg-box-dark/80 px-2 py-1 rounded-md">
                <span class="font-mono text-sm">{country.countryCode.alpha3}</span>
            </div>
        </div>

        <!-- Country information -->
        <div class="p-6">
            <div class="flex justify-between items-start mb-4">
                <div>
                    <Link to={`/countries/${country.name.en.toLowerCase()}`} class="hover:underline">
                        <h2 class="text-2xl font-bold text-txt-heading-light dark:text-txt-heading-dark">
                            {country.name[$activeLanguage] || country.name.en}
                        </h2>
                    </Link>
                    <div class="text-sm text-txt-muted-light dark:text-txt-muted-dark mt-1">
                        {$t.countries.countryDetails.capital}: {country.capital[$activeLanguage] || country.capital.en} <!-- Use translation for "Capital" -->
                    </div>
                </div>
                <div class="text-4xl">{getCountryFlag(country.countryCode.alpha2)}</div>
            </div>

            <div class="space-y-3 text-txt-body-light dark:text-txt-body-dark">
                {#each visibleDetails as [label, value]}
                    <div class="flex justify-between">
                        <span class="text-txt-muted-light dark:text-txt-muted-dark">{label}:</span>
                        <span class="font-medium">{value}</span>
                    </div>
                {/each}

                {#each visibleSections as section}
                    <div class="pt-2">
                        <span class="text-txt-muted-light dark:text-txt-muted-dark">{section.label}:</span>
                        <div class="flex flex-wrap gap-2 mt-1">
                            {#each section.items as item}
                                {#if section.label === $t.countries.countryDetails.majorCities}
                                    <Link to={`/cities/${item.toLowerCase()}`} class="hover:underline">
                                        <span class="px-2 py-1 bg-form-input-light dark:bg-form-input-dark rounded-md text-sm">
                                            {item}
                                        </span>
                                    </Link>
                                {:else}
                                    <span class="px-2 py-1 bg-form-input-light dark:bg-form-input-dark rounded-md text-sm">
                                        {item}
                                    </span>
                                {/if}
                            {/each}
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>
{:else}
    <!-- New compact layout -->
    <div class="bg-box-light dark:bg-box-dark rounded-lg shadow p-3 flex items-center justify-between">
        <div class="flex items-center gap-3">
            <span class="text-2xl">{getCountryFlag(country.countryCode.alpha2)}</span>
            <Link to={`/countries/${country.name.en.toLowerCase()}`} class="hover:underline">
                <span class="font-medium text-txt-heading-light dark:text-txt-heading-dark">
                    {country.name[$activeLanguage] || country.name.en}
                </span>
            </Link>
        </div>
        <span class="text-sm text-txt-muted-light dark:text-txt-muted-dark">
            {country.countryCode.alpha3}
        </span>
    </div>
{/if}