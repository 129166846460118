import type { City } from '../../cities';

export const mumbai: City = {
    name: {
        "en": "Mumbai",
        "ru": "Мумбаи"
    },
    country: {
        "en": "India",
        "ru": "Индия"
    },
    image: "https://images.unsplash.com/photo-1529253355930-ddbe423a2ac7",
    populationMillion: 20.4,
    population: {
        "en": "20.4 million",
        "ru": "20,4 млн"
    },
    description: {
        "en": "Mumbai is India's financial capital and largest city, known for its entertainment industry, colonial architecture, and vibrant culture.",
        "ru": "Мумбаи - финансовая столица и крупнейший город Индии, известный своей индустрией развлечений, колониальной архитектурой и яркой культурой."
    },
    founded: {
        "en": "1661",
        "ru": "1661"
    },
    landmarks: {
        "en": ["Gateway of India", "Taj Mahal Palace", "Marine Drive", "Elephanta Caves"],
        "ru": ["Ворота Индии", "Дворец Тадж-Махал", "Марин-Драйв", "Пещеры Элефанты"]
    },
    universities: {
        "en": ["University of Mumbai", "Indian Institute of Technology Bombay", "TATA Institute of Social Sciences"],
        "ru": ["Университет Мумбаи", "Индийский технологический институт Бомбея", "Институт социальных наук ТАТА"]
    },
    industries: {
        "en": ["Finance", "Entertainment", "Textiles", "Information Technology"],
        "ru": ["Финансы", "Развлечения", "Текстиль", "Информационные технологии"]
    },
    climate: {
        "en": "Tropical monsoon climate with warm temperatures year-round",
        "ru": "Тропический муссонный климат с теплой температурой круглый год"
    },
    transportation: {
        "en": ["Local Trains", "Metro", "Bus", "Auto Rickshaw"],
        "ru": ["Пригородные поезда", "Метро", "Автобус", "Авторикша"]
    },
    timeZone: "UTC+05:30",
    languages: {
        "en": ["Marathi", "Hindi", "English", "Gujarati"],
        "ru": ["Маратхи", "Хинди", "Английский", "Гуджарати"]
    },
    airports: {
        "en": ["Chhatrapati Shivaji Maharaj International Airport"],
        "ru": ["Международный аэропорт имени Чатрапати Шиваджи"]
    },
    isCapital: false,
    coordinates: {
        latitude: 19.0760,
        longitude: 72.8777
    }
};
