<script lang="ts">
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { auth } from "../../stores/authStore";

  export let component: any;
  export let path: string;

  let isChecking = false;

  onMount(async () => {
    if (isChecking) return;
    isChecking = true;

    try {
      const authStatus = await auth.checkAuth();
      if (!authStatus) {
        auth.clearUser();
        navigate("/login");
      }
    } catch (error) {
      console.error("Auth check failed:", error);
      auth.clearUser();
      navigate("/login");
    } finally {
      isChecking = false;
    }
  });
</script>

<svelte:component this={component} />
