<script lang="ts">
  import { onMount } from "svelte";
  import SEO from "../../../lib/components/SEO.svelte";
  console.log("Script initialized");

  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;
  const colors = ["#333333", "#666666", "#999999"]; // Dark gray, medium gray, light gray
  const shapes = ["triangle", "circle", "square", "x"];
  const streams: Stream[] = [];
  let container: HTMLDivElement;

  class Shape {
    x: number;
    y: number;
    size: number;
    speed: number;
    color: string;
    shape: string;
    opacity: number;

    constructor(x: number, y: number) {
      this.x = x;
      this.y = y;
      this.size = Math.random() * 5 + 2; // Changed from (Math.random() * 3 + 1) to make shapes bigger
      this.speed = Math.random() * 1.5 + 0.2; // Slightly slower speed
      this.color = colors[Math.floor(Math.random() * colors.length)];
      this.shape = shapes[Math.floor(Math.random() * shapes.length)];
      this.opacity = 1;
    }

    draw() {
      ctx.strokeStyle = this.color;
      ctx.fillStyle = "transparent";
      ctx.globalAlpha = this.opacity;
      ctx.lineWidth = 0.5;

      // Round coordinates to prevent strobing
      const x = Math.round(this.x);
      const y = Math.round(this.y);
      const size = Math.round(this.size);

      switch (this.shape) {
        case "triangle":
          ctx.beginPath();
          ctx.moveTo(x, y - size);
          ctx.lineTo(x - size, y + size);
          ctx.lineTo(x + size, y + size);
          ctx.closePath();
          ctx.stroke();
          break;
        case "circle":
          ctx.beginPath();
          ctx.arc(x, y, size, 0, Math.PI * 2);
          ctx.stroke();
          break;
        case "square":
          ctx.strokeRect(x - size, y - size, size * 2, size * 2);
          break;
        case "x":
          ctx.beginPath();
          ctx.moveTo(x - size, y - size);
          ctx.lineTo(x + size, y + size);
          ctx.moveTo(x + size, y - size);
          ctx.lineTo(x - size, y + size);
          ctx.stroke();
          break;
      }
    }

    update() {
      this.y += this.speed;
      this.opacity -= 0.002; // Slower fade out for longer trails
      return this.opacity > 0;
    }
  }

  class Stream {
    x: number;
    shapes: Shape[];
    timer: number;
    interval: number;

    constructor(x: number) {
      this.x = x;
      this.shapes = [];
      this.timer = 0;
      this.interval = Math.random() * 40 + 15;

      // Increase initial shapes density
      const numberOfInitialShapes = Math.ceil(canvas.height / 60);
      for (let i = 0; i < numberOfInitialShapes; i++) {
        this.shapes.push(new Shape(this.x, Math.random() * canvas.height));
      }
    }

    update() {
      this.timer++;
      if (this.timer >= this.interval) {
        this.shapes.push(new Shape(this.x, 0));
        this.timer = 0;
      }

      this.shapes = this.shapes.filter((shape) => shape.update());
      this.shapes.forEach((shape) => shape.draw());
    }
  }

  function animate() {
    if (!ctx || !canvas || !container) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    streams.forEach((stream) => stream.update());
    const animationId = requestAnimationFrame(animate);
    return animationId;
  }

  onMount(() => {
    console.log("onMount triggered");

    // Add a small delay to ensure container is properly rendered
    setTimeout(() => {
      if (!canvas || !container) {
        console.error("Canvas or container not initialized");
        return;
      }

      ctx = canvas.getContext("2d")!;
      if (!ctx) {
        console.error("Could not get canvas context");
        return;
      }

      const updateCanvasSize = () => {
        if (!canvas || !container) return;

        // Force a reflow to ensure accurate dimensions
        container.offsetHeight;
        const rect = container.getBoundingClientRect();

        // Add validation for dimensions
        if (rect.width <= 0 || rect.height <= 0) {
          console.warn("Invalid container dimensions, retrying...");
          requestAnimationFrame(updateCanvasSize);
          return;
        }

        canvas.width = rect.width;
        canvas.height = rect.height;

        console.log("Canvas dimensions:", canvas.width, canvas.height);

        // Only initialize streams if we have valid dimensions
        if (canvas.width > 0 && canvas.height > 0) {
          streams.length = 0;
          const streamCount = Math.floor(canvas.width / 5);
          for (let i = 0; i < streamCount; i++) {
            streams.push(new Stream(5 + i * 5));
          }
        }
      };

      updateCanvasSize();
      const animationId = animate();

      // Debounce the resize handler
      let resizeTimeout: number;
      const handleResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(updateCanvasSize, 100);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        if (animationId) {
          cancelAnimationFrame(animationId);
        }
        window.removeEventListener("resize", handleResize);
        clearTimeout(resizeTimeout);
        streams.length = 0;
        ctx = null;
      };
    }, 50); // Small delay to ensure DOM is ready
  });
</script>

<SEO
  title="Shindo | The Way of the Heart"
  description="Explore the Way of the Heart through Shindo - a journey of mindful living, cultural exploration, and global understanding. Discover cities and countries through a lens of heart-centered wisdom."
  keywords="shindo, way of the heart, mindful living, cultural exploration, global understanding, world cities, countries, heart wisdom, mindfulness"
/>


<div bind:this={container} class="animation-container">
  <canvas
    bind:this={canvas}
    style="position: absolute; left: 0; top: 0; pointer-events: none; z-index: 1;"
  />
  <h1 class="title text-gray-700 dark:text-gray-100">Shindō</h1>
  <slot></slot>
</div>

<style>
  .animation-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    min-height: 80%;
    background: transparent;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    z-index: 2;
    margin: 0;
    font-weight: bold;
  }

  :global(body) {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
</style>
