import { logger } from '../utils/logger';

// Base URL for authentication endpoints - switches between production and development environments
const AUTH_BASE_URL = import.meta.env.MODE === 'production'
  ? 'https://auth.shindo.dev'
  : '/auth';

interface AuthResponse {
  message?: string;
  data?: {
    id?: string | number;
    username?: string;
    email?: string;
    token_issued_at?: string;
    token_expires_at?: string;
    token_expires_in?: string;
  };
  error?: string;
}

/**
 * Authenticates a user with email and password
 * @param email - User's email address
 * @param password - User's password
 * @returns Promise containing the API response
 */
export async function loginUser(
  email: string,
  password: string
): Promise<AuthResponse> {
  logger.info('Attempting login for user:', email);

  try {
    const response = await fetch(`${AUTH_BASE_URL}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ email, password })
    });

    logger.info('Login response status:', response.status);

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || `Login failed with status: ${response.status}`);
    }

    logger.info('Login successful');
    return data;
  } catch (error) {
    logger.error('Login error:', error instanceof Error ? error.message : 'Unknown error');
    return { error: error instanceof Error ? error.message : 'Failed to login' };
  }
}

/**
 * Logs out the current user
 * @returns Promise containing the API response
 */
export const logoutUser = async (): Promise<AuthResponse> => {
  logger.info('Attempting logout');

  try {
    const response = await fetch(`${AUTH_BASE_URL}/logout`, {
      method: 'POST',
      credentials: 'include'
    });

    logger.info('Logout response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      logger.error('Logout failed:', errorData);
      throw new Error(errorData.error || `Logout failed with status: ${response.status}`);
    }

    const data = await response.json();
    logger.info('Logout successful');
    return { message: data.message };
  } catch (error) {
    logger.error('Logout error:', error instanceof Error ? error.message : 'Unknown error');
    return { error: error instanceof Error ? error.message : 'Failed to logout' };
  }
};

/**
 * Checks the current authentication status
 * @returns Promise containing the API response
 */
export const checkAuthStatus = async (): Promise<AuthResponse> => {
  logger.info('Checking auth status');

  try {
    const response = await fetch(`${AUTH_BASE_URL}/status`, {
      credentials: 'include'
    });

    logger.info('Auth status response:', response.status);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      logger.error('Auth status check failed:', errorData);
      throw new Error(errorData.error || `Auth check failed with status: ${response.status}`);
    }

    const data = await response.json();
    logger.info('Auth status check successful');
    return {
      data: {
        username: data.username,
        email: data.email,
        token_issued_at: data.token_issued_at,
        token_expires_at: data.token_expires_at,
        token_expires_in: data.token_expires_in
      }
    };
  } catch (error) {
    logger.error('Auth status check error:', error instanceof Error ? error.message : 'Unknown error');
    return { error: error instanceof Error ? error.message : 'Failed to check auth status' };
  }
};