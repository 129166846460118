import type { Country } from '../countries';

export const russia: Country = {
  name: {
    "en": "Russia",
    "ru": "Россия"
  },
  image: "https://images.unsplash.com/photo-1513326738677-b964603b136d",
  gdpBillion: 1778,
  gdp: {
    "en": "1,778 billion USD",
    "ru": "1,778 млрд долл. США"
  },
  currency: {
    "en": "Russian Ruble (₽)",
    "ru": "Российский рубль (₽)"
  },
  topCities: {
    "en": ["Moscow", "Saint Petersburg", "Novosibirsk"],
    "ru": ["Москва", "Санкт-Петербург", "Новосибирск"]
  },
  populationMillion: 143.4,
  population: {
    "en": "143.4 million",
    "ru": "143,4 млн"
  },
  capital: {
    "en": "Moscow",
    "ru": "Москва"
  },
  languages: {
    "en": ["Russian"],
    "ru": ["Русский"]
  },
  continent: {
    "en": "Europe",
    "ru": "Европа"
  },
  timeZones: ["UTC+02:00", "UTC+12:00"],
  drivingSide: "right",
  countryCode: {
    alpha2: "RU",
    alpha3: "RUS"
  },
  government: {
    "en": "Federal semi-presidential republic",
    "ru": "Федеративная президентско-парламентская республика"
  },
  areaTotal: 17098246,
  areaUnit: {
    "en": "km²",
    "ru": "км²"
  },
  climate: {
    "en": ["Arctic", "Subarctic", "Continental"],
    "ru": ["Арктический", "Субарктический", "Континентальный"]
  },
  landmarks: {
    "en": ["Saint Basil's Cathedral", "Hermitage Museum", "Lake Baikal"],
    "ru": ["Храм Василия Блаженного", "Эрмитаж", "Озеро Байкал"]
  },
  internetTLD: ".ru",
  phoneCode: "+7",
  majorCompanies: {
    "en": ["Gazprom", "Rosneft", "Sberbank", "Lukoil", "VTB Bank"],
    "ru": ["Газпром", "Роснефть", "Сбербанк", "Лукойл", "ВТБ"]
  },
  majorIndustries: {
    "en": ["Oil and Gas", "Mining", "Manufacturing", "Agriculture", "Defense"],
    "ru": ["Нефть и газ", "Горнодобывающая", "Производство", "Сельское хозяйство", "Оборонная"]
  },
  geography: {
    "en": "Russia is a country in Eastern Europe with the Arctic Ocean, the Pacific Ocean, and the Ural Mountains.",
    "ru": "Россия - страна в Восточной Европе с Арктическим океаном, Тихим океаном и Уральскими горами."
  },
  history: {
    "en": "Russia's history spans over 1,000 years, with notable dynasties such as the House of Rurik and the House of Romanov.",
    "ru": "История России насчитывает более 1000 лет, с заметными династиями, такими как Дом Рюрика и Дом Романовых."
  },
  culture: {
    "en": "Russian culture is rich and diverse, with influences from Christianity, Orthodox Christianity, and Islam, known for its unique art forms and cuisine.",
    "ru": "Российская культура богата и разнообразна, с влиянием христианства, православия и ислама, известна своей уникальной художественной сцене и кухней."
  },
  travel: {
    "en": "Russia offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
    "ru": "Россия предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
  }
};