import type { City } from '../../cities';

export const riodejaneiro: City = {
    name: {
        "en": "Rio de Janeiro",
        "ru": "Рио-де-Жанейро"
    },
    country: {
        "en": "Brazil",
        "ru": "Бразилия"
    },
    image: "https://images.unsplash.com/photo-1483729558449-99ef09a8c325",
    populationMillion: 6.7,
    population: {
        "en": "6.7 million",
        "ru": "6,7 млн"
    },
    description: {
        "en": "Rio de Janeiro is Brazil's second-largest city, famous for its natural beauty, carnival celebrations, and iconic landmarks.",
        "ru": "Рио-де-Жанейро - второй по величине город Бразилии, известный своей природной красотой, карнавалами и знаковыми достопримечательностями."
    },
    founded: {
        "en": "1565",
        "ru": "1565"
    },
    landmarks: {
        "en": ["Christ the Redeemer", "Sugarloaf Mountain", "Copacabana Beach", "Maracanã Stadium"],
        "ru": ["Христос-Искупитель", "Сахарная Голова", "Пляж Копакабана", "Стадион Маракана"]
    },
    universities: {
        "en": ["Federal University of Rio de Janeiro", "Pontifical Catholic University", "State University of Rio de Janeiro"],
        "ru": ["Федеральный университет Рио-де-Жанейро", "Папский католический университет", "Государственный университет Рио-де-Жанейро"]
    },
    industries: {
        "en": ["Tourism", "Oil and Gas", "Media", "Services", "Technology"],
        "ru": ["Туризм", "Нефть и газ", "Медиа", "Услуги", "Технологии"]
    },
    climate: {
        "en": "Tropical savanna climate with hot summers and mild winters",
        "ru": "Тропический саванный климат с жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Light Rail", "Cable Car"],
        "ru": ["Метро", "Автобус", "Легкорельсовый транспорт", "Канатная дорога"]
    },
    timeZone: "UTC-03:00",
    languages: {
        "en": ["Portuguese"],
        "ru": ["Португальский"]
    },
    airports: {
        "en": ["Galeão International Airport", "Santos Dumont Airport"],
        "ru": ["Международный аэропорт Галеан", "Аэропорт Сантос-Дюмон"]
    },
    isCapital: false,
    coordinates: {
        latitude: -22.9068,
        longitude: -43.1729
    }
};
