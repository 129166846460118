type LogLevel = 'debug' | 'info' | 'warn' | 'error';

interface LogMessage {
  level: LogLevel;
  message: string;
  data?: any;
}

export const logger = {
  debug: (message: string, data?: any) => log('debug', message, data),
  info: (message: string, data?: any) => log('info', message, data),
  warn: (message: string, data?: any) => log('warn', message, data),
  error: (message: string, data?: any) => log('error', message, data)
};

function log(level: LogLevel, message: string, data?: any) {
  const logMessage: LogMessage = { level, message, data };

  switch (level) {
    case 'debug':
      data ? console.debug(message, data) : console.debug(message);
      break;
    case 'info':
      data ? console.info(message, data) : console.info(message);
      break;
    case 'warn':
      data ? console.warn(message, data) : console.warn(message);
      break;
    case 'error':
      data ? console.error(message, data) : console.error(message);
      break;
  }
}
