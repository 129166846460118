<script lang="ts">
  import type { Country } from "./data/countries";
  import { countries as countriesData } from "./data/countries";
  import CountryFilters from "./CountryFilters.svelte";
  import CountryBox from "./CountryBox.svelte";
  import { t, activeLanguage } from "../../../translations/translations";
  import { onMount } from 'svelte';
  import SEO from '../../../lib/components/SEO.svelte';

  let countries: Country[] = countriesData;
  let filteredCountries: Country[] = countries;
  let searchQuery: string = "";

  // Create two separate objects: one for initial settings that never changes,
  // and one for current settings that can be modified
  const initialVisibilitySettings = {
    gdp: true,
    population: true,
    area: true,
    currency: false,
    continent: false,
    phone: false,
    domain: false,
    languages: true,
    majorCities: false,
    majorCompanies: false,
    majorIndustries: false,
    timeZones: false
  };

  let currentVisibilitySettings = { ...initialVisibilitySettings };
  let isLargeBox = true;

  // Reactive statement to update filtered countries when language changes
  $: {
    if (countries) {
      filteredCountries = countries.filter(country => {
        const name = getName(country).toLowerCase();
        return name.includes(searchQuery.toLowerCase());
      });
    }
  }

  // Reactive statements to update unique values when language changes
  $: uniqueContinents =
    countries && $activeLanguage
      ? [...new Set(countries.map((country) => getContinent(country)))].sort()
      : [];

  $: uniqueCurrencies =
    countries && $activeLanguage
      ? [...new Set(countries.map((country) => getCurrency(country)))].sort()
      : [];

  $: uniqueLanguages =
    countries && $activeLanguage
      ? [
          ...new Set(countries.flatMap((country) => getLanguages(country))),
        ].sort()
      : [];

  // Functions to get translated values
  function getName(country: Country): string {
    return (country.name)[$activeLanguage] || country.name.en;
  }

  function getContinent(country: Country): string {
    return (
      (country.continent)[$activeLanguage] ||
      country.continent.en
    );
  }

  function getCurrency(country: Country): string {
    return (
      (country.currency)[$activeLanguage] ||
      country.currency.en
    );
  }

  function getLanguages(country: Country): string[] {
    return (
      (country.languages)[$activeLanguage] ||
      country.languages.en
    );
  }

  function handleFilters(event: CustomEvent) {
    if (!countries) return;

    const { visibilitySettings, ...filters } = event.detail;
    currentVisibilitySettings = visibilitySettings;

    filteredCountries = countries.filter((country) => {
      const name = getName(country).toLowerCase();
      if (!name.includes(searchQuery.toLowerCase())) return false;

      const gdpB = country.gdpBillion;
      const popM = country.populationMillion;

      if (filters.gdpBMin && gdpB < filters.gdpBMin) return false;
      if (filters.gdpBMax && gdpB > filters.gdpBMax) return false;
      if (filters.popMMin && popM < filters.popMMin) return false;
      if (filters.popMMax && popM > filters.popMMax) return false;

      const countryContinent = getContinent(country);
      const countryLanguages = getLanguages(country);
      const countryCurrency = getCurrency(country);

      if (filters.selectedContinent !== "all" && countryContinent !== filters.selectedContinent) return false;
      if (filters.selectedLanguage !== "all" && !countryLanguages.includes(filters.selectedLanguage)) return false;
      if (filters.selectedCurrency !== "all" && countryCurrency !== filters.selectedCurrency) return false;

      return true;
    });

    filteredCountries = sortCountries(filteredCountries, filters);
  }

  function handleSizeChange(event: CustomEvent) {
    isLargeBox = event.detail;
  }

  // Update the sorting function
  function sortCountries(countries: Country[], filters: any): Country[] {
    return [...countries].sort((a, b) => {
      let valueA, valueB;

      switch (filters.sortBy) {
        case "gdp":
          valueA = a.gdpBillion;
          valueB = b.gdpBillion;
          break;
        case "population":
          valueA = a.populationMillion;
          valueB = b.populationMillion;
          break;
        case "area":
          valueA = a.areaTotal;
          valueB = b.areaTotal;
          break;
        default: // 'name'
          valueA = getName(a).toLowerCase();
          valueB = getName(b).toLowerCase();
          return filters.sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
      }

      // Numeric comparison
      if (filters.sortOrder === "asc") {
        return valueA - valueB;
      }
      return valueB - valueA;
    });
  }

  let observerOptions = {
    root: null,
    rootMargin: '50px', // Start loading items 50px before they enter viewport
    threshold: 0.1
  };

  // Track which countries are visible
  let visibleCountries = new Set();

  onMount(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const countryIndex = parseInt(entry.target.dataset.index);
        if (entry.isIntersecting) {
          visibleCountries.add(countryIndex);
          visibleCountries = visibleCountries; // Trigger reactivity
        }
      });
    }, observerOptions);

    // Disconnect observer on component cleanup
    return () => observer.disconnect();
  });

  // Helper function to observe new elements
  function observeElement(element: HTMLElement) {
    if (element && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          const countryIndex = parseInt(entry.target.dataset.index);
          if (entry.isIntersecting) {
            visibleCountries.add(countryIndex);
            visibleCountries = visibleCountries;
          }
        });
      }, observerOptions);

      observer.observe(element);
      return {
        destroy() { observer.disconnect(); }
      };
    }
  }
</script>

<!-- <SEO
  title="Countries Directory | Shindo.dev"
  description="Discover countries worldwide with detailed statistics on GDP, population, languages, and major industries. Comprehensive country guides and information."
  keywords="countries worldwide, country information, global data, world statistics, country guides"
  url="https://shindo.dev/countries"
/> -->

{#if countries && countries.length > 0}
  <div class="container mx-auto px-4 py-8">
    <div class="mb-4 relative">
      <input
        type="text"
        bind:value={searchQuery}
        placeholder={$t.countries.searchPlaceholder}
        class="w-full p-2 pr-10 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
      />
      {#if searchQuery}
        <button
          class="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          on:click={() => searchQuery = ""}
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
          </svg>
        </button>
      {/if}
    </div>

    <CountryFilters
      {uniqueContinents}
      {uniqueCurrencies}
      {uniqueLanguages}
      {isLargeBox}
      initialVisibilitySettings={initialVisibilitySettings}
      on:filterChange={handleFilters}
      on:sizeChange={handleSizeChange}
    />

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
      {#each filteredCountries as country, index}
        <div
          data-index={index}
          use:observeElement
        >
          {#if visibleCountries.has(index)}
            <CountryBox
              {country}
              {isLargeBox}
              visibilitySettings={currentVisibilitySettings}
            />
          {:else}
            <div class="h-96 bg-box-light dark:bg-box-dark rounded-xl shadow-lg" />
          {/if}
        </div>
      {/each}
    </div>
  </div>
{:else}
  <div class="container mx-auto px-4 py-8">
    <p>{$t.countries.loading}</p>
  </div>
{/if}
