import type { Country } from '../countries';

export const india: Country = {
    name: {
        "en": "India",
        "ru": "Индия"
    },
    image: "https://images.unsplash.com/photo-1524492412937-b28074a5d7da",
    gdpBillion: 3170,
    gdp: {
        "en": "3,170 billion USD",
        "ru": "3,170 млрд долл. США"
    },
    currency: {
        "en": "Indian Rupee (₹)",
        "ru": "Индийская рупия (₹)"
    },
    topCities: {
        "en": ["Mumbai", "Delhi", "Bangalore"],
        "ru": ["Мумбаи", "Дели", "Бангалор"]
    },
    populationMillion: 1380,
    population: {
        "en": "1.38 billion",
        "ru": "1,38 млрд"
    },
    capital: {
        "en": "New Delhi",
        "ru": "Нью-Дели"
    },
    languages: {
        "en": ["Hindi", "English"],
        "ru": ["Хинди", "Английский"]
    },
    continent: {
        "en": "Asia",
        "ru": "Азия"
    },
    timeZones: ["UTC+05:30", "UTC+05:45"],
    drivingSide: "right",
    countryCode: {
        alpha2: "IN",
        alpha3: "IND"
    },
    government: {
        "en": "Federal parliamentary democratic republic",
        "ru": "Федеративная демократическая республика"
    },
    areaTotal: 3287263,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Tropical", "Subtropical", "Temperate"],
        "ru": ["Тропический", "Субтропический", "Умеренный"]
    },
    landmarks: {
        "en": ["Taj Mahal", "Red Fort", "Qutub Minar"],
        "ru": ["Тадж-Махал", "Красная крепость", "Кутб-минар"]
    },
    internetTLD: ".in",
    phoneCode: "+91",
    majorCompanies: {
        "en": ["Reliance Industries", "Tata Group", "Infosys", "HDFC Bank", "Bharti Airtel"],
        "ru": ["Reliance Industries", "Tata Group", "Infosys", "HDFC Bank", "Bharti Airtel"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "India is the seventh largest country in the world by land area, with a diverse landscape ranging from the Himalayas to the tropical forests of the south.",
        "ru": "Индия - седьмое по величине государство в мире по площади, с разнообразным ландшафтом, простирающимся от Гималаев до тропических rainforests южного Индии."
    },
    history: {
        "en": "India's history spans over 5,000 years, with notable dynasties such as the Maurya and Gupta, followed by the Mughal Empire.",
        "ru": "История Индии насчитывает более 5000 лет, с заметными династиями, такими как Маурья и Гупта, за которыми следовал Мughal."
    },
    culture: {
        "en": "Indian culture is rich and diverse, with influences from Hinduism, Buddhism, and Jainism, known for its vibrant festivals and cuisine.",
        "ru": "Индийская культура богата и разнообразна, с влиянием индуизма, буддизма и джайнизма, известна своим разнообразным фестивалями и кухней."
    },
    travel: {
        "en": "India offers a wide range of travel experiences from ancient historical sites to vibrant cities, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Индия предлагает широкий спектр путешествий от древних исторических объектов до живописного городского ландшафта, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};