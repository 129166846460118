<script lang="ts">
  export let title = 'Shindo';
  export let description = 'Explore cities and countries around the world with detailed information and statistics';
  export let keywords = 'cities, countries, world data, statistics, travel, global information';
  export let url = 'https://shindo.dev';

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Shindo",
    "description": description,
    "url": url,
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://shindo.dev/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    }
  };
</script>

<svelte:head>
  <title>{title}</title>
  <meta name="description" content={description} />
  <meta name="keywords" content={keywords} />

  <script type="application/ld+json">
    {JSON.stringify(structuredData)}
  </script>
</svelte:head>