import type { Country } from '../countries';

export const unitedKingdom: Country = {
    name: {
        "en": "United Kingdom",
        "ru": "Великобритания"
    },
    capital: {
        "en": "London",
        "ru": "Лондон"
    },
    image: "https://images.unsplash.com/photo-1513635269975-59663e0ac1ad",
    gdpBillion: 3130,
    gdp: {
        "en": "3,130 billion USD",
        "ru": "3,130 млрд долл. США"
    },
    currency: {
        "en": "British Pound (£)",
        "ru": "Британский фунт (£)"
    },
    topCities: {
        "en": ["London", "Manchester", "Birmingham"],
        "ru": ["Лондон", "Манчестер", "Бирмингем"]
    },
    populationMillion: 67.2,
    population: {
        "en": "67.2 million",
        "ru": "67,2 млн"
    },
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    continent: {
        "en": "Europe",
        "ru": "Европа"
    },
    timeZones: ["UTC+00:00"],
    drivingSide: "left",
    countryCode: {
        alpha2: "GB",
        alpha3: "GBR"
    },
    government: {
        "en": "Unitary parliamentary constitutional monarchy",
        "ru": "Унитарная парламентская конституционная монархия"
    },
    areaTotal: 242900,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Temperate", "Oceanic"],
        "ru": ["Умеренный", "Океанический"]
    },
    landmarks: {
        "en": ["Big Ben", "Buckingham Palace", "Stonehenge"],
        "ru": ["Биг Бэн", "Букмингемский дворец", "Стоунхендж"]
    },
    internetTLD: ".uk",
    phoneCode: "+44",
    majorCompanies: {
        "en": ["HSBC", "BP", "GlaxoSmithKline", "Unilever", "Rio Tinto"],
        "ru": ["HSBC", "BP", "GlaxoSmithKline", "Unilever", "Rio Tinto"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "The United Kingdom is a country in Western Europe with the North Sea, the Atlantic Ocean, and the English Channel.",
        "ru": "Великобритания - страна в Западной Европе с Северным морем, Атлантическим океаном и Английским каналом."
    },
    history: {
        "en": "The United Kingdom's history spans over 1,000 years, with notable dynasties such as the House of Wessex and the House of Normandy.",
        "ru": "История Великобритании насчитывает более 1000 лет, с заметными династиями, такими как Дом Уэссекс и Дом Нормандии."
    },
    culture: {
        "en": "British culture is rich and diverse, with influences from Christianity, Roman Catholicism, and Protestantism, known for its unique art forms and cuisine.",
        "ru": "Британская культура богата и разнообразна, с влиянием христианства, римско-католичества и протестантизма, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "The United Kingdom offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Великобритания предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};