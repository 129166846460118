import type { City } from '../../cities';

export const seoul: City = {
    name: {
        "en": "Seoul",
        "ru": "Сеул"
    },
    country: {
        "en": "South Korea",
        "ru": "Южная Корея"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.audleytravel.com%2F4533%2F3238%2F79%2F15983086-seoul-sunset.jpg&f=1&nofb=1&ipt=e577808bbc4bc17b062f77cbc59f42b646e2b0ad440bc5a8cc1795e183dfd402&ipo=images",
    populationMillion: 9.7,
    population: {
        "en": "9.7 million",
        "ru": "9,7 млн"
    },
    description: {
        "en": "Seoul is South Korea's capital and largest metropolis, blending high-tech innovation with rich cultural heritage and traditional temples.",
        "ru": "Сеул - столица и крупнейший мегаполис Южной Кореи, сочетающий высокотехнологичные инновации с богатым культурным наследием и традиционными храмами."
    },
    founded: {
        "en": "18 BC",
        "ru": "18 г. до н.э."
    },
    landmarks: {
        "en": ["Gyeongbokgung Palace", "N Seoul Tower", "Bukchon Hanok Village", "Dongdaemun Design Plaza"],
        "ru": ["Дворец Кёнбоккун", "Сеульская башня", "Деревня ханоков Букчон", "Дизайн-плаза Тондэмун"]
    },
    universities: {
        "en": ["Seoul National University", "Korea University", "Yonsei University"],
        "ru": ["Сеульский национальный университет", "Университет Корё", "Университет Ёнсе"]
    },
    industries: {
        "en": ["Technology", "Entertainment", "Finance", "Manufacturing"],
        "ru": ["Технологии", "Развлечения", "Финансы", "Производство"]
    },
    climate: {
        "en": "Humid continental climate with hot summers and cold winters",
        "ru": "Влажный континентальный климат с жарким летом и холодной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Train", "Taxi"],
        "ru": ["Метро", "Автобус", "Поезд", "Такси"]
    },
    timeZone: "UTC+09:00",
    languages: {
        "en": ["Korean", "English"],
        "ru": ["Корейский", "Английский"]
    },
    airports: {
        "en": ["Incheon International Airport", "Gimpo International Airport"],
        "ru": ["Международный аэропорт Инчхон", "Международный аэропорт Кимпхо"]
    },
    isCapital: true,
    coordinates: {
        latitude: 37.5665,
        longitude: 126.9780
    }
};
