import type { Country } from '../countries';

export const italy: Country = {
    name: {
        "en": "Italy",
        "ru": "Италия"
    },
    image: "https://images.unsplash.com/photo-1552832230-c0197dd311b5",
    gdpBillion: 2100,
    gdp: {
        "en": "2,100 billion USD",
        "ru": "2,100 млрд долл. США"
    },
    currency: {
        "en": "Euro (€)",
        "ru": "Евро (€)"
    },
    topCities: {
        "en": ["Rome", "Milan", "Naples"],
        "ru": ["Рим", "Милан", "Наполи"]
    },
    populationMillion: 60.3,
    population: {
        "en": "60.3 million",
        "ru": "60,3 млн"
    },
    capital: {
        "en": "Rome",
        "ru": "Рим"
    },
    languages: {
        "en": ["Italian"],
        "ru": ["Итальянский"]
    },
    continent: {
        "en": "Europe",
        "ru": "Европа"
    },
    timeZones: ["UTC+01:00"],
    drivingSide: "left",
    countryCode: {
        alpha2: "IT",
        alpha3: "ITA"
    },
    government: {
        "en": "Unitary parliamentary republic",
        "ru": "Унитарная парламентская республика"
    },
    areaTotal: 301338,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Mediterranean", "Oceanic"],
        "ru": ["Медитерранский", "Океанический"]
    },
    landmarks: {
        "en": ["Colosseum", "Trevi Fountain", "Leaning Tower of Pisa"],
        "ru": ["Колизей", "Треви фонтан", "Наклонная башня Пиза"]
    },
    internetTLD: ".it",
    phoneCode: "+39",
    majorCompanies: {
        "en": ["Eni", "Enel", "Ferrari", "Fiat Chrysler", "Gucci"],
        "ru": ["Эни", "Энерго", "Феррари", "Фиат Крус", "Гуччи"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "Italy is a country in Southern Europe with the Mediterranean Sea, the Alps, and the Apennines.",
        "ru": "Италия - страна в Южной Европе с Средиземноморским морем, Альпами и Апеннинами."
    },
    history: {
        "en": "Italy's history spans over 2,000 years, with notable dynasties such as the Romans, Visigoths, and Moors.",
        "ru": "История Италии насчитывает более 2000 лет, с заметными династиями, такими как Римляне, Визиготы и Мавры."
    },
    culture: {
        "en": "Italian culture is rich and diverse, with influences from Christianity, Roman Catholicism, and Protestantism, known for its unique art forms and cuisine.",
        "ru": "Итальянская культура богата и разнообразна, с влиянием христианства, римско-католичества и протестантизма, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "Italy offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Италия предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};