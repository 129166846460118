import type { City } from '../../cities';

export const shanghai: City = {
    name: {
        "en": "Shanghai",
        "ru": "Шанхай"
    },
    country: {
        "en": "China",
        "ru": "Китай"
    },
    image: "https://images.unsplash.com/photo-1538428494232-9c0d8a3ab403",
    populationMillion: 27.8,
    population: {
        "en": "27.8 million",
        "ru": "27,8 млн"
    },
    description: {
        "en": "Shanghai is China's largest city and global financial hub, known for its futuristic skyline and blend of Western and Eastern influences.",
        "ru": "Шанхай - крупнейший город Китая и мировой финансовый центр, известный своим футуристическим горизонтом и сочетанием западного и восточного влияния."
    },
    founded: {
        "en": "751",
        "ru": "751"
    },
    landmarks: {
        "en": ["The Bund", "Oriental Pearl Tower", "Yu Garden", "Nanjing Road"],
        "ru": ["Набережная Вайтань", "Восточная жемчужина", "Сад Юй", "Улица Нанкин"]
    },
    universities: {
        "en": ["Fudan University", "Shanghai Jiao Tong University", "Tongji University"],
        "ru": ["Университет Фудань", "Шанхайский университет Цзяо Тун", "Университет Тунцзи"]
    },
    industries: {
        "en": ["Finance", "Technology", "Manufacturing", "Shipping"],
        "ru": ["Финансы", "Технологии", "Производство", "Судоходство"]
    },
    climate: {
        "en": "Humid subtropical climate with four distinct seasons",
        "ru": "Влажный субтропический климат с четырьмя различными сезонами"
    },
    transportation: {
        "en": ["Metro", "Bus", "Maglev Train", "Ferry"],
        "ru": ["Метро", "Автобус", "Поезд на магнитной подушке", "Паром"]
    },
    timeZone: "UTC+08:00",
    languages: {
        "en": ["Mandarin Chinese", "Shanghainese", "English"],
        "ru": ["Китайский (мандарин)", "Шанхайский диалект", "Английский"]
    },
    airports: {
        "en": ["Shanghai Pudong International Airport", "Shanghai Hongqiao International Airport"],
        "ru": ["Международный аэропорт Шанхай Пудун", "Международный аэропорт Шанхай Хунцяо"]
    },
    isCapital: false,
    coordinates: {
        latitude: 31.2304,
        longitude: 121.4737
    }
};
