import type { Country } from '../countries';

export const france: Country = {
  name: {
    "en": "France",
    "ru": "Франция"
  },
  image: "https://images.unsplash.com/photo-1502602898657-3e91760cbb34",
  gdpBillion: 2780,
  gdp: {
    "en": "2,780 billion USD",
    "ru": "2,780 млрд долл. США"
  },
  currency: {
    "en": "Euro (€)",
    "ru": "Евро (€)"
  },
  topCities: {
    "en": ["Paris", "Lyon", "Marseille"],
    "ru": ["Париж", "Лион", "Марсель"]
  },
  populationMillion: 67.4,
  population: {
    "en": "67.4 million",
    "ru": "67,4 млн"
  },
  capital: {
    "en": "Paris",
    "ru": "Париж"
  },
  languages: {
    "en": ["French"],
    "ru": ["Французский"]
  },
  continent: {
    "en": "Europe",
    "ru": "Европа"
  },
  timeZones: ["UTC+01:00"],
  drivingSide: "left",
  countryCode: {
    alpha2: "FR",
    alpha3: "FRA"
  },
  government: {
    "en": "Unitary semi-presidential republic",
    "ru": "Унитарная полупрезидентская республика"
  },
  areaTotal: 643801,
  areaUnit: {
    "en": "km²",
    "ru": "км²"
  },
  climate: {
    "en": ["Mediterranean", "Oceanic"],
    "ru": ["Медитерранский", "Океанический"]
  },
  landmarks: {
    "en": ["Eiffel Tower", "Louvre Museum", "Notre-Dame Cathedral"],
    "ru": ["Эйфелева башня", "Лувр", "Собор Парижской Богоматери"]
  },
  internetTLD: ".fr",
  phoneCode: "+33",
  majorCompanies: {
    "en": ["LVMH", "Total", "L'Oréal", "BNP Paribas", "AXA"],
    "ru": ["LVMH", "Total", "L'Oréal", "BNP Paribas", "AXA"]
  },
  majorIndustries: {
    "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
    "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
  },
  geography: {
    "en": "France is a country in Western Europe with the Atlantic Ocean, the English Channel, and the Alps.",
    "ru": "Франция - страна в Западной Европе с Атлантическим океаном, Английским каналом и Альпами."
  },
  history: {
    "en": "France's history spans over 2,000 years, with notable dynasties such as the House of Capet and the House of Bourbon.",
    "ru": "История Франции насчитывает более 2000 лет, с заметными династиями, такими как Дом Капетингов и Дом Бурбонов."
  },
  culture: {
    "en": "French culture is rich and diverse, with influences from Christianity, Roman Catholicism, and Protestantism, known for its unique art forms and cuisine.",
    "ru": "Французская культура богата и разнообразна, с влиянием христианства, римско-католичества и протестантизма, известна своей уникальной художественной сцене и кухней."
  },
  travel: {
    "en": "France offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
    "ru": "Франция предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
  }
};