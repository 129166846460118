import { type LanguageCode } from "./translations";

export type CatchTranslation = {
    errorCode: string;
    title: string;
    message: string;
    homeButton: string;
};

export const catchTranslations: Record<LanguageCode, CatchTranslation> = {
    en: {
        errorCode: "404",
        title: "Page Not Found",
        message: "Oops! The page you're looking for doesn't exist.",
        homeButton: "Go Home"
    },
    ru: {
        errorCode: "404",
        title: "Страница не найдена",
        message: "Упс! Страница, которую вы ищете, не существует.",
        homeButton: "На главную"
    },
};