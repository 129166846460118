import type { City } from '../../cities';

export const vladivostok: City = {
    name: {
        "en": "Vladivostok",
        "ru": "Владивосток"
    },
    country: {
        "en": "Russia",
        "ru": "Россия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fsailtraininginternational.org%2Fapp%2Fuploads%2F2017%2F10%2Fvladivostok-3.jpg&f=1&nofb=1&ipt=3c56d3b75136f94aa6fa84770ef0481c8f2279f621c0963c82fa59b744a836ba&ipo=images",
    populationMillion: 0.606,
    population: {
        "en": "606,000",
        "ru": "606 тыс."
    },
    description: {
        "en": "Vladivostok is Russia's major Pacific port city and the terminus of the Trans-Siberian Railway. It's known for its maritime heritage and stunning bridges.",
        "ru": "Владивосток - крупнейший тихоокеанский порт России и конечная точка Транссибирской магистрали. Известен своим морским наследием и впечатляющими мостами."
    },
    founded: {
        "en": "1860",
        "ru": "1860"
    },
    landmarks: {
        "en": ["Russky Bridge", "Golden Bridge", "Eagle's Nest Mount", "Submarine S-56"],
        "ru": ["Русский мост", "Золотой мост", "Орлиное гнездо", "Подводная лодка С-56"]
    },
    universities: {
        "en": ["Far Eastern Federal University", "Maritime State University", "Vladivostok State University of Economics and Service"],
        "ru": ["Дальневосточный федеральный университет", "Морской государственный университет", "Владивостокский государственный университет экономики и сервиса"]
    },
    industries: {
        "en": ["Shipping", "Fishing", "Military", "Tourism"],
        "ru": ["Судоходство", "Рыболовство", "Военная промышленность", "Туризм"]
    },
    climate: {
        "en": "Humid continental climate with warm summers and cold winters",
        "ru": "Влажный континентальный климат с теплым летом и холодной зимой"
    },
    transportation: {
        "en": ["Bus", "Funicular", "Ferry", "Train"],
        "ru": ["Автобус", "Фуникулер", "Паром", "Поезд"]
    },
    timeZone: "UTC+10:00",
    languages: {
        "en": ["Russian", "English"],
        "ru": ["Русский", "Английский"]
    },
    airports: {
        "en": ["Vladivostok International Airport"],
        "ru": ["Международный аэропорт Владивосток"]
    },
    isCapital: false,
    coordinates: {
        latitude: 43.1332,
        longitude: 131.9113
    }
};
