import type { Country } from '../countries';

export const singapore: Country = {
    name: {
        "en": "Singapore",
        "ru": "Сингапур"
    },
    image: "https://images.unsplash.com/photo-1525625293386-3f8f99389edd",
    gdpBillion: 370,
    gdp: {
        "en": "370 billion USD",
        "ru": "370 млрд долл. США"
    },
    currency: {
        "en": "Singapore Dollar (S$)",
        "ru": "Сингапурский доллар (S$)"
    },
    topCities: {
        "en": ["Marina Bay", "Orchard", "Jurong"],
        "ru": ["Марина-Бей", "Орд", "Джорен"]
    },
    populationMillion: 5.6,
    population: {
        "en": "5.6 million",
        "ru": "5,6 млн"
    },
    capital: {
        "en": "Singapore",
        "ru": "Сингапур"
    },
    languages: {
        "en": ["English", "Malay", "Chinese", "Tamil"],
        "ru": ["Английский", "Малайский", "Китайский", "Тамильский"]
    },
    continent: {
        "en": "Asia",
        "ru": "Азия"
    },
    timeZones: ["UTC+08:00"],
    drivingSide: "right",
    countryCode: {
        alpha2: "SG",
        alpha3: "SGP"
    },
    government: {
        "en": "Unitary parliamentary republic",
        "ru": "Унитарная парламентская республика"
    },
    areaTotal: 728,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Tropical", "Humid subtropical"],
        "ru": ["Тропический", "Влажный субтропический климат"]
    },
    landmarks: {
        "en": ["Marina Bay Sands", "Gardens by the Bay", "Merlion"],
        "ru": ["Марина-Бей-Сандс", "Сады у залива", "Мерлион"]
    },
    internetTLD: ".sg",
    phoneCode: "+65",
    majorCompanies: {
        "en": ["DBS Bank", "OCBC Bank", "United Overseas Bank", "Singtel", "Wilmar International"],
        "ru": ["DBS Bank", "OCBC Bank", "United Overseas Bank", "Singtel", "Wilmar International"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "Singapore is an island city-state with the main island of Singapore, Pulau Tekong, and the Southern Islands.",
        "ru": "Сингапур - островное государство с главным островом Сингапур, островом Теконг и Южными островами."
    },
    history: {
        "en": "Singapore's history spans over 1,000 years, with notable dynasties such as the Temasek and the Malay Sultanate.",
        "ru": "История Сингапура насчитывает более 1000 лет, с заметными династиями, такими как Темасек и Малайский султанат."
    },
    culture: {
        "en": "Singapore's culture is a blend of Chinese, Malay, and Indian influences, known for its unique blend of cultures and cuisine.",
        "ru": "Культура Сингапура представляет собой смесь китайской, малайской и индийской культур, известна своей уникальной смесью культур и кухни."
    },
    travel: {
        "en": "Singapore offers a wide range of travel experiences from modern urban landscapes to historical sites, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Сингапур предлагает широкий спектр путешествий от современных городских ландшафтов до исторических объектов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};