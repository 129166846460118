import type { City } from '../../cities';

export const moscow: City = {
    name: {
        "en": "Moscow",
        "ru": "Москва"
    },
    country: {
        "en": "Russia",
        "ru": "Россия"
    },
    image: "https://images.unsplash.com/photo-1513326738677-b964603b136d",
    populationMillion: 12.5,
    population: {
        "en": "12.5 million",
        "ru": "12,5 млн"
    },
    description: {
        "en": "Moscow is Russia's capital and largest city, a major political, economic and cultural center combining historic architecture with modern development.",
        "ru": "Москва - столица и крупнейший город России, важный политический, экономический и культурный центр, сочетающий историческую архитектуру с современным развитием."
    },
    founded: {
        "en": "1147",
        "ru": "1147"
    },
    landmarks: {
        "en": ["Red Square", "Saint Basil's Cathedral", "Kremlin", "Bolshoi Theatre"],
        "ru": ["Красная площадь", "Храм Василия Блаженного", "Кремль", "Большой театр"]
    },
    universities: {
        "en": ["Moscow State University", "MGIMO University", "Bauman Moscow State Technical University"],
        "ru": ["МГУ им. М.В. Ломоносова", "МГИМО", "МГТУ им. Н.Э. Баумана"]
    },
    industries: {
        "en": ["Finance", "Technology", "Manufacturing", "Media"],
        "ru": ["Финансы", "Технологии", "Производство", "Медиа"]
    },
    climate: {
        "en": "Humid continental climate with warm summers and long cold winters",
        "ru": "Влажный континентальный климат с теплым летом и длинной холодной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Tram", "Trolleybus"],
        "ru": ["Метро", "Автобус", "Трамвай", "Троллейбус"]
    },
    timeZone: "UTC+03:00",
    languages: {
        "en": ["Russian", "English"],
        "ru": ["Русский", "Английский"]
    },
    airports: {
        "en": ["Sheremetyevo International Airport", "Domodedovo International Airport", "Vnukovo International Airport"],
        "ru": ["Международный аэропорт Шереметьево", "Международный аэропорт Домодедово", "Международный аэропорт Внуково"]
    },
    isCapital: true,
    coordinates: {
        latitude: 55.7558,
        longitude: 37.6173
    }
};
