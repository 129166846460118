<script lang="ts">
  export let title: string;
  export let data: Record<string, any> = {};

  // Helper function to format value display
  const formatValue = (value: any) => {
    if (typeof value === 'string' && value.length > 50) {
      return {
        type: 'long-text',
        content: value
      };
    } else if (typeof value === 'object') {
      return {
        type: 'object',
        content: JSON.stringify(value, null, 2)
      };
    }
    return {
      type: 'simple',
      content: value
    };
  };
</script>

<div class="bg-box-light dark:bg-box-dark rounded-xl shadow-lg p-4 overflow-hidden w-full h-full min-h-fit max-w-md">
  <h2 class="text-lg font-bold mb-4 text-txt-heading-light dark:text-txt-heading-dark tracking-tight text-center">
    {title}
  </h2>

  <div class="flex-1 min-h-0">
    <div class="min-h-fit h-full border-form-border-light dark:border-form-border-dark divide-y divide-form-border-light dark:divide-form-border-dark overflow-y-auto pr-2">
      {#each Object.entries(data) as [key, value]}
        {@const formattedValue = formatValue(value)}
        <div class="py-3 first:pt-2 last:pb-2">
          <h3 class="font-medium text-xs text-txt-body-light dark:text-txt-body-dark mb-2 uppercase tracking-wide">{key}</h3>

          {#if formattedValue.type === 'long-text' || formattedValue.type === 'object'}
            <div class="overflow-x-auto">
              <pre class="text-sm bg-box-light dark:bg-box-dark p-3 rounded-md text-txt-body-light dark:text-txt-body-dark whitespace-pre-wrap break-words font-mono leading-snug">{formattedValue.content}</pre>
            </div>
          {:else}
            <p class="text-sm text-txt-body-light dark:text-txt-body-dark break-words leading-snug">{formattedValue.content}</p>
          {/if}
        </div>
      {/each}
    </div>
  </div>
</div>