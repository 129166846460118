import type { City } from '../../cities';

export const delhi: City = {
    name: {
        "en": "Delhi",
        "ru": "Дели"
    },
    country: {
        "en": "India",
        "ru": "Индия"
    },
    image: "https://images.unsplash.com/photo-1587474260584-136574528ed5",
    populationMillion: 32.9,
    population: {
        "en": "32.9 million",
        "ru": "32,9 млн"
    },
    description: {
        "en": "Delhi is India's capital and a major cultural hub, blending historical monuments with modern development. It's one of the oldest continuously inhabited cities in the world.",
        "ru": "Дели - столица Индии и важный культурный центр, сочетающий исторические памятники с современным развитием. Это один из старейших постоянно населенных городов мира."
    },
    founded: {
        "en": "6th century BC",
        "ru": "6 век до н.э."
    },
    landmarks: {
        "en": ["Red Fort", "Qutub Minar", "India Gate", "Humayun's Tomb"],
        "ru": ["Красный форт", "Кутуб-Минар", "Ворота Индии", "Гробница Хумаюна"]
    },
    universities: {
        "en": ["University of Delhi", "Jawaharlal Nehru University", "Indian Institute of Technology Delhi"],
        "ru": ["Делийский университет", "Университет имени Джавахарлала Неру", "Индийский технологический институт Дели"]
    },
    industries: {
        "en": ["Information Technology", "Tourism", "Retail", "Manufacturing"],
        "ru": ["Информационные технологии", "Туризм", "Розничная торговля", "Производство"]
    },
    climate: {
        "en": "Humid subtropical climate with very hot summers and mild winters",
        "ru": "Влажный субтропический климат с очень жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Auto Rickshaw", "Railway"],
        "ru": ["Метро", "Автобус", "Авторикша", "Железная дорога"]
    },
    timeZone: "UTC+05:30",
    languages: {
        "en": ["Hindi", "English", "Punjabi", "Urdu"],
        "ru": ["Хинди", "Английский", "Панджаби", "Урду"]
    },
    airports: {
        "en": ["Indira Gandhi International Airport", "Delhi Airport Terminal 3"],
        "ru": ["Международный аэропорт имени Индиры Ганди", "Терминал 3 аэропорта Дели"]
    },
    isCapital: true,
    coordinates: {
        latitude: 28.6139,
        longitude: 77.2090
    }
};
