import type { Country } from '../countries';

export const australia: Country = {
    name: {
        "en": "Australia",
        "ru": "Австралия"
    },
    image: "https://images.unsplash.com/photo-1523482580672-f109ba8cb9be",
    gdpBillion: 1540,
    gdp: {
        "en": "1,540 billion USD",
        "ru": "1,540 млрд долл. США"
    },
    currency: {
        "en": "Australian Dollar ($)",
        "ru": "Австралийский доллар ($)"
    },
    topCities: {
        "en": ["Sydney", "Melbourne", "Brisbane"],
        "ru": ["Сидней", "Мельбурн", "Брисбен"]
    },
    populationMillion: 25.7,
    population: {
        "en": "25.7 million",
        "ru": "25,7 млн"
    },
    capital: {
        "en": "Canberra",
        "ru": "Канберра"
    },
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    continent: {
        "en": "Oceania",
        "ru": "Океания"
    },
    timeZones: ["UTC+10:00", "UTC+11:00"],
    drivingSide: "left",
    countryCode: {
        alpha2: "AU",
        alpha3: "AUS"
    },
    government: {
        "en": "Federal parliamentary democracy",
        "ru": "Федеративная демократическая республика"
    },
    areaTotal: 7692024,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Arid", "Continental", "Oceanic"],
        "ru": ["Пустыня", "Континентальный климат", "Океанический"]
    },
    landmarks: {
        "en": ["Sydney Opera House", "Great Barrier Reef", "Uluru"],
        "ru": ["Сиднейский оперный театр", "Большой Барьерный риф", "Улуру"]
    },
    internetTLD: ".au",
    phoneCode: "+61",
    majorCompanies: {
        "en": ["BHP", "Commonwealth Bank", "CSL", "Westpac", "ANZ Bank"],
        "ru": ["BHP", "Commonwealth Bank", "CSL", "Westpac", "ANZ Bank"]
    },
    majorIndustries: {
        "en": ["Mining", "Financial Services", "Healthcare", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Финансовые услуги", "Здравоохранение", "Технологии", "Туризм"]
    },
    geography: {
        "en": "Australia is the world's smallest continent and largest island, characterized by the vast Outback desert, Great Dividing Range, and extensive coastline. The country features unique landscapes from tropical rainforests to arid deserts.",
        "ru": "Австралия - самый маленький континент и самый большой остров в мире, характеризующийся обширной пустыней Аутбэк, Большим Водораздельным хребтом и протяженной береговой линией."
    },
    history: {
        "en": "Australia's history spans over 65,000 years of Aboriginal settlement, followed by European colonization in 1788, Federation in 1901, and development into a modern multicultural nation.",
        "ru": "История Австралии охватывает более 65 000 лет поселения аборигенов, за которым последовала европейская колонизация в 1788 году, образование Федерации в 1901 году и развитие в современную многокультурную нацию."
    },
    culture: {
        "en": "Australian culture blends Indigenous heritage with modern Western influences, known for its laid-back lifestyle, outdoor living, sports enthusiasm, and unique arts scene.",
        "ru": "Австралийская культура сочетает наследие коренных народов с современными западными влияниями, известна непринужденным образом жизни, жизнью на природе, энтузиазмом к спорту и уникальной художественной сценой."
    },
    travel: {
        "en": "Australia offers diverse experiences from iconic landmarks to natural wonders, with excellent tourism infrastructure and unique wildlife encounters.",
        "ru": "Австралия предлагает разнообразные впечатления от знаковых достопримечательностей до природных чудес, с отличной туристической инфраструктурой и уникальными встречами с дикой природой."
    }
};
