import type { City } from '../../cities';

export const vancouver: City = {
    name: {
        "en": "Vancouver",
        "ru": "Ванкувер"
    },
    country: {
        "en": "Canada",
        "ru": "Канада"
    },
    image: "https://images.unsplash.com/photo-1560813962-ff3d8fcf59ba",
    populationMillion: 2.5,
    population: {
        "en": "2.5 million",
        "ru": "2,5 млн"
    },
    description: {
        "en": "Vancouver is a major city in western Canada, known for its stunning natural beauty, surrounded by mountains and ocean. It consistently ranks as one of the world's most livable cities.",
        "ru": "Ванкувер - крупный город в западной Канаде, известный своей потрясающей природной красотой, окруженный горами и океаном. Стабильно входит в число самых комфортных для жизни городов мира."
    },
    founded: {
        "en": "1886",
        "ru": "1886"
    },
    landmarks: {
        "en": ["Stanley Park", "Granville Island", "Gastown", "Vancouver Lookout"],
        "ru": ["Стэнли Парк", "Остров Гранвиль", "Гастаун", "Смотровая площадка Ванкувера"]
    },
    universities: {
        "en": ["University of British Columbia", "Simon Fraser University", "Vancouver Film School"],
        "ru": ["Университет Британской Колумбии", "Университет Саймона Фрейзера", "Ванкуверская школа кино"]
    },
    industries: {
        "en": ["Film Production", "Technology", "Tourism", "Port Operations", "Green Technology"],
        "ru": ["Кинопроизводство", "Технологии", "Туризм", "Портовые операции", "Зеленые технологии"]
    },
    climate: {
        "en": "Oceanic climate with mild winters and warm summers",
        "ru": "Океанический климат с мягкими зимами и теплым летом"
    },
    transportation: {
        "en": ["SkyTrain", "Bus", "SeaBus", "Aquabus"],
        "ru": ["Скайтрейн", "Автобус", "Морской автобус", "Аквабус"]
    },
    timeZone: "UTC-08:00",
    languages: {
        "en": ["English", "French"],
        "ru": ["Английский", "Французский"]
    },
    airports: {
        "en": ["Vancouver International Airport"],
        "ru": ["Международный аэропорт Ванкувер"]
    },
    isCapital: false,
    coordinates: {
        latitude: 49.2827,
        longitude: -123.1207
    }
};
