import type { Country } from '../countries';

export const southKorea: Country = {
    name: {
        "en": "South Korea",
        "ru": "Республика Корея"
    },
    image: "https://images.unsplash.com/photo-1517154421773-0529f29ea451",
    gdpBillion: 1710,
    gdp: {
        "en": "1,710 billion USD",
        "ru": "1,710 млрд долл. США"
    },
    currency: {
        "en": "South Korean Won (₩)",
        "ru": "Республиканская вона (₩)"
    },
    topCities: {
        "en": ["Seoul", "Busan", "Incheon"],
        "ru": ["Сеул", "Пусан", "Инчхон"]
    },
    populationMillion: 51.7,
    population: {
        "en": "51.7 million",
        "ru": "51,7 млн"
    },
    capital: {
        "en": "Seoul",
        "ru": "Сеул"
    },
    languages: {
        "en": ["Korean"],
        "ru": ["Корейский"]
    },
    continent: {
        "en": "Asia",
        "ru": "Азия"
    },
    timeZones: ["UTC+09:00"],
    drivingSide: "right",
    countryCode: {
        alpha2: "KR",
        alpha3: "KOR"
    },
    government: {
        "en": "Unitary presidential constitutional republic",
        "ru": "Унитарная президиальная конституционная республика"
    },
    areaTotal: 100210,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Temperate", "Humid subtropical"],
        "ru": ["Умеренный", "Влажный субтропический климат"]
    },
    landmarks: {
        "en": ["Gyeongbokgung Palace", "Seoraksan National Park", "Jogyesa Temple"],
        "ru": ["Гейонбокгун дворец", "Сораксан национальный парк", "Дворец Джокья"]
    },
    internetTLD: ".kr",
    phoneCode: "+82",
    majorCompanies: {
        "en": ["Samsung", "Hyundai", "SK Group", "LG", "Kia"],
        "ru": ["Samsung", "Hyundai", "SK Group", "LG", "Kia"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "South Korea is a peninsula with the Korean Peninsula, Jeju Island, and the East Sea.",
        "ru": "Южная Корея - полуостров с Корейским полуостровом, островом Джеху и Восточным морем."
    },
    history: {
        "en": "South Korea's history spans over 5,000 years, with notable dynasties such as the Goguryeo, Baekje, and Silla.",
        "ru": "История Южной Кореи насчитывает более 5000 лет, с заметными династиями, такими как Гогурейо, Бейдже и Сила."
    },
    culture: {
        "en": "South Korean culture is rich and diverse, with influences from Buddhism, Confucianism, and Shamanism, known for its unique art forms and cuisine.",
        "ru": "Корейская культура богата и разнообразна, с влиянием буддизма, конфуцианства и шаманства, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "South Korea offers a wide range of travel experiences from ancient historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Южная Корея предлагает широкий спектр путешествий от древних исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};