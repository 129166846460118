import type { City } from '../../cities';

export const osaka: City = {
    name: {
        "en": "Osaka",
        "ru": "Осака"
    },
    country: {
        "en": "Japan",
        "ru": "Япония"
    },
    image: "https://images.unsplash.com/photo-1590559899731-a382839e5549",
    populationMillion: 19.1,
    population: {
        "en": "19.1 million",
        "ru": "19,1 млн"
    },
    description: {
        "en": "Osaka is Japan's kitchen and a major economic hub, famous for its food culture and vibrant nightlife.",
        "ru": "Осака - кухня Японии и крупный экономический центр, известный своей культурой еды и ночной жизнью."
    },
    founded: {
        "en": "645",
        "ru": "645"
    },
    landmarks: {
        "en": ["Osaka Castle", "Dotonbori", "Universal Studios Japan", "Kuromon Market"],
        "ru": ["Замок Осака", "Дотонбори", "Юниверсал Студиос Япония", "Рынок Куромон"]
    },
    universities: {
        "en": ["Osaka University", "Kansai University", "Osaka City University"],
        "ru": ["Университет Осаки", "Университет Кансай", "Городской университет Осаки"]
    },
    industries: {
        "en": ["Manufacturing", "Electronics", "Food Processing", "Tourism"],
        "ru": ["Производство", "Электроника", "Пищевая промышленность", "Туризм"]
    },
    climate: {
        "en": "Humid subtropical climate with four distinct seasons",
        "ru": "Влажный субтропический климат с четырьмя различными сезонами"
    },
    transportation: {
        "en": ["Metro", "JR Lines", "Bus", "Ferry"],
        "ru": ["Метро", "Линии JR", "Автобус", "Паром"]
    },
    timeZone: "UTC+09:00",
    languages: {
        "en": ["Japanese", "English"],
        "ru": ["Японский", "Английский"]
    },
    airports: {
        "en": ["Kansai International Airport", "Osaka International Airport"],
        "ru": ["Международный аэропорт Кансай", "Международный аэропорт Осака"]
    },
    isCapital: false,
    coordinates: {
        latitude: 34.6937,
        longitude: 135.5023
    }
};