// localStore/dialogStore.ts
import { writable } from 'svelte/store';

// Define a type for the message role
type MessageRole = "user" | "assistant";

// Define the structure of a message
interface Message {
    id: string;
    role: MessageRole;
    content: string;
}

// Get initial messages from localStorage or use empty array
const storedMessages = localStorage.getItem('chatMessages');
const initialMessages: Message[] = storedMessages
    ? JSON.parse(storedMessages).map((msg: Message) => {
        // Ensure existing messages have IDs
        return msg.id ? msg : { ...msg, id: crypto.randomUUID() };
    })
    : [];

// Create a writable store for messages with localStorage persistence
const messageStore = writable<Message[]>(initialMessages);

// Create a custom store that syncs with localStorage
export const messages = {
    ...messageStore,
    set: (value: Message[]) => {
        localStorage.setItem('chatMessages', JSON.stringify(value));
        messageStore.set(value);
    },
    update: (updater: (value: Message[]) => Message[]) => {
        messageStore.update(value => {
            const updatedValue = updater(value);
            localStorage.setItem('chatMessages', JSON.stringify(updatedValue));
            return updatedValue;
        });
    }
};