import { writable, derived } from 'svelte/store';
import { menuTranslations } from './t_menu';
import type { MenuTranslation } from './t_menu';
import { chatTranslations } from './t_chat';
import type { ChatTranslation } from './t_chat';
import { loginTranslations } from './t_login';
import type { LoginTranslation } from './t_login';
import { statusTranslations } from './t_status';
import type { StatusTranslation } from './t_status';
import { footerTranslations } from './t_footer';
import type { FooterTranslation } from './t_footer';
import { privacyTranslations } from './t_privacy';
import type { PrivacyTranslation } from './t_privacy';
import { termsTranslations } from './t_terms';
import type { TermsTranslation } from './t_terms';
import { contactTranslations } from './t_contact';
import type { ContactTranslation } from './t_contact';
import { aboutTranslations } from './t_about';
import type { AboutTranslation } from './t_about';
import { catchTranslations } from './t_catch';
import type { CatchTranslation } from './t_catch';
import { registerTranslations } from './t_register';
import type { RegisterTranslation } from './t_register';
import { countriesTranslations } from './t_country';
import type { CountriesTranslation } from './t_country';
import { citiesTranslations } from './t_city';
import type { CitiesTranslation } from './t_city';

// Define the type for translations
export type Translation = {
    title: string;
    text: string;
    menu: MenuTranslation;
    chat: ChatTranslation;
    login: LoginTranslation;
    status: StatusTranslation;
    footer: FooterTranslation;
    privacy: PrivacyTranslation;
    terms: TermsTranslation;
    contact: ContactTranslation;
    about: AboutTranslation;
    catch: CatchTranslation;
    register: RegisterTranslation;
    countries: CountriesTranslation;
    cities: CitiesTranslation;
};

export type Translations = {
    [key: string]: Translation;
};

// Export available languages for reuse
export const availableLanguages = ['en', 'ru'] as const;
export type LanguageCode = typeof availableLanguages[number];

// Get initial language from localStorage or default to 'en'
const browserLanguage = typeof window !== 'undefined' ? window.localStorage.getItem('language') : null;
const initialLanguage = (() => {
    if (!browserLanguage) return 'en';
    if (!availableLanguages.includes(browserLanguage as LanguageCode)) {
        console.warn(`Invalid language "${browserLanguage}" found in localStorage, defaulting to English`);
        return 'en';
    }
    return browserLanguage as LanguageCode;
})();

// Create the language store with persisted language
export const activeLanguage = writable<LanguageCode>(initialLanguage);

// Subscribe to changes and update localStorage
activeLanguage.subscribe((language) => {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem('language', language);
    }
});

// Modified translations object that imports menu from menuTranslations
export const translations: Record<LanguageCode, Translation> = {
    en: {
        title: "English",
        text: "English",
        menu: menuTranslations.en,
        chat: chatTranslations.en,
        login: loginTranslations.en,
        status: statusTranslations.en,
        footer: footerTranslations.en,
        privacy: privacyTranslations.en,
        terms: termsTranslations.en,
        contact: contactTranslations.en,
        about: aboutTranslations.en,
        catch: catchTranslations.en,
        register: registerTranslations.en,
        countries: countriesTranslations.en,
        cities: citiesTranslations.en,
    },
    ru: {
        title: "Русский",
        text: "Русский",
        menu: menuTranslations.ru,
        chat: chatTranslations.ru,
        login: loginTranslations.ru,
        status: statusTranslations.ru,
        footer: footerTranslations.ru,
        privacy: privacyTranslations.ru,
        terms: termsTranslations.ru,
        contact: contactTranslations.ru,
        about: aboutTranslations.ru,
        catch: catchTranslations.ru,
        register: registerTranslations.ru,
        countries: countriesTranslations.ru,
        cities: citiesTranslations.ru,
    },
};

// Create a derived store for active translations
export const t = derived(activeLanguage, ($activeLanguage) => translations[$activeLanguage]);