<script lang="ts">
    import { t } from "../../../translations/translations";

    let name = "";
    let message = "";
    let showSuccess = false;
    let showError = false;

    const handleSubmit = async () => {
        try {
            // Implement your form submission logic here
            // For example, using fetch to send to your backend
            showSuccess = true;
            showError = false;
            name = "";
            message = "";
        } catch (error) {
            showSuccess = false;
            showError = true;
        }
    };
</script>

<div
    id="contact-container"
    class="container mx-auto px-4 py-12 max-w-[600px]"
>
    <div
        id="contact-card"
        class="w-full p-8 bg-box-light dark:bg-box-dark rounded-xl shadow-lg"
    >
        <h1
            id="contact-title"
            class="text-4xl text-center text-txt-heading-light dark:text-txt-heading-dark tracking-tight font-bold mb-8"
        >
            {$t.contact.title}
        </h1>

        {#if showError}
            <div
                id="error-message"
                class="p-4 mb-6 bg-status-error/10 rounded-lg text-sm text-status-error font-medium"
            >
                {$t.contact.errorMessage}
            </div>
        {/if}

        {#if showSuccess}
            <div
                id="success-message"
                class="p-4 mb-6 bg-status-success/10 rounded-lg text-sm text-status-success font-medium"
            >
                {$t.contact.successMessage}
            </div>
        {/if}

        <form
            id="contact-form"
            on:submit|preventDefault={handleSubmit}
            class="flex flex-col w-full gap-5"
        >
            <div
                id="name-field-container"
                class="flex flex-col"
            >
                <label
                    for="name"
                    class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                >
                    {$t.contact.name}
                </label>
                <input
                    type="text"
                    id="name"
                    bind:value={name}
                    placeholder={$t.contact.namePlaceholder}
                    class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    required
                />
            </div>

            <div
                id="message-field-container"
                class="flex flex-col"
            >
                <label
                    for="message"
                    class="mb-1.5 text-sm text-txt-body-light dark:text-txt-body-dark font-medium"
                >
                    {$t.contact.message}
                </label>
                <textarea
                    id="message"
                    bind:value={message}
                    placeholder={$t.contact.messagePlaceholder}
                    rows="6"
                    class="p-2.5 bg-form-input-light dark:bg-form-input-dark border border-form-border-light dark:border-form-border-dark rounded-lg text-txt-body-light dark:text-txt-body-dark transition-all duration-200 focus:ring-2 focus:ring-form-border-focus focus:border-form-border-focus"
                    required
                ></textarea>
            </div>

            <button
                type="submit"
                class="w-full py-2.5 bg-accent-light hover:bg-accent-light-hover active:bg-accent-light-active dark:bg-accent-dark dark:hover:bg-accent-dark-hover dark:active:bg-accent-dark-active rounded-lg text-txt-body-dark font-medium transition-colors duration-200 focus:ring-2 focus:ring-offset-2 focus:ring-accent-light"
            >
                {$t.contact.send}
            </button>
        </form>
    </div>
</div>
