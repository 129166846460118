import { beijing } from './asia/china/beijing';
import { shanghai } from './asia/china/shanghai';
import { shenzhen } from './asia/china/shenzhen';

import { delhi } from './asia/india/delhi';
import { mumbai } from './asia/india/mumbai';
import { bangalore } from './asia/india/bangalore';

import { tokyo } from './asia/japan/tokyo';
import { kyoto } from './asia/japan/kyoto';
import { osaka } from './asia/japan/osaka';

import { seoul } from './asia/southkorea/seoul';
import { busan } from './asia/southkorea/busan';
import { incheon } from './asia/southkorea/incheon';

import { paris } from './europe/france/paris';
import { lyon } from './europe/france/lyon';
import { marseille } from './europe/france/marseille';

import { berlin } from './europe/germany/berlin';
import { hamburg } from './europe/germany/hamburg';
import { munich } from './europe/germany/munich';

import { rome } from './europe/italy/rome';
import { milan } from './europe/italy/milan';
import { naples } from './europe/italy/naples';

import { moscow } from './europe/russia/moscow';
import { saintPetersburg } from './europe/russia/stpetersburgh';
import { novosibirsk } from './europe/russia/novosibirsk';
import { vladivostok } from './europe/russia/vladivostok';

import { london } from './europe/uk/london';
import { manchester } from './europe/uk/manchester';
import { birmingham } from './europe/uk/birmingham';

import { montreal } from './northAmerica/canada/montreal';
import { toronto } from './northAmerica/canada/toronto';
import { vancouver } from './northAmerica/canada/vancouver';

import { chicago } from './northAmerica/usa/chicago';
import { losangeles } from './northAmerica/usa/losangeles';
import { newyork } from './northAmerica/usa/newyork';

import { saopaolo } from './southAmerica/brazil/saopaolo';
import { riodejaneiro } from './southAmerica/brazil/riodejaneiro';
import { brasilia } from './southAmerica/brazil/brasilia';

import { brisbane } from './oceania/australia/brisbane';
import { melbourne } from './oceania/australia/melbourne';
import { sydney } from './oceania/australia/sydney';

// Define supported languages
type SupportedLanguage = 'en' | 'ru';

// Define interfaces for translatable fields
export interface TranslatedText extends Partial<Record<SupportedLanguage, string>> {
    en: string; // English is required as fallback
    ru?: string;
}

export interface TranslatedStringArray extends Partial<Record<SupportedLanguage, string[]>> {
    en: string[]; // English is required as fallback
    ru?: string[];
}

// Define the interface for city data structure
export interface City {
    name: TranslatedText;
    country: TranslatedText;
    image: string;
    population: TranslatedText;
    populationMillion: number;
    description: TranslatedText;
    founded: TranslatedText;
    landmarks: TranslatedStringArray;
    universities: TranslatedStringArray;
    industries: TranslatedStringArray;
    climate: TranslatedText;
    transportation: TranslatedStringArray;
    timeZone: string;
    languages: TranslatedStringArray;
    airports: TranslatedStringArray;
    isCapital: boolean;
    coordinates: {
        latitude: number;
        longitude: number;
    };
}

export const cities: City[] = [
    // Asian cities
    tokyo,
    kyoto,
    osaka,
    seoul,
    busan,
    incheon,
    beijing,
    shanghai,
    shenzhen,
    delhi,
    mumbai,
    bangalore,

    // European cities
    london,
    paris,
    lyon,
    marseille,
    berlin,
    hamburg,
    munich,
    moscow,
    rome,
    milan,
    naples,
    saintPetersburg,
    novosibirsk,
    vladivostok,
    manchester,
    birmingham,
    montreal,
    toronto,
    vancouver,
    chicago,
    losangeles,
    newyork,
    saopaolo,
    riodejaneiro,
    brasilia,
    brisbane,
    melbourne,
    sydney,
];
