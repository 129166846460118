import type { City } from '../../cities';

export const naples: City = {
    name: {
        "en": "Naples",
        "ru": "Неаполь"
    },
    country: {
        "en": "Italy",
        "ru": "Италия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.routard.com%2Fimage%2F71%2F4%2Fnaples.1486714.jpg&f=1&nofb=1&ipt=3dd7b28681a0533a75be585287af5a60f7da1633dd8c4fcf8a2663c171121999&ipo=images",
    populationMillion: 3.1,
    population: {
        "en": "3.1 million",
        "ru": "3,1 млн"
    },
    description: {
        "en": "Naples is one of Italy's largest cities, known for its historic center, vibrant culture, and being the birthplace of pizza.",
        "ru": "Неаполь - один из крупнейших городов Италии, известный своим историческим центром, яркой культурой и тем, что является родиной пиццы."
    },
    founded: {
        "en": "600 BC",
        "ru": "600 до н.э."
    },
    landmarks: {
        "en": ["Pompeii", "Mount Vesuvius", "Royal Palace", "Naples Cathedral"],
        "ru": ["Помпеи", "Везувий", "Королевский дворец", "Неаполитанский собор"]
    },
    universities: {
        "en": ["University of Naples Federico II", "L'Orientale University", "Parthenope University"],
        "ru": ["Неаполитанский университет имени Федерико II", "Университет L'Orientale", "Университет Партенопе"]
    },
    industries: {
        "en": ["Tourism", "Manufacturing", "Shipping", "Food Production"],
        "ru": ["Туризм", "Производство", "Судоходство", "Пищевая промышленность"]
    },
    climate: {
        "en": "Mediterranean climate with hot summers and mild winters",
        "ru": "Средиземноморский климат с жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Funicular", "Ferry"],
        "ru": ["Метро", "Автобус", "Фуникулер", "Паром"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["Italian", "Neapolitan"],
        "ru": ["Итальянский", "Неаполитанский"]
    },
    airports: {
        "en": ["Naples International Airport"],
        "ru": ["Международный аэропорт Неаполя"]
    },
    isCapital: false,
    coordinates: {
        latitude: 40.8518,
        longitude: 14.2681
    }
};
