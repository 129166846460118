import type { City } from '../../cities';

export const rome: City = {
    name: {
        "en": "Rome",
        "ru": "Рим"
    },
    country: {
        "en": "Italy",
        "ru": "Италия"
    },
    image: "https://images.unsplash.com/photo-1552832230-c0197dd311b5",
    populationMillion: 4.3,
    population: {
        "en": "4.3 million",
        "ru": "4,3 млн"
    },
    description: {
        "en": "Rome is Italy's capital and largest city, known for its rich history spanning over two and a half millennia, art, architecture and cultural heritage.",
        "ru": "Рим - столица и крупнейший город Италии, известный своей богатой историей, охватывающей более двух с половиной тысячелетий, искусством, архитектурой и культурным наследием."
    },
    founded: {
        "en": "753 BC",
        "ru": "753 до н.э."
    },
    landmarks: {
        "en": ["Colosseum", "Vatican City", "Pantheon", "Trevi Fountain"],
        "ru": ["Колизей", "Ватикан", "Пантеон", "Фонтан Треви"]
    },
    universities: {
        "en": ["Sapienza University of Rome", "Roma Tre University", "LUISS University"],
        "ru": ["Римский университет Сапиенца", "Университет Рома Тре", "Университет ЛУИСС"]
    },
    industries: {
        "en": ["Tourism", "Finance", "Technology", "Fashion"],
        "ru": ["Туризм", "Финансы", "Технологии", "Мода"]
    },
    climate: {
        "en": "Mediterranean climate with hot summers and mild winters",
        "ru": "Средиземноморский климат с жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Tram", "Train"],
        "ru": ["Метро", "Автобус", "Трамвай", "Поезд"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["Italian", "English"],
        "ru": ["Итальянский", "Английский"]
    },
    airports: {
        "en": ["Leonardo da Vinci International Airport", "Rome Ciampino Airport"],
        "ru": ["Международный аэропорт имени Леонардо да Винчи", "Аэропорт Рим-Чампино"]
    },
    isCapital: true,
    coordinates: {
        latitude: 41.9028,
        longitude: 12.4964
    }
};
