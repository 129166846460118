import type { City } from '../../cities';

export const shenzhen: City = {
    name: {
        "en": "Shenzhen",
        "ru": "Шэньчжэнь"
    },
    country: {
        "en": "China",
        "ru": "Китай"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fqualityinspection.org%2Fwp-content%2Fuploads%2F2016%2F07%2FShenzhentheBestCityinChinaforManufacturing.jpg&f=1&nofb=1&ipt=10622d58b6db5d8a09e5b26021073ccd6630f7274c930455a2b50f10c5a7a280&ipo=images",
    populationMillion: 17.5,
    population: {
        "en": "17.5 million",
        "ru": "17,5 млн"
    },
    description: {
        "en": "Shenzhen is China's technology hub and innovation center, transformed from a fishing village to a modern metropolis in just 40 years.",
        "ru": "Шэньчжэнь - технологический и инновационный центр Китая, превратившийся из рыбацкой деревни в современный мегаполис всего за 40 лет."
    },
    founded: {
        "en": "1979",
        "ru": "1979"
    },
    landmarks: {
        "en": ["Window of the World", "Ping An Finance Center", "OCT Harbour", "Shenzhen Bay Park"],
        "ru": ["Окно в мир", "Финансовый центр Пинъань", "Гавань OCT", "Парк залива Шэньчжэнь"]
    },
    universities: {
        "en": ["Shenzhen University", "Southern University of Science and Technology", "Chinese University of Hong Kong, Shenzhen"],
        "ru": ["Университет Шэньчжэня", "Южный университет науки и технологии", "Китайский университет Гонконга, Шэньчжэнь"]
    },
    industries: {
        "en": ["Technology", "Electronics", "Manufacturing", "Finance"],
        "ru": ["Технологии", "Электроника", "Производство", "Финансы"]
    },
    climate: {
        "en": "Humid subtropical climate with mild winters and hot summers",
        "ru": "Влажный субтропический климат с мягкой зимой и жарким летом"
    },
    transportation: {
        "en": ["Metro", "Bus", "High-speed Rail", "Ferry"],
        "ru": ["Метро", "Автобус", "Скоростная железная дорога", "Паром"]
    },
    timeZone: "UTC+08:00",
    languages: {
        "en": ["Mandarin Chinese", "Cantonese", "English"],
        "ru": ["Китайский (мандарин)", "Кантонский", "Английский"]
    },
    airports: {
        "en": ["Shenzhen Bao'an International Airport"],
        "ru": ["Международный аэропорт Шэньчжэнь Баоань"]
    },
    isCapital: false,
    coordinates: {
        latitude: 22.5431,
        longitude: 114.0579
    }
};
