<script lang="ts">
    import { t, activeLanguage } from '../../../translations/translations';
    import { cities as citiesData } from './data/cities';
    import type { City } from './data/cities';
    import { Link } from 'svelte-routing';
    import { onMount } from 'svelte';

    // Get the city name from URL params
    let cityName: string;
    let city: City | undefined;

    onMount(() => {
        cityName = location.pathname.split("/").pop() || "";
        city = citiesData.find(c =>
            c.name.en.toLowerCase() === cityName?.toLowerCase()
        );
    });

    // Helper function to format numbers with commas
    function formatNumber(num: number): string {
        return num.toLocaleString();
    }
</script>

{#if city}
    <div class="container mx-auto px-4 py-8">
        <!-- Breadcrumb -->
        <nav class="flex mb-6 text-txt-muted-light dark:text-txt-muted-dark">
            <Link to="/countries" class="hover:text-txt-body-light dark:hover:text-txt-body-dark">
                {$t.countries.countries}
            </Link>
            <span class="mx-2">›</span>
            <Link to={`/countries/${city.country.en}`} class="hover:text-txt-body-light dark:hover:text-txt-body-dark">
                {city.country[$activeLanguage] || city.country.en}
            </Link>
            <span class="mx-2">›</span>
            <Link to="/cities" class="hover:text-txt-body-light dark:hover:text-txt-body-dark">
                {$t.cities.cities}
            </Link>
            <span class="mx-2">›</span>
            <span class="text-txt-body-light dark:text-txt-body-dark">
                {city.name[$activeLanguage] || city.name.en}
            </span>
        </nav>

        <!-- Hero section -->
        <div class="relative h-96 rounded-xl overflow-hidden mb-8">
            <img
                src={city.image}
                alt={city.name[$activeLanguage] || city.name.en}
                class="w-full h-full object-cover"
            />
            <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
                <div class="absolute bottom-0 left-0 p-8">
                    <div>
                        <h1 class="text-4xl font-bold text-white mb-2">
                            {city.name[$activeLanguage] || city.name.en}
                        </h1>
                        <div class="text-white/80">
                            {city.country[$activeLanguage] || city.country.en}
                            {#if city.isCapital}
                                • {$t.cities.capital}
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main content -->
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <!-- Key information -->
            <div class="lg:col-span-2 space-y-8">
                <!-- Basic info -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.information}</h2>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                                {$t.cities.cityDetails.population}
                            </p>
                            <p class="text-lg">
                                {formatNumber(city.populationMillion)} {$t.cities.filters.population}
                            </p>
                        </div>
                        <div>
                            <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                                {$t.cities.cityDetails.founded}
                            </p>
                            <p class="text-lg">{city.founded[$activeLanguage] || city.founded.en}</p>
                        </div>
                        <div>
                            <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                                {$t.cities.timeZone}
                            </p>
                            <p class="text-lg">{city.timeZone}</p>
                        </div>
                        <div>
                            <p class="text-txt-muted-light dark:text-txt-muted-dark mb-1">
                                {$t.cities.coordinates}
                            </p>
                            <p class="text-lg">
                                {city.coordinates.latitude.toFixed(2)}, {city.coordinates.longitude.toFixed(2)}
                            </p>
                        </div>
                    </div>
                </section>

                <!-- Description -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.description}</h2>
                    <p class="text-txt-body-light dark:text-txt-body-dark leading-relaxed">
                        {city.description[$activeLanguage] || city.description.en}
                    </p>
                </section>

                <!-- Climate -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.climate}</h2>
                    <p class="text-txt-body-light dark:text-txt-body-dark leading-relaxed">
                        {city.climate[$activeLanguage] || city.climate.en}
                    </p>
                </section>
            </div>

            <!-- Sidebar -->
            <div class="space-y-8">
                <!-- Languages -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.languages}</h2>
                    <div class="flex flex-wrap gap-2">
                        {#each (city.languages[$activeLanguage] || city.languages.en) as language}
                            <span class="px-3 py-1 bg-form-input-light dark:bg-form-input-dark rounded-full">
                                {language}
                            </span>
                        {/each}
                    </div>
                </section>

                <!-- Landmarks -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.landmarks}</h2>
                    <div class="space-y-2">
                        {#each (city.landmarks[$activeLanguage] || city.landmarks.en) as landmark}
                            <div class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg">
                                {landmark}
                            </div>
                        {/each}
                    </div>
                </section>

                <!-- Universities -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.universities}</h2>
                    <div class="space-y-2">
                        {#each (city.universities[$activeLanguage] || city.universities.en) as university}
                            <div class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg">
                                {university}
                            </div>
                        {/each}
                    </div>
                </section>

                <!-- Industries -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.industries}</h2>
                    <div class="space-y-2">
                        {#each (city.industries[$activeLanguage] || city.industries.en) as industry}
                            <div class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg">
                                {industry}
                            </div>
                        {/each}
                    </div>
                </section>

                <!-- Transportation -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.transportation}</h2>
                    <div class="space-y-2">
                        {#each (city.transportation[$activeLanguage] || city.transportation.en) as transport}
                            <div class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg">
                                {transport}
                            </div>
                        {/each}
                    </div>
                </section>

                <!-- Airports -->
                <section class="bg-box-light dark:bg-box-dark rounded-xl p-6">
                    <h2 class="text-2xl font-semibold mb-4">{$t.cities.cityDetails.airports}</h2>
                    <div class="space-y-2">
                        {#each (city.airports[$activeLanguage] || city.airports.en) as airport}
                            <div class="px-3 py-2 bg-form-input-light dark:bg-form-input-dark rounded-lg">
                                {airport}
                            </div>
                        {/each}
                    </div>
                </section>
            </div>
        </div>
    </div>
{:else}
    <div class="container mx-auto px-4 py-8">
        <p>{$t.cities.notFound}</p>
    </div>
{/if}