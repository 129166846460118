import type { City } from '../../cities';

export const saintPetersburg: City = {
    name: {
        "en": "Saint Petersburg",
        "ru": "Санкт-Петербург"
    },
    country: {
        "en": "Russia",
        "ru": "Россия"
    },
    image: "https://images.unsplash.com/photo-1556610961-2fecc5927173",
    populationMillion: 5.4,
    population: {
        "en": "5.4 million",
        "ru": "5,4 млн"
    },
    description: {
        "en": "Saint Petersburg is Russia's cultural capital and second-largest city. Known for its stunning architecture, art museums, and rich imperial history.",
        "ru": "Санкт-Петербург - культурная столица России и второй по величине город. Известен своей потрясающей архитектурой, художественными музеями и богатой имперской историей."
    },
    founded: {
        "en": "1703",
        "ru": "1703"
    },
    landmarks: {
        "en": ["Hermitage Museum", "Peter and Paul Fortress", "Church of the Savior on Blood", "Winter Palace"],
        "ru": ["Эрмитаж", "Петропавловская крепость", "Спас на Крови", "Зимний дворец"]
    },
    universities: {
        "en": ["Saint Petersburg State University", "ITMO University", "Peter the Great St.Petersburg Polytechnic University"],
        "ru": ["Санкт-Петербургский государственный университет", "Университет ИТМО", "Санкт-Петербургский политехнический университет"]
    },
    industries: {
        "en": ["Tourism", "Shipbuilding", "Technology", "Cultural Industries"],
        "ru": ["Туризм", "Судостроение", "Технологии", "Культурная индустрия"]
    },
    climate: {
        "en": "Humid continental climate with warm summers and cold winters",
        "ru": "Влажный континентальный климат с теплым летом и холодной зимой"
    },
    transportation: {
        "en": ["Metro", "Bus", "Tram", "River Transport"],
        "ru": ["Метро", "Автобус", "Трамвай", "Речной транспорт"]
    },
    timeZone: "UTC+03:00",
    languages: {
        "en": ["Russian", "English"],
        "ru": ["Русский", "Английский"]
    },
    airports: {
        "en": ["Pulkovo International Airport"],
        "ru": ["Международный аэропорт Пулково"]
    },
    isCapital: false,
    coordinates: {
        latitude: 59.9375,
        longitude: 30.3086
    }
};
