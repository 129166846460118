import type { Country } from '../countries';

export const germany: Country = {
    name: {
        "en": "Germany",
        "ru": "Германия"
    },
    image: "https://images.unsplash.com/photo-1467269204594-9661b134dd2b",
    gdpBillion: 4220,
    gdp: {
        "en": "4,220 billion USD",
        "ru": "4,220 млрд долл. США"
    },
    currency: {
        "en": "Euro (€)",
        "ru": "Евро (€)"
    },
    topCities: {
        "en": ["Berlin", "Munich", "Hamburg"],
        "ru": ["Берлин", "Мюнхен", "Гамбург"]
    },
    populationMillion: 83.2,
    population: {
        "en": "83.2 million",
        "ru": "83,2 млн"
    },
    capital: {
        "en": "Berlin",
        "ru": "Берлин"
    },
    languages: {
        "en": ["German"],
        "ru": ["Немецкий"]
    },
    continent: {
        "en": "Europe",
        "ru": "Европа"
    },
    timeZones: ["UTC+01:00"],
    drivingSide: "left",
    countryCode: {
        alpha2: "DE",
        alpha3: "DEU"
    },
    government: {
        "en": "Federal parliamentary republic",
        "ru": "Федеративная парламентская республика"
    },
    areaTotal: 357386,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Temperate", "Oceanic"],
        "ru": ["Умеренный", "Океанический"]
    },
    landmarks: {
        "en": ["Brandenburg Gate", "Neuschwanstein Castle", "Ludwig Maximilian Bridge"],
        "ru": ["Бранденбургская ворота", "Замок Нойшванштайн", "Мост Людвига Максимилиана"]
    },
    internetTLD: ".de",
    phoneCode: "+49",
    majorCompanies: {
        "en": ["Volkswagen", "Siemens", "BMW", "Deutsche Bank", "SAP"],
        "ru": ["Фольксваген", "Сименс", "BMW", "Deutsche Bank", "SAP"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "Germany is a country in Western Europe with the North Sea, the Baltic Sea, and the Alps.",
        "ru": "Германия - страна в Западной Европе с Северным морем, Балтийским морем и Альпами."
    },
    history: {
        "en": "Germany's history spans over 1,000 years, with notable dynasties such as the House of Welf and the House of Hohenstaufen.",
        "ru": "История Германии насчитывает более 1000 лет, с заметными династиями, такими как Дом Вельфов и Дом Гогенштауфенов."
    },
    culture: {
        "en": "German culture is rich and diverse, with influences from Christianity, Roman Catholicism, and Protestantism, known for its unique art forms and cuisine.",
        "ru": "Немецкая культура богата и разнообразна, с влиянием христианства, римско-католичества и протестантизма, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "Germany offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Германия предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};