<script lang="ts">
    import { onMount } from "svelte";
    import { messages } from "../../../stores/dialogStore";
    import AIMessage from "./MsgThem.svelte";
    import UserMessage from "./MsgUs.svelte";
    import ChatInput from "./ChatInput.svelte";
    import { t } from "../../../translations/translations";
    import { auth } from "../../../stores/authStore";
    // Define a type for the message role
    type MessageRole = "user" | "assistant";

    // Update the message type definition
    interface Message {
        id: string;
        role: MessageRole;
        content: string;
    }

    let isLoading = false;

    // Add message deletion handler
    function handleMessageDeletion(messageId: string) {
        messages.update((msgs) => msgs.filter((msg) => msg.id !== messageId));
    }
</script>

<!-- Chat Input Section -->

<div class="flex flex-col h-[calc(100vh-45px)] w-full overflow-hidden">
    <div class="flex-1 w-full max-w-readable mx-auto px-4 mt-8 overflow-y-auto">
        <div class="max-w-2xl mx-auto">
            {#each $messages as message}
                {#if message.role === "user"}
                    <UserMessage
                        content={message.content}
                        onDelete={() => handleMessageDeletion(message.id)}
                    />
                {:else}
                    <AIMessage
                        content={message.content}
                        onDelete={() => handleMessageDeletion(message.id)}
                    />
                {/if}
            {/each}
        </div>

        {#if isLoading}
            <div class="flex justify-center items-center p-4">
                <div class="flex space-x-2">
                    <div
                        class="w-2 h-2 bg-accent-light dark:bg-accent-dark rounded-full animate-[bounce_1s_infinite_0ms]"
                    ></div>
                    <div
                        class="w-2 h-2 bg-accent-light dark:bg-accent-dark rounded-full animate-[bounce_1s_infinite_200ms]"
                    ></div>
                    <div
                        class="w-2 h-2 bg-accent-light dark:bg-accent-dark rounded-full animate-[bounce_1s_infinite_400ms]"
                    ></div>
                </div>
            </div>
        {/if}
    </div>

    <div class="w-full mt-auto">
        <ChatInput bind:isLoading />
    </div>
</div>
