import type { City } from '../../cities';

export const munich: City = {
    name: {
        "en": "Munich",
        "ru": "Мюнхен"
    },
    country: {
        "en": "Germany",
        "ru": "Германия"
    },
    image: "https://images.unsplash.com/photo-1599982890963-3aabd60064d2",
    populationMillion: 1.5,
    population: {
        "en": "1.5 million",
        "ru": "1,5 млн"
    },
    description: {
        "en": "Munich is Bavaria's capital and Germany's third-largest city, known for its rich culture, Oktoberfest, and high quality of life.",
        "ru": "Мюнхен - столица Баварии и третий по величине город Германии, известный своей богатой культурой, Октоберфестом и высоким качеством жизни."
    },
    founded: {
        "en": "1158",
        "ru": "1158"
    },
    landmarks: {
        "en": ["Marienplatz", "Frauenkirche", "English Garden", "Nymphenburg Palace"],
        "ru": ["Мариенплац", "Фрауэнкирхе", "Английский сад", "Дворец Нимфенбург"]
    },
    universities: {
        "en": ["Technical University of Munich", "Ludwig Maximilian University", "Munich University of Applied Sciences"],
        "ru": ["Технический университет Мюнхена", "Мюнхенский университет Людвига-Максимилиана", "Мюнхенский университет прикладных наук"]
    },
    industries: {
        "en": ["Technology", "Automotive", "Media", "Finance"],
        "ru": ["Технологии", "Автомобилестроение", "Медиа", "Финансы"]
    },
    climate: {
        "en": "Oceanic climate with warm summers and cool winters",
        "ru": "Океанический климат с теплым летом и прохладной зимой"
    },
    transportation: {
        "en": ["U-Bahn", "S-Bahn", "Tram", "Bus"],
        "ru": ["Метро", "Городская электричка", "Трамвай", "Автобус"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["German", "English"],
        "ru": ["Немецкий", "Английский"]
    },
    airports: {
        "en": ["Munich International Airport"],
        "ru": ["Международный аэропорт Мюнхена"]
    },
    isCapital: false,
    coordinates: {
        latitude: 48.1351,
        longitude: 11.5820
    }
};
