import type { City } from '../../cities';

export const hamburg: City = {
    name: {
        "en": "Hamburg",
        "ru": "Гамбург"
    },
    country: {
        "en": "Germany",
        "ru": "Германия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.independent.co.uk%2Fs3fs-public%2Fthumbnails%2Fimage%2F2018%2F05%2F22%2F14%2Fhamburg-main.jpg%3Fquality%3D75%26width%3D1200%26auto%3Dwebp&f=1&nofb=1&ipt=c59f47e87c6ebbf852d0e9dd2bf8e2f682249522ccaeb4a60235ff5cd1d1b3fb&ipo=images",
    populationMillion: 1.8,
    population: {
        "en": "1.8 million",
        "ru": "1,8 млн"
    },
    description: {
        "en": "Hamburg is Germany's second-largest city and biggest port, known for its maritime heritage, cultural scene, and architectural landmarks.",
        "ru": "Гамбург - второй по величине город Германии и крупнейший порт, известный своим морским наследием, культурной жизнью и архитектурными достопримечательностями."
    },
    founded: {
        "en": "808",
        "ru": "808"
    },
    landmarks: {
        "en": ["Elbphilharmonie", "Speicherstadt", "Miniatur Wunderland", "St. Michael's Church"],
        "ru": ["Эльбская филармония", "Шпайхерштадт", "Миниатюр-Вундерланд", "Церковь Святого Михаила"]
    },
    universities: {
        "en": ["University of Hamburg", "Hamburg University of Technology", "HafenCity University"],
        "ru": ["Гамбургский университет", "Гамбургский технический университет", "Университет ХафенСити"]
    },
    industries: {
        "en": ["Maritime Trade", "Media", "Aviation", "Technology"],
        "ru": ["Морская торговля", "Медиа", "Авиация", "Технологии"]
    },
    climate: {
        "en": "Oceanic climate with warm summers and mild winters",
        "ru": "Океанический климат с теплым летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "S-Bahn", "Bus", "Ferry"],
        "ru": ["Метро", "С-Бан", "Автобус", "Паром"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["German", "English"],
        "ru": ["Немецкий", "Английский"]
    },
    airports: {
        "en": ["Hamburg Airport"],
        "ru": ["Аэропорт Гамбурга"]
    },
    isCapital: false,
    coordinates: {
        latitude: 53.5511,
        longitude: 9.9937
    }
};
