import type { Country } from '../countries';

export const japan: Country = {
  name: {
    "en": "Japan",
    "ru": "Япония"
  },
  image: "https://images.unsplash.com/photo-1542640244-7e672d6cef4e",
  gdpBillion: 4910,
  gdp: {
    "en": "4,910 billion USD",
    "ru": "4,910 млрд долл. США"
  },
  currency: {
    "en": "Japanese Yen (¥)",
    "ru": "Японская иена (¥)"
  },
  topCities: {
    "en": ["Tokyo", "Osaka", "Kyoto"],
    "ru": ["Токио", "Осака", "Киото"]
  },
  populationMillion: 125.7,
  population: {
    "en": "125.7 million",
    "ru": "125,7 млн"
  },
  capital: {
    "en": "Tokyo",
    "ru": "Токио"
  },
  languages: {
    "en": ["Japanese"],
    "ru": ["Японский"]
  },
  continent: {
    "en": "Asia",
    "ru": "Азия"
  },
  timeZones: ["UTC+09:00"],
  drivingSide: "left",
  countryCode: {
    alpha2: "JP",
    alpha3: "JPN"
  },
  government: {
    "en": "Unitary parliamentary constitutional monarchy",
    "ru": "Унитарная парламентская конституционная монархия"
  },
  areaTotal: 377975,
  areaUnit: {
    "en": "km²",
    "ru": "км²"
  },
  climate: {
    "en": ["Temperate", "Subtropical"],
    "ru": ["Умеренный", "Субтропический"]
  },
  landmarks: {
    "en": ["Mount Fuji", "Osaka Castle", "Fushimi Inari Shrine"],
    "ru": ["Гора Фудзи", "Замок Осака", "Храм Фусими Инари"]
  },
  internetTLD: ".jp",
  phoneCode: "+81",
  majorCompanies: {
    "en": ["Toyota", "Sony", "Nintendo", "SoftBank", "Honda"],
    "ru": ["Тойота", "Сони", "Нинтендо", "СофтБанк", "Хонда"]
  },
  majorIndustries: {
    "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
    "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
  },
  geography: {
    "en": "Japan is an archipelago of islands, with the main island of Honshu, Hokkaido, Shikoku, and Kyushu.",
    "ru": "Япония - архипелаг островов, с главным островом Хонсю, Хоккайдо, Сикоку и Кюшу."
  },
  history: {
    "en": "Japan's history spans over 2,000 years, with notable dynasties such as the Yamato, Heian, and Tokugawa.",
    "ru": "История Японии насчитывает более 2000 лет, с заметными династиями, такими как Yamato, Хеян и Токугава."
  },
  culture: {
    "en": "Japanese culture is rich and diverse, with influences from Buddhism, Shinto, and Confucianism, known for its unique art forms and cuisine.",
    "ru": "Японская культура богата и разнообразна, с влиянием буддизма, шинто и конфуцианства, известна своей уникальной художественной сцене и кухней."
  },
  travel: {
    "en": "Japan offers a wide range of travel experiences from ancient historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
    "ru": "Япония предлагает широкий спектр путешествий от древних исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
  }
};