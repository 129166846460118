import { writable } from 'svelte/store';
import { loginUser, logoutUser, checkAuthStatus } from '../api/auth';
import { navigate } from 'svelte-routing';
import { logger } from '../utils/logger';

// Defines the structure for user information
interface UserInfo {
  id: string;
  username: string;
  email: string;
}

// Represents the complete state of the auth store
interface AuthState {
  userInfo: UserInfo | null;
  isLoggedIn: boolean;
  isLoading: boolean;
}

const createAuthStore = () => {
  logger.info('Initializing auth store');
  const { subscribe, set, update } = writable<AuthState>({
    userInfo: null,
    isLoggedIn: false,
    isLoading: false
  });

  return {
    subscribe,

    /**
     * Updates store with user data
     */
    setUser: (userData: UserInfo) => {
      logger.info('Attempting to set user data', { username: userData.username, email: userData.email });
      try {
        if (!userData.username || !userData.email) {
          logger.error('Invalid user data provided', userData);
          throw new Error('Invalid user data: missing required fields');
        }
        update(state => ({
          ...state,
          userInfo: userData,
          isLoggedIn: true,
          isLoading: false
        }));
        logger.info('User data successfully set', { username: userData.username });
      } catch (error) {
        logger.error('Failed to set user data', { error, userData });
        throw error;
      }
    },

    /**
     * Clears user data from store
     */
    clearUser: () => {
      set({ userInfo: null, isLoggedIn: false, isLoading: false });
    },

    /**
     * Handles login and updates store
     * Cookie management is handled by the server
     */
    login: async (email: string, password: string) => {
      logger.info('Attempting login', { email });
      update(state => ({ ...state, isLoading: true }));

      try {
        const response = await loginUser(email, password);

        if (response.error) {
          throw new Error(response.error);
        }

        const userData = {
          id: response.data?.id?.toString() || '',
          username: response.data?.username,
          email: response.data?.email
        };

        // Ensure all required fields are present before setting user
        if (!response.data?.username || !response.data?.email) {
          throw new Error('Invalid user data received from server');
        }

        const validatedUserData: UserInfo = {
          id: response.data.id?.toString() || '',
          username: response.data.username,
          email: response.data.email
        };

        auth.setUser(validatedUserData);
        navigate("/status");
      } catch (error) {
        logger.error('Login failed', { error, email });
        auth.clearUser();
        throw new Error(`Login failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    },

    /**
     * Handles logout and cleans up store
     * Cookie cleanup is handled by the server
     */
    logout: async () => {
      logger.info('Attempting logout');
      update(state => ({ ...state, isLoading: true }));

      try {
        await logoutUser();
        logger.info('Logout successful');
      } catch (error) {
        logger.error('Logout error', { error });
        // Continue with cleanup even if server logout fails
      } finally {
        auth.clearUser();
        logger.info('Local auth state cleared');
        navigate("/");
      }
    },

    /**
     * Verifies authentication status with server
     * Relies on HttpOnly cookie being present
     */
    checkAuth: async () => {
      logger.debug('Checking authentication status');
      update(state => ({ ...state, isLoading: true }));

      try {
        const response = await checkAuthStatus();

        if (response.error) {
          throw new Error(response.error);
        }

        logger.debug('Received auth status response', { data: response.data });
        if (response.data?.username && response.data?.email) {
          auth.setUser({
            id: response.data.id?.toString() || '',
            username: response.data.username,
            email: response.data.email
          });
          logger.info('Authentication verified', { username: response.data.username });
          return true;
        }

        logger.info('User not authenticated');
        auth.clearUser();
        return false;
      } catch (error) {
        logger.error('Auth check failed', { error });
        auth.clearUser();
        return false;
      } finally {
        update(state => ({ ...state, isLoading: false }));
      }
    }
  };
};

export const auth = createAuthStore();