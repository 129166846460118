import { japan } from './asia/jpn';
import { france } from './europe/fra';
import { germany } from './europe/deu';
import { unitedKingdom } from './europe/gbr';
import { italy } from './europe/ita';
import { canada } from './northAmerica/can';
import { australia } from './oceania/aus';
import { southKorea } from './asia/kor';
import { brazil } from './southAmerica/bra';
import { india } from './asia/ind';
import { china } from './asia/chn';
import { singapore } from './asia/sgp';
import { russia } from './europe/rus';
import { unitedStates } from './northAmerica/usa';

// Define supported languages
type SupportedLanguage = 'en' | 'ru'; // Add other supported languages as needed

// Define interfaces for translatable fields
export interface TranslatedText extends Partial<Record<SupportedLanguage, string>> {
    en: string; // English is required as fallback
    ru?: string;
}

export interface TranslatedStringArray extends Partial<Record<SupportedLanguage, string[]>> {
    en: string[]; // English is required as fallback
    ru?: string[];
}

// Define the interface for country data structure
export interface Country {
    name: TranslatedText;
    image: string;
    gdpBillion: number;
    gdp: TranslatedText;
    currency: TranslatedText;
    topCities: TranslatedStringArray;
    populationMillion: number;
    population: TranslatedText;
    capital: TranslatedText;
    languages: TranslatedStringArray;
    continent: TranslatedText;
    timeZones: string[];
    drivingSide: 'left' | 'right';
    countryCode: {
        alpha2: string;  // ISO 3166-1 alpha-2 (e.g., 'JP')
        alpha3: string;  // ISO 3166-1 alpha-3 (e.g., 'JPN')
    };
    government: TranslatedText;
    areaTotal: number;
    areaUnit: TranslatedText;
    climate: TranslatedStringArray;
    landmarks: TranslatedStringArray;
    internetTLD: string; // Top Level Domain (e.g., '.jp')
    phoneCode: string;   // International calling code
    majorCompanies: TranslatedStringArray;  // Add this new field
    majorIndustries: TranslatedStringArray;  // Add this new field
    geography: TranslatedText;
    history: TranslatedText;
    culture: TranslatedText;
    travel: TranslatedText;
}

// Export the countries data
export const countries: Country[] = [
    japan,
    france,
    germany,
    unitedKingdom,
    italy,
    canada,
    australia,
    southKorea,
    brazil,
    india,
    china,
    singapore,
    russia,
    unitedStates,
];
