import type { Country } from '../countries';

export const canada: Country = {
    name: {
        "en": "Canada",
        "ru": "Канада"
    },
    image: "https://images.unsplash.com/photo-1503614472-8c93d56e92ce",
    gdpBillion: 1990,
    gdp: {
        "en": "1,990 billion USD",
        "ru": "1,990 млрд долл. США"
    },
    currency: {
        "en": "Canadian Dollar ($)",
        "ru": "Канадский доллар ($)"
    },
    topCities: {
        "en": ["Toronto", "Vancouver", "Montreal"],
        "ru": ["Торонто", "Ванкувер", "Монреаль"]
    },
    populationMillion: 38.2,
    population: {
        "en": "38.2 million",
        "ru": "38,2 млн"
    },
    capital: {
        "en": "Ottawa",
        "ru": "Оттава"
    },
    languages: {
        "en": ["English", "French"],
        "ru": ["Английский", "Французский"]
    },
    continent: {
        "en": "North America",
        "ru": "Северная Америка"
    },
    timeZones: ["UTC-04:00", "UTC-05:00"],
    drivingSide: "left",
    countryCode: {
        alpha2: "CA",
        alpha3: "CAN"
    },
    government: {
        "en": "Federal parliamentary democracy",
        "ru": "Федеративная демократическая республика"
    },
    areaTotal: 9984670,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Humid continental", "Oceanic"],
        "ru": ["Влажный континентальный климат", "Океанический"]
    },
    landmarks: {
        "en": ["CN Tower", "Niagara Falls", "Banff National Park"],
        "ru": ["Башня CN", "Ниагара-Фолс", "Национальный парк Банф"]
    },
    internetTLD: ".ca",
    phoneCode: "+1",
    majorCompanies: {
        "en": ["Royal Bank of Canada", "TD Bank", "Shopify", "BCE", "Canadian National Railway"],
        "ru": ["Королевская банк Канады", "Банк ТД", "Shopify", "BCE", "Канадская национальная железная дорога"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "Canada is a country in North America with the Atlantic Ocean, the Pacific Ocean, and the Rocky Mountains.",
        "ru": "Канада - страна в Северной Америке с Атлантическим океаном, Тихим океаном и Скалистыми горами."
    },
    history: {
        "en": "Canada's history spans over 1,000 years, with notable dynasties such as the House of Wessex and the House of Normandy.",
        "ru": "История Канады насчитывает более 1000 лет, с заметными династиями, такими как Дом Уэссекс и Дом Нормандии."
    },
    culture: {
        "en": "Canadian culture is rich and diverse, with influences from Christianity, Roman Catholicism, and Protestantism, known for its unique art forms and cuisine.",
        "ru": "Канадская культура богата и разнообразна, с влиянием христианства, римско-католичества и протестантизма, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "Canada offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Канада предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};