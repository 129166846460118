import type { City } from '../../cities';

export const brasilia: City = {
    name: {
        "en": "Brasília",
        "ru": "Бразилиа"
    },
    country: {
        "en": "Brazil",
        "ru": "Бразилия"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.adsttc.com%2Fmedia%2Fimages%2F55e6%2F44e6%2F8450%2Fb5a3%2Fae00%2F0426%2Fnewsletter%2F1294250407-brasilia8.jpg%3F1441154274&f=1&nofb=1&ipt=2924446f076feae41006f469cf2bfd2a7c665d29395829c6733bc4f2dfc4c94c&ipo=images",
    populationMillion: 3.1,
    population: {
        "en": "3.1 million",
        "ru": "3,1 млн"
    },
    description: {
        "en": "Brasília is Brazil's capital and a planned city known for its unique modernist architecture. It serves as the seat of the federal government.",
        "ru": "Бразилиа - столица Бразилии и спланированный город, известный своей уникальной модернистской архитектурой. Является местом расположения федерального правительства."
    },
    founded: {
        "en": "1960",
        "ru": "1960"
    },
    landmarks: {
        "en": ["Cathedral of Brasília", "National Congress", "Three Powers Plaza", "Presidential Palace"],
        "ru": ["Кафедральный собор Бразилиа", "Национальный конгресс", "Площадь трёх властей", "Президентский дворец"]
    },
    universities: {
        "en": ["University of Brasília", "Catholic University of Brasília", "UniCEUB"],
        "ru": ["Университет Бразилиа", "Католический университет Бразилиа", "УниСЕУБ"]
    },
    industries: {
        "en": ["Government", "Public Services", "Information Technology", "Construction"],
        "ru": ["Государственное управление", "Государственные услуги", "Информационные технологии", "Строительство"]
    },
    climate: {
        "en": "Tropical savanna climate with dry winters and rainy summers",
        "ru": "Тропический саванный климат с сухой зимой и дождливым летом"
    },
    transportation: {
        "en": ["Bus", "Metro", "Taxi", "Airport Shuttle"],
        "ru": ["Автобус", "Метро", "Такси", "Трансфер в аэропорт"]
    },
    timeZone: "UTC-03:00",
    languages: {
        "en": ["Portuguese"],
        "ru": ["Португальский"]
    },
    airports: {
        "en": ["Presidente Juscelino Kubitschek International Airport"],
        "ru": ["Международный аэропорт имени Жуселину Кубичека"]
    },
    isCapital: true,
    coordinates: {
        latitude: -15.7975,
        longitude: -47.8919
    }
};
