import { type LanguageCode } from "./translations";

// Define the type for register translations
export type RegisterTranslation = {
    createAccount: string;
    email: string;
    password: string;
    confirmPassword: string;
    register: string;
    signInInstead: string;
    continueWith: string;
    registrationFailed: string;
    tryAgain: string;
};

// Export register translations for each language
export const registerTranslations: Record<LanguageCode, RegisterTranslation> = {
    en: {
        createAccount: "Create Account",
        email: "Email",
        password: "Password",
        confirmPassword: "Confirm Password",
        register: "Register",
        signInInstead: "Sign in instead",
        continueWith: "or continue with",
        registrationFailed: "Registration failed",
        tryAgain: "Please try again."
    },
    ru: {
        createAccount: "Создать аккаунт",
        email: "Электронная почта",
        password: "Пароль",
        confirmPassword: "Подтвердите пароль",
        register: "Зарегистрироваться",
        signInInstead: "Войти вместо этого",
        continueWith: "или продолжить с помощью",
        registrationFailed: "Ошибка регистрации",
        tryAgain: "Пожалуйста, попробуйте снова."
    },
};
