import type { Country } from '../countries';

export const brazil: Country = {
    name: {
        "en": "Brazil",
        "ru": "Бразилия"
    },
    image: "https://images.unsplash.com/photo-1483729558449-99ef09a8c325",
    gdpBillion: 1610,
    gdp: {
        "en": "1,610 billion USD",
        "ru": "1,610 млрд долл. США"
    },
    currency: {
        "en": "Brazilian Real (R$)",
        "ru": "Бразильский реал (R$)"
    },
    topCities: {
        "en": ["São Paulo", "Rio de Janeiro", "Brasília"],
        "ru": ["Сан-Паулу", "Рио-де-Жанейро", "Бразилиа"]
    },
    populationMillion: 214.3,
    population: {
        "en": "214.3 million",
        "ru": "214,3 млн"
    },
    capital: {
        "en": "Brasília",
        "ru": "Бразилиа"
    },
    languages: {
        "en": ["Portuguese"],
        "ru": ["Португальский"]
    },
    continent: {
        "en": "South America",
        "ru": "Южная Америка"
    },
    timeZones: ["UTC-03:00"],
    drivingSide: "right",
    countryCode: {
        alpha2: "BR",
        alpha3: "BRA"
    },
    government: {
        "en": "Federal presidential republic",
        "ru": "Федеративная президентская республика"
    },
    areaTotal: 8515767,
    areaUnit: {
        "en": "km²",
        "ru": "км²"
    },
    climate: {
        "en": ["Tropical", "Humid subtropical"],
        "ru": ["Тропический", "Влажный субтропический климат"]
    },
    landmarks: {
        "en": ["Christ the Redeemer", "Sugarloaf Mountain", "Iguaçu Falls"],
        "ru": ["Христа Собора", "Гора Саугароф", "Игуашу-Фолс"]
    },
    internetTLD: ".br",
    phoneCode: "+55",
    majorCompanies: {
        "en": ["Petrobras", "Vale", "Itaú Unibanco", "Bradesco", "Ambev"],
        "ru": ["Petrobras", "Vale", "Itaú Unibanco", "Bradesco", "Ambev"]
    },
    majorIndustries: {
        "en": ["Mining", "Manufacturing", "Financial Services", "Technology", "Tourism"],
        "ru": ["Горнодобывающая", "Производство", "Финансовые услуги", "Технологии", "Туризм"]
    },
    geography: {
        "en": "Brazil is a country in South America with the Atlantic Ocean, the Amazon River, and the Pantanal wetlands.",
        "ru": "Бразилия - страна в Южной Америке с Атлантическим океаном, Амазонкой и Пантаналом."
    },
    history: {
        "en": "Brazil's history spans over 1,000 years, with notable dynasties such as the House of Braganza and the House of Habsburg.",
        "ru": "История Бразилии насчитывает более 1000 лет, с заметными династиями, такими как Дом Бранка и Дом Габсбург."
    },
    culture: {
        "en": "Brazilian culture is rich and diverse, with influences from Portuguese, African, and Indigenous cultures, known for its unique art forms and cuisine.",
        "ru": "Бразильская культура богата и разнообразна, с влиянием португальцев, африканцев и коренных народов, известна своей уникальной художественной сцене и кухней."
    },
    travel: {
        "en": "Brazil offers a wide range of travel experiences from historical sites to modern urban landscapes, with excellent tourism infrastructure and unique cultural experiences.",
        "ru": "Бразилия предлагает широкий спектр путешествий от исторических объектов до современных городских ландшафтов, с отличной туристической инфраструктурой и уникальными культурными впечатлениями."
    }
};