import type { City } from '../../cities';

export const toronto: City = {
    name: {
        "en": "Toronto",
        "ru": "Торонто"
    },
    country: {
        "en": "Canada",
        "ru": "Канада"
    },
    image: "https://images.unsplash.com/photo-1517090504586-fde19ea6066f",
    populationMillion: 6.3,
    population: {
        "en": "6.3 million",
        "ru": "6,3 млн"
    },
    description: {
        "en": "Toronto is Canada's largest city and a global center for business, finance, arts and culture. It is one of the most multicultural cities in the world.",
        "ru": "Торонто - крупнейший город Канады и мировой центр бизнеса, финансов, искусства и культуры. Это один из самых мультикультурных городов в мире."
    },
    founded: {
        "en": "1793",
        "ru": "1793"
    },
    landmarks: {
        "en": ["CN Tower", "Royal Ontario Museum", "Casa Loma", "Art Gallery of Ontario"],
        "ru": ["Си-Эн Тауэр", "Королевский музей Онтарио", "Каса Лома", "Художественная галерея Онтарио"]
    },
    universities: {
        "en": ["University of Toronto", "York University", "Ryerson University"],
        "ru": ["Университет Торонто", "Йоркский университет", "Университет Райерсона"]
    },
    industries: {
        "en": ["Finance", "Technology", "Media", "Tourism", "Film Production"],
        "ru": ["Финансы", "Технологии", "Медиа", "Туризм", "Кинопроизводство"]
    },
    climate: {
        "en": "Humid continental climate with warm summers and cold winters",
        "ru": "Влажный континентальный климат с теплым летом и холодной зимой"
    },
    transportation: {
        "en": ["Subway", "Streetcar", "Bus", "GO Transit"],
        "ru": ["Метро", "Трамвай", "Автобус", "GO Transit"]
    },
    timeZone: "UTC-05:00",
    languages: {
        "en": ["English", "French"],
        "ru": ["Английский", "Французский"]
    },
    airports: {
        "en": ["Toronto Pearson International Airport", "Billy Bishop Toronto City Airport"],
        "ru": ["Международный аэропорт Торонто Пирсон", "Городской аэропорт Билли Бишоп"]
    },
    isCapital: false,
    coordinates: {
        latitude: 43.6532,
        longitude: -79.3832
    }
};
