import type { City } from '../../cities';

export const brisbane: City = {
    name: {
        "en": "Brisbane",
        "ru": "Брисбен"
    },
    country: {
        "en": "Australia",
        "ru": "Австралия"
    },
    image: "https://images.unsplash.com/photo-1566734904496-9309bb1798ae",
    populationMillion: 2.5,
    population: {
        "en": "2.5 million",
        "ru": "2,5 млн"
    },
    description: {
        "en": "Brisbane is the capital of Queensland and third largest city in Australia. Known for its subtropical climate, outdoor lifestyle and cultural scene.",
        "ru": "Брисбен - столица Квинсленда и третий по величине город Австралии. Известен субтропическим климатом, активным образом жизни и культурной жизнью."
    },
    founded: {
        "en": "1824",
        "ru": "1824"
    },
    landmarks: {
        "en": ["Story Bridge", "South Bank Parklands", "Mount Coot-tha", "Queensland Cultural Centre"],
        "ru": ["Мост Стори", "Парки Саут-Бэнк", "Гора Кут-та", "Культурный центр Квинсленда"]
    },
    universities: {
        "en": ["University of Queensland", "Queensland University of Technology", "Griffith University"],
        "ru": ["Университет Квинсленда", "Технологический университет Квинсленда", "Университет Гриффита"]
    },
    industries: {
        "en": ["Information Technology", "Tourism", "Education", "Mining Services"],
        "ru": ["Информационные технологии", "Туризм", "Образование", "Горнодобывающие услуги"]
    },
    climate: {
        "en": "Humid subtropical climate with hot summers and mild winters",
        "ru": "Влажный субтропический климат с жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Train", "Bus", "Ferry", "CityCycle"],
        "ru": ["Поезд", "Автобус", "Паром", "Городской велопрокат"]
    },
    timeZone: "UTC+10:00",
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    airports: {
        "en": ["Brisbane Airport"],
        "ru": ["Аэропорт Брисбена"]
    },
    isCapital: false,
    coordinates: {
        latitude: -27.4698,
        longitude: 153.0251
    }
};
