import type { City } from '../../cities';

export const chicago: City = {
    name: {
        "en": "Chicago",
        "ru": "Чикаго"
    },
    country: {
        "en": "United States",
        "ru": "США"
    },
    image: "https://images.unsplash.com/photo-1494522855154-9297ac14b55f",
    populationMillion: 9.5,
    population: {
        "en": "9.5 million",
        "ru": "9,5 млн"
    },
    description: {
        "en": "Chicago is the third-largest city in the United States, known for its bold architecture, rich cultural scene, and importance as a global financial center.",
        "ru": "Чикаго - третий по величине город США, известный своей смелой архитектурой, богатой культурной жизнью и значимостью как мирового финансового центра."
    },
    founded: {
        "en": "1833",
        "ru": "1833"
    },
    landmarks: {
        "en": ["Willis Tower", "Millennium Park", "Navy Pier", "Cloud Gate"],
        "ru": ["Уиллис-тауэр", "Миллениум-парк", "Нэви-пир", "Облачные врата"]
    },
    universities: {
        "en": ["University of Chicago", "Northwestern University", "University of Illinois Chicago"],
        "ru": ["Чикагский университет", "Северо-Западный университет", "Иллинойский университет в Чикаго"]
    },
    industries: {
        "en": ["Finance", "Manufacturing", "Technology", "Transportation", "Tourism"],
        "ru": ["Финансы", "Производство", "Технологии", "Транспорт", "Туризм"]
    },
    climate: {
        "en": "Humid continental climate with hot summers and cold winters",
        "ru": "Влажный континентальный климат с жарким летом и холодной зимой"
    },
    transportation: {
        "en": ["L Train", "Bus", "Metra", "Water Taxi"],
        "ru": ["Надземка", "Автобус", "Метра", "Водное такси"]
    },
    timeZone: "UTC-06:00",
    languages: {
        "en": ["English", "Spanish"],
        "ru": ["Английский", "Испанский"]
    },
    airports: {
        "en": ["O'Hare International Airport", "Midway International Airport"],
        "ru": ["Международный аэропорт О'Хара", "Международный аэропорт Мидуэй"]
    },
    isCapital: false,
    coordinates: {
        latitude: 41.8781,
        longitude: -87.6298
    }
};
