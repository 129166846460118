<script lang="ts">
  import { onMount } from "svelte";
  import { getProtectedAPIData } from "../../../api/api";
  import { checkAuthStatus } from "../../../api/auth";
  import DataBox from "./StatusDataBox.svelte";
  import { t } from "../../../translations/translations";
  import { auth } from "../../../stores/authStore";

  let isLoggedIn = false;
  let authResponse = {};
  let apiResponse = {};

  auth.subscribe((value) => {
    const wasLoggedIn = isLoggedIn;
    isLoggedIn = value.isLoggedIn;
    if (isLoggedIn && !wasLoggedIn) {
      loadData();
    }
  });

  async function loadData() {
    try {
      const [authResp, apiResp] = await Promise.all([
        checkAuthStatus(),
        getProtectedAPIData()
      ]);
      authResponse = authResp.data || {};
      apiResponse = apiResp;
    } catch (error) {
      console.error('Error loading status data:', error);
    }
  }

  // onMount(() => {});
</script>

{#if isLoggedIn}
  <div
    id="status-grid-container"
    class="container mx-auto max-w-7xl px-4 my-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center"
  >
    <!-- Merged Status Box -->
    <DataBox title={$t.status.auth} data={{ status: $t.status.loggedIn, ...authResponse }} />

    <!-- API Response Box -->
    <DataBox title={$t.status.api} data={apiResponse} />
  </div>
{:else}
  <div
    id="status-logged-out-container"
    class="container mx-auto px-4 py-4 flex justify-center"
  >
    <DataBox title={$t.status.status} data={{ [$t.status.status]: $t.status.loggedOut }} />
  </div>
{/if}
