import { type LanguageCode } from "./translations";

export type AboutTranslation = {
    title: string;
    introduction: string;
    featuresTitle: string;
    feature1: string;
    feature2: string;
    feature3: string;
    technologyTitle: string;
    technology: string;
    conclusion: string;
};

export const aboutTranslations: Record<LanguageCode, AboutTranslation> = {
    en: {
        title: "About Shindo",
        introduction: "Welcome to a platform that embraces the way of the heart. We've created this space to bring more harmony, understanding, and positive energy to your daily life.",
        featuresTitle: "Our Vision",
        feature1: "Bridging cultural and linguistic boundaries through technology",
        feature2: "Creating a safe and harmonious space for personal growth",
        feature3: "Intuitive experience that adapts to your preferences",
        technologyTitle: "Our Foundation",
        technology: "Built with modern technologies including Svelte, TypeScript, and Go, ensuring a smooth and reliable experience.",
        conclusion: "We're on a journey to create something meaningful that brings more light and joy to people's lives worldwide."
    },
    ru: {
        title: "О Синдо",
        introduction: "Добро пожаловать на платформу, которая следует пути сердца. Мы создали это пространство, чтобы привнести больше гармонии, понимания и позитивной энергии в вашу повседневную жизнь.",
        featuresTitle: "Наше видение",
        feature1: "Преодоление культурных и языковых барьеров с помощью технологий",
        feature2: "Создание безопасного и гармоничного пространства для личностного роста",
        feature3: "Интуитивный опыт, адаптирующийся под ваши предпочтения",
        technologyTitle: "Наша основа",
        technology: "Построен на современных технологиях, включая Svelte, TypeScript и Go, обеспечивая плавную и надёжную работу.",
        conclusion: "Мы находимся в пути создания чего-то значимого, что принесёт больше света и радости в жизни людей по всему миру."
    },
};