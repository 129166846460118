import type { City } from '../../cities';

export const tokyo: City = {
    name: {
        "en": "Tokyo",
        "ru": "Токио"
    },
    country: {
        "en": "Japan",
        "ru": "Япония"
    },
    image: "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf",
    populationMillion: 37.4,
    population: {
        "en": "37.4 million",
        "ru": "37,4 млн"
    },
    description: {
        "en": "Tokyo is Japan's capital and the world's largest metropolitan economy. It combines ultramodern technology and traditional culture.",
        "ru": "Токио - столица Японии и крупнейшая городская экономика мира. Город сочетает ультрасовременные технологии и традиционную культуру."
    },
    founded: {
        "en": "1457",
        "ru": "1457"
    },
    landmarks: {
        "en": ["Tokyo Skytree", "Senso-ji Temple", "Shibuya Crossing", "Imperial Palace"],
        "ru": ["Токийское небесное дерево", "Храм Сэнсо-дзи", "Перекресток Сибуя", "Императорский дворец"]
    },
    universities: {
        "en": ["University of Tokyo", "Waseda University", "Keio University"],
        "ru": ["Токийский университет", "Университет Васэда", "Университет Кэйо"]
    },
    industries: {
        "en": ["Technology", "Finance", "Media", "Manufacturing"],
        "ru": ["Технологии", "Финансы", "Медиа", "Производство"]
    },
    climate: {
        "en": "Humid subtropical climate with hot summers and mild winters",
        "ru": "Влажный субтропический климат с жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "JR Lines", "Bus", "Monorail"],
        "ru": ["Метро", "Линии JR", "Автобус", "Монорельс"]
    },
    timeZone: "UTC+09:00",
    languages: {
        "en": ["Japanese", "English"],
        "ru": ["Японский", "Английский"]
    },
    airports: {
        "en": ["Haneda Airport", "Narita International Airport"],
        "ru": ["Аэропорт Ханэда", "Международный аэропорт Нарита"]
    },
    isCapital: true,
    coordinates: {
        latitude: 35.6762,
        longitude: 139.6503
    }
};