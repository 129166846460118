import { auth } from "../stores/authStore";

const API_BASE_URL = import.meta.env.MODE === 'production'
  ? 'https://api.shindo.dev/api/v1'
  : '/api';

export type Message = {
  role: string;
  content: string;
};

export type APIResponse<T = any> = {
  data?: T;
  error?: string;
};

export async function getProtectedAPIData(options: RequestInit = {}): Promise<APIResponse> {
  try {
    const response = await fetch(`${API_BASE_URL}/status`, {
      ...options,
      credentials: 'include'
    });

    if (!response.ok) {
      if (response.status === 401) {
        auth.clearUser();
      }
      throw new Error(`Failed to fetch data: ${response.status} - ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export async function generateAIResponse(
  messages: Message[],
  options: RequestInit = {}
): Promise<APIResponse> {
  try {
    const response = await fetch(`${API_BASE_URL}/ai`, {
      ...options,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ messages }),
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 401) {
        auth.clearUser();
      }
      throw new Error(`Failed to generate AI response: ${response.status} - ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Error generating AI response:', error);
    throw error;
  }
}

export async function sendTelegramMessage(
  message: string,
  options: RequestInit = {}
): Promise<Response> {
  try {
    const response = await fetch(`${API_BASE_URL}/telegram`, {
      ...options,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message }),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`Failed to send telegram message: ${response.status} - ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error('Error sending Telegram message:', error);
    throw error;
  }
}