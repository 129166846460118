<script lang="ts">
  import { Link } from "svelte-routing";
  import { slide } from "svelte/transition";
  import ShindoLogo from "../../../assets/favicon/favicon-96x96.png";
  import DarkModeToggle from "./DarkModeToggle.svelte";
  import MiniMenu from "./Burger.svelte";
  import LanguageSelector from "./LanguageSelector.svelte";
  import { t } from "../../../translations/translations";
  import { auth } from "../../../stores/authStore";
  let isMobileMenuOpen = false;
  let isLoggedIn: boolean;

  // Subscribe to the user store for login status
  auth.subscribe((value) => {
    isLoggedIn = value.isLoggedIn; // Update isLoggedIn whenever the store changes
  });

  // Function to handle logout
  function handleLogout() {
    auth.logout();
  }

  let isOpen = false;
  const toggleMenu = () => (isOpen = !isOpen);
</script>

<nav id="mainNav" class="bg-menu-dark shadow-lg">
  <div id="navContainer" class="mx-auto px-4">
    <div id="navContent" class="flex justify-between h-11">
      <div id="logoSection" class="flex items-center">
        <Link to="/" id="logoLink" class="flex flex-shrink-0 items-center">
          <img
            src={ShindoLogo}
            alt="Shindo Logo"
            class="h-6 w-auto transition-transform duration-200 hover:scale-105 opacity-85 filter saturate-[0.85]"
          />
        </Link>
        <div class="ml-3">
          <DarkModeToggle />
        </div>
        <div class="ml-3">
          <LanguageSelector />
        </div>
      </div>

      <div id="desktopMenu" class="hidden md:flex md:items-center md:space-x-8">
        <ul class="flex items-center space-x-8">
          {#if isLoggedIn}
          <!-- <li>
            <Link
              to="/countries"
              class="text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
            >
              {$t.menu.countries}
            </Link>
          </li>
          <li>
            <Link
              to="/cities"
              class="text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
            >
              {$t.menu.cities}
            </Link>
          </li> -->
          <li>
            <Link
              to="/dialog"
              class="text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
            >
              {$t.menu.dialog}
            </Link>
          </li>
          <li>
            <Link
              to="/status"
              class="text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
            >
              {$t.menu.status}
              </Link>
            </li>
          {/if}
          <li>
            {#if isLoggedIn}
              <a
                href="/"
                on:click|preventDefault={handleLogout}
                class="text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
              >
                {$t.menu.logout}
              </a>
            {:else}
              <Link
                to="/login"
                class="text-txt-body-dark font-medium transition-colors hover:text-txt-body-light-hover dark:hover:text-txt-body-dark-hover"
              >
                {$t.menu.login}
              </Link>
            {/if}
          </li>
        </ul>
      </div>

      <div id="mobileMenuButton" class="flex items-center md:hidden">
        <MiniMenu
          {isOpen}
          {toggleMenu}
          menuItems={[
            // { to: "/countries", label: $t.menu.countries },
            // { to: "/cities", label: $t.menu.cities },
            { to: "/dialog", label: $t.menu.dialog },
            { to: "/status", label: $t.menu.status },
          ]}
        />
      </div>
    </div>
  </div>
</nav>
