import { type LanguageCode } from "./translations";

export type ChatTranslation = {
    pleaseLogIn: string;
    typeMessage: string;
    submit: string;
    clear: string;
    errorMessage: string;
    loading: string;
};

export const chatTranslations: Record<LanguageCode, ChatTranslation> = {
    en: {
        pleaseLogIn: "Please log in",
        typeMessage: "Type your message...",
        submit: "Submit",
        clear: "Clear",
        errorMessage: "Sorry, there was an error processing your request. Please try again.",
        loading: "Loading..."
    },
    ru: {
        pleaseLogIn: "Пожалуйста, войдите в систему",
        typeMessage: "Введите сообщение...",
        submit: "Отправить",
        clear: "Очистить",
        errorMessage: "Извините, произошла ошибка при обработке вашего запроса. Пожалуйста, попробуйте еще раз.",
        loading: "Загрузка..."
    },
};