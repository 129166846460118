import type { City } from '../../cities';

export const saopaolo: City = {
    name: {
        "en": "São Paulo",
        "ru": "Сан-Паулу"
    },
    country: {
        "en": "Brazil",
        "ru": "Бразилия"
    },
    image: "https://images.unsplash.com/photo-1554168848-228452c09d60",
    populationMillion: 12.3,
    population: {
        "en": "12.3 million",
        "ru": "12,3 млн"
    },
    description: {
        "en": "São Paulo is Brazil's largest city and the main financial center of South America. It's known for its cultural diversity, gastronomy, and vibrant business environment.",
        "ru": "Сан-Паулу - крупнейший город Бразилии и главный финансовый центр Южной Америки. Известен культурным разнообразием, гастрономией и активной деловой средой."
    },
    founded: {
        "en": "1554",
        "ru": "1554"
    },
    landmarks: {
        "en": ["Paulista Avenue", "Ibirapuera Park", "São Paulo Museum of Art", "Municipal Theater"],
        "ru": ["Проспект Паулиста", "Парк Ибирапуэра", "Музей искусств Сан-Паулу", "Муниципальный театр"]
    },
    universities: {
        "en": ["University of São Paulo", "State University of Campinas", "São Paulo State University"],
        "ru": ["Университет Сан-Паулу", "Государственный университет Кампинаса", "Государственный университет Сан-Паулу"]
    },
    industries: {
        "en": ["Finance", "Technology", "Manufacturing", "Services", "Media"],
        "ru": ["Финансы", "Технологии", "Производство", "Услуги", "Медиа"]
    },
    climate: {
        "en": "Humid subtropical climate with mild winters and warm summers",
        "ru": "Влажный субтропический климат с мягкими зимами и теплым летом"
    },
    transportation: {
        "en": ["Metro", "Bus", "Train", "Helicopter"],
        "ru": ["Метро", "Автобус", "Поезд", "Вертолет"]
    },
    timeZone: "UTC-03:00",
    languages: {
        "en": ["Portuguese"],
        "ru": ["Португальский"]
    },
    airports: {
        "en": ["Guarulhos International Airport", "Congonhas Airport"],
        "ru": ["Международный аэропорт Гуарульос", "Аэропорт Конгоньяс"]
    },
    isCapital: false,
    coordinates: {
        latitude: -23.5505,
        longitude: -46.6333
    }
};
