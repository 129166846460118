import type { City } from '../../cities';

export const manchester: City = {
    name: {
        "en": "Manchester",
        "ru": "Манчестер"
    },
    country: {
        "en": "United Kingdom",
        "ru": "Великобритания"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Feu-assets.simpleview-europe.com%2Fmanchester2016%2Fimageresizer%2F%3Fimage%3D%252Fdmsimgs%252Folympic-parade_1992645352.jpg%26action%3DOpenGraph&f=1&nofb=1&ipt=0175bae0f408243772090304828c3b00c047787a3361db86eb68d92a078171ce&ipo=images",
    populationMillion: 2.8,
    population: {
        "en": "2.8 million",
        "ru": "2,8 млн"
    },
    description: {
        "en": "Manchester is a major city in England known for its industrial heritage, vibrant culture, and influential music scene. It was the world's first industrialized city.",
        "ru": "Манчестер - крупный город в Англии, известный своим промышленным наследием, яркой культурой и влиятельной музыкальной сценой. Это был первый в мире индустриализированный город."
    },
    founded: {
        "en": "79 AD",
        "ru": "79 год н.э."
    },
    landmarks: {
        "en": ["Manchester Town Hall", "Old Trafford", "John Rylands Library", "Manchester Cathedral"],
        "ru": ["Манчестерская ратуша", "Олд Траффорд", "Библиотека Джона Райландса", "Манчестерский собор"]
    },
    universities: {
        "en": ["University of Manchester", "Manchester Metropolitan University", "University of Salford"],
        "ru": ["Манчестерский университет", "Манчестерский столичный университет", "Университет Салфорда"]
    },
    industries: {
        "en": ["Technology", "Media", "Finance", "Manufacturing"],
        "ru": ["Технологии", "Медиа", "Финансы", "Производство"]
    },
    climate: {
        "en": "Temperate maritime climate with mild winters and cool summers",
        "ru": "Умеренный морской климат с мягкими зимами и прохладным летом"
    },
    transportation: {
        "en": ["Tram", "Bus", "Train", "Metrolink"],
        "ru": ["Трамвай", "Автобус", "Поезд", "Метролинк"]
    },
    timeZone: "UTC+00:00",
    languages: {
        "en": ["English"],
        "ru": ["Английский"]
    },
    airports: {
        "en": ["Manchester Airport"],
        "ru": ["Аэропорт Манчестера"]
    },
    isCapital: false,
    coordinates: {
        latitude: 53.4808,
        longitude: -2.2426
    }
};
