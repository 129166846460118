<script lang="ts">
    import {t} from "../../../translations/translations";
</script>

<div
        id="terms-container"
        class="
        container
        mx-auto
        px-4
        py-8
        max-w-3xl
    "
>
    <div
            id="terms-content-card"
            class="
            flex
            flex-col
            p-8
            rounded-2xl
            bg-form-light
            dark:bg-form-dark
            shadow-lg
        "
    >
        <h1
                id="terms-title"
                class="
                text-3xl
                font-bold
                mb-6
                text-txt-heading-light
                dark:text-txt-heading-dark
            "
        >
            {$t.terms.title}
        </h1>

        <div
                id="terms-text-container"
                class="
                flex
                flex-col
                space-y-6
                text-txt-body-light
                dark:text-txt-body-dark
            "
        >
            <p
                    id="terms-intro"
                    class="
                    leading-relaxed
                "
            >
                {$t.terms.introduction}
            </p>

            <p
                    id="terms-usage"
                    class="
                    leading-relaxed
                "
            >
                {$t.terms.usage}
            </p>

            <p
                    id="terms-limitations"
                    class="
                    leading-relaxed
                "
            >
                {$t.terms.limitations}
            </p>

            <p
                    id="terms-contact"
                    class="
                    leading-relaxed
                "
            >
                {$t.terms.contact}
            </p>
        </div>
    </div>
</div>
