<script lang="ts">
  export let content: string;
  export let contentColor: string = "text-gray-800 dark:text-gray-200";
  export let onDelete: () => void;
  let copied = false;
  let deleted = false;

  // Function to copy content to clipboard with success animation
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content);
      copied = true;
      setTimeout(() => {
        copied = false;
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  // Capitalize the first letter of the content
  $: displayContent = content.charAt(0).toUpperCase() + content.slice(1);

  // Add delete function
  const deleteMessage = async () => {
    try {
      await onDelete();
      deleted = true;
      setTimeout(() => {
        deleted = false;
      }, 2000);
    } catch (err) {
      console.error('Failed to delete message:', err);
    }
  };
</script>

<div class="
  p-4 pl-8
  rounded-lg
  mb-4
  bg-white
  dark:bg-gray-700
  shadow-md
  hover:shadow-lg
  transition-shadow duration-300
  relative
  min-h-[88px]">
  <div class="absolute top-2 right-2 flex flex-col gap-1">
    <button
      on:click={copyToClipboard}
      class="
      p-1.5
      z-10
      bg-white dark:bg-gray-700
      rounded-md
      text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200
      transition-all duration-200 {copied ? 'scale-125 text-green-500 dark:text-green-400' : ''}"
      title="Copy message"
    >
      {#if copied}
        <!-- Checkmark icon -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      {:else}
        <!-- Copy icon -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
          <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
        </svg>
      {/if}
    </button>

    <button
      on:click={deleteMessage}
      class="
      p-1.5
      z-10
      bg-white dark:bg-gray-700
      rounded-md
      text-gray-500 hover:text-red-600 dark:text-gray-400 dark:hover:text-red-400
      transition-all duration-200 {deleted ? 'scale-125 text-green-500 dark:text-green-400' : ''}"
      title="Delete message"
    >
      {#if deleted}
        <!-- Checkmark icon -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      {:else}
        <!-- Trash icon -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
      {/if}
    </button>
  </div>

  <p class={`
    whitespace-pre-wrap
    font-extrabold
    leading-golden
    text-sm sm:text-md
    antialiased
    tracking-normal
    max-w-readable
    mx-auto
    prose-p:font-normal prose-p:mb-6 ${contentColor}`}>
    {displayContent}
  </p>
</div>