<script lang="ts">
    import { t } from "../../../translations/translations";
</script>

<div id="about-page-container" class="
    container
    flex
    flex-col
    max-w-3xl
    mx-auto
    px-4
    py-8
">
    <div id="about-content-wrapper" class="
        flex
        flex-col
        p-8
        bg-form-light
        dark:bg-form-dark
        rounded-2xl
        shadow-lg
    ">
        <h1 id="about-main-title" class="
            mb-6
            text-3xl
            font-bold
            text-txt-heading-light
            dark:text-txt-heading-dark
        ">
            {$t.about.title}
        </h1>

        <div id="about-content-section" class="
            flex
            flex-col
            space-y-6
            text-txt-body-light
            dark:text-txt-body-dark
        ">
            <p id="about-intro-text" class="
                leading-relaxed
            ">
                {$t.about.introduction}
            </p>

            <h2 id="about-features-title" class="
                mt-8
                mb-4
                text-2xl
                font-semibold
                text-txt-heading-light
                dark:text-txt-heading-dark
            ">
                {$t.about.featuresTitle}
            </h2>
            <ul id="about-features-list" class="
                pl-6
                space-y-2
                list-disc
            ">
                <li>{$t.about.feature1}</li>
                <li>{$t.about.feature2}</li>
                <li>{$t.about.feature3}</li>
            </ul>

            <h2 id="about-technology-title" class="
                mt-8
                mb-4
                text-2xl
                font-semibold
                text-txt-heading-light
                dark:text-txt-heading-dark
            ">
                {$t.about.technologyTitle}
            </h2>
            <p id="about-technology-text" class="
                leading-relaxed
            ">
                {$t.about.technology}
            </p>

            <p id="about-conclusion-text" class="
                leading-relaxed
            ">
                {$t.about.conclusion}
            </p>
        </div>
    </div>
</div>
