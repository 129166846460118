import type { City } from '../../cities';

export const marseille: City = {
    name: {
        "en": "Marseille",
        "ru": "Марсель"
    },
    country: {
        "en": "France",
        "ru": "Франция"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.routard.com%2Fimage%2F32%2F8%2Fmarseille-port.1558328.w430.jpg&f=1&nofb=1&ipt=a6a2a5b4443b67a4d53db0f0bbd8e983bb57a4cf36480b3886942e0b5d66b198&ipo=images",
    populationMillion: 1.6,
    population: {
        "en": "1.6 million",
        "ru": "1,6 млн"
    },
    description: {
        "en": "Marseille is France's second-largest city and largest port, known for its rich history, diverse culture, and Mediterranean charm.",
        "ru": "Марсель - второй по величине город Франции и крупнейший порт, известный своей богатой историей, разнообразной культурой и средиземноморским шармом."
    },
    founded: {
        "en": "600 BC",
        "ru": "600 г. до н.э."
    },
    landmarks: {
        "en": ["Notre-Dame de la Garde", "Vieux-Port", "Le Panier", "Calanques"],
        "ru": ["Нотр-Дам-де-ла-Гард", "Старый порт", "Ле Панье", "Каланки"]
    },
    universities: {
        "en": ["Aix-Marseille University", "École Centrale Marseille", "KEDGE Business School"],
        "ru": ["Университет Экс-Марсель", "Центральная школа Марселя", "Бизнес-школа KEDGE"]
    },
    industries: {
        "en": ["Maritime Trade", "Tourism", "Technology", "Manufacturing"],
        "ru": ["Морская торговля", "Туризм", "Технологии", "Производство"]
    },
    climate: {
        "en": "Mediterranean climate with hot summers and mild winters",
        "ru": "Средиземноморский климат с жарким летом и мягкой зимой"
    },
    transportation: {
        "en": ["Metro", "Tram", "Bus", "Ferry"],
        "ru": ["Метро", "Трамвай", "Автобус", "Паром"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["French", "English"],
        "ru": ["Французский", "Английский"]
    },
    airports: {
        "en": ["Marseille Provence Airport"],
        "ru": ["Аэропорт Марсель Прованс"]
    },
    isCapital: false,
    coordinates: {
        latitude: 43.2965,
        longitude: 5.3698
    }
};
