import type { City } from '../../cities';

export const losangeles: City = {
    name: {
        "en": "Los Angeles",
        "ru": "Лос-Анджелес"
    },
    country: {
        "en": "United States",
        "ru": "США"
    },
    image: "https://images.unsplash.com/photo-1534190760961-74e8c1c5c3da",
    populationMillion: 3.9,
    population: {
        "en": "3.9 million",
        "ru": "3,9 млн"
    },
    description: {
        "en": "Los Angeles is the entertainment capital of the world and second-largest city in the US. Known for its Mediterranean climate, cultural diversity, and film industry.",
        "ru": "Лос-Анджелес - мировая столица развлечений и второй по величине город США. Известен средиземноморским климатом, культурным разнообразием и киноиндустрией."
    },
    founded: {
        "en": "1781",
        "ru": "1781"
    },
    landmarks: {
        "en": ["Hollywood Sign", "Griffith Observatory", "Venice Beach", "Getty Center"],
        "ru": ["Знак Голливуда", "Обсерватория Гриффита", "Пляж Венис", "Центр Гетти"]
    },
    universities: {
        "en": ["UCLA", "USC", "California State University Los Angeles"],
        "ru": ["Калифорнийский университет в Лос-Анджелесе", "Университет Южной Калифорнии", "Калифорнийский государственный университет в Лос-Анджелесе"]
    },
    industries: {
        "en": ["Entertainment", "Technology", "Tourism", "Aerospace", "Fashion"],
        "ru": ["Развлечения", "Технологии", "Туризм", "Аэрокосмическая промышленность", "Мода"]
    },
    climate: {
        "en": "Mediterranean climate with warm, sunny weather year-round",
        "ru": "Средиземноморский климат с теплой, солнечной погодой круглый год"
    },
    transportation: {
        "en": ["Metro", "Bus", "Light Rail", "Rideshare"],
        "ru": ["Метро", "Автобус", "Легкорельсовый транспорт", "Райдшеринг"]
    },
    timeZone: "UTC-08:00",
    languages: {
        "en": ["English", "Spanish"],
        "ru": ["Английский", "Испанский"]
    },
    airports: {
        "en": ["Los Angeles International Airport (LAX)", "Hollywood Burbank Airport"],
        "ru": ["Международный аэропорт Лос-Анджелеса (LAX)", "Аэропорт Голливуд-Бербанк"]
    },
    isCapital: false,
    coordinates: {
        latitude: 34.0522,
        longitude: -118.2437
    }
};
