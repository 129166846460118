import type { City } from '../../cities';

export const lyon: City = {
    name: {
        "en": "Lyon",
        "ru": "Лион"
    },
    country: {
        "en": "France",
        "ru": "Франция"
    },
    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fpyt-blogs.imgix.net%2F2018%2F02%2F34276358804_e28b8da0cb_k.jpg%3Fauto%3Dformat%26ixlib%3Dphp-3.3.0&f=1&nofb=1&ipt=99e7dd2e57fa215541748885209e1063f11b63cab77c940d9dc8badf841807db&ipo=images",
    populationMillion: 1.7,
    population: {
        "en": "1.7 million",
        "ru": "1,7 млн"
    },
    description: {
        "en": "Lyon is France's third-largest city and a UNESCO World Heritage site, known for its gastronomy, historical architecture, and silk-weaving heritage.",
        "ru": "Лион - третий по величине город Франции и объект Всемирного наследия ЮНЕСКО, известный своей гастрономией, исторической архитектурой и наследием шелкоткачества."
    },
    founded: {
        "en": "43 BC",
        "ru": "43 г. до н.э."
    },
    landmarks: {
        "en": ["Basilica of Notre-Dame de Fourvière", "Vieux Lyon", "Place Bellecour", "Parc de la Tête d'Or"],
        "ru": ["Базилика Нотр-Дам-де-Фурвьер", "Старый Лион", "Площадь Белькур", "Парк Тет д'Ор"]
    },
    universities: {
        "en": ["University of Lyon", "École Normale Supérieure de Lyon", "INSA Lyon"],
        "ru": ["Университет Лиона", "Высшая нормальная школа Лиона", "INSA Лион"]
    },
    industries: {
        "en": ["Biotechnology", "Digital Industry", "Manufacturing", "Tourism"],
        "ru": ["Биотехнологии", "Цифровая индустрия", "Производство", "Туризм"]
    },
    climate: {
        "en": "Semi-continental climate with warm summers and cool winters",
        "ru": "Полуконтинентальный климат с теплым летом и прохладной зимой"
    },
    transportation: {
        "en": ["Metro", "Tram", "Bus", "Bicycle sharing"],
        "ru": ["Метро", "Трамвай", "Автобус", "Велопрокат"]
    },
    timeZone: "UTC+01:00",
    languages: {
        "en": ["French", "English"],
        "ru": ["Французский", "Английский"]
    },
    airports: {
        "en": ["Lyon-Saint Exupéry Airport"],
        "ru": ["Аэропорт Лион-Сент-Экзюпери"]
    },
    isCapital: false,
    coordinates: {
        latitude: 45.7640,
        longitude: 4.8357
    }
};
